import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Element from '../../../Components/SelectElement/Element';
import { setFormSelectItem, setFormState } from '../../../redux/form';
import { closeModal } from '../../../redux/modal';
import { numberAddComma } from '../../../common/util';
import DefaultModalLayout from './../../../Layouts/ModalLayouts/DefaultModalLayout';
import { FuneralItemTypes } from './../../../redux/funeralItem';
import { FormTypes } from './../../../redux/form';

const key = 'portraitPhoto';
function SelectPortraitPhoto() {
    const dispatch = useDispatch();
    const portraitPhotos = useSelector(
        (state) => state.funeralItem[FuneralItemTypes.OPTIONS].portraitPhoto
    );
    const { portraitPhotoId } = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );

    return (
        <DefaultModalLayout header={{ content: '영정사진 선택', props: {} }}>
            {portraitPhotos.map((ele, idx) => {
                const {
                    optionId,
                    name,
                    extra,
                    price,
                    default: IsDefault,
                } = ele;

                return (
                    <Element
                        key={optionId}
                        name={extra}
                        extra={<>{numberAddComma(price)}원</>}
                        selected={optionId === portraitPhotoId}
                        onClick={() => {
                            dispatch(
                                setFormState({
                                    type: FormTypes.FUNERAL_ESTIMATE,
                                    prop: key + 'Id',
                                    data: optionId,
                                })
                            );
                            dispatch(
                                setFormSelectItem({
                                    type: FormTypes.FUNERAL_ESTIMATE,
                                    prop: key,
                                    data: ele,
                                })
                            );
                            dispatch(closeModal());
                        }}
                    />
                );
            })}
        </DefaultModalLayout>
    );
}

export default SelectPortraitPhoto;
