import styled from 'styled-components';

const Space = styled.div`
    margin-left: ${({ width = 0 }) =>
        String(width).indexOf('%') === -1 ? width + 'px' : width};
    margin-bottom: ${({ height = 0 }) =>
        String(height).indexOf('%') === -1 ? height + 'px' : height};
    width: 0;
    height: 0;
`;

export default Space;
