import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import modalReducer from './modal';
import formReducer from './form';
import funeralItemReducer from './funeralItem';

export default configureStore({
    reducer: {
        user: userReducer,
        modal: modalReducer,
        form: formReducer,
        funeralItem: funeralItemReducer,
    },
});
