import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogLayout from '../../Layouts/ModalLayouts/DialogLayout';
import { FormTypes } from './../../redux/form';
import { clearModal, closeModal } from '../../redux/modal';
import { addFuneralManagement, removeReservation } from '../../redux/user';

function AskRequestFuneralReserve() {
    const dispatch = useDispatch();
    const formData = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );

    return (
        <DialogLayout
            body={{ content: '예약 되었습니까?', props: {} }}
            actions={[
                {
                    content: '닫기',
                    props: {
                        onClick: () => {
                            dispatch(closeModal());
                        },
                    },
                },
                {
                    content: '예약됨',
                    props: {
                        style: { color: '#485BA1' },
                        onClick: () => {
                            // 현재 열려져 있는 Dialog 닫기
                            dispatch(clearModal());

                            // 장례관리 데이터 추가
                            dispatch(
                                addFuneralManagement({
                                    type: 'funeral',
                                    // data,
                                    // idx: data.estimate.funeralIdx,
                                })
                            );

                            // 요청된 예약 삭제
                            dispatch(
                                removeReservation({
                                    type: 'funeral',
                                    // idx: data.idx,
                                })
                            );
                        },
                    },
                },
            ]}
        />
    );
}

export default AskRequestFuneralReserve;
