import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Element from '../../../Components/SelectElement/Element';
import { setFormSelectItem, setFormState } from '../../../redux/form';
import { closeModal } from '../../../redux/modal';
import { numberAddComma } from '../../../common/util';
import DefaultModalLayout from './../../../Layouts/ModalLayouts/DefaultModalLayout';
import { FuneralItemTypes } from './../../../redux/funeralItem';
import { FormTypes } from './../../../redux/form';

const key = 'coffin';
function SelectCoffin() {
    const dispatch = useDispatch();

    const coffins = useSelector(
        (state) => state.funeralItem[FuneralItemTypes.OPTIONS].coffin
    );
    const { coffinId } = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );

    return (
        <DefaultModalLayout header={{ content: '빈소 선택', props: {} }}>
            {coffins.map((ele, idx) => {
                const { optionId, name, price } = ele;

                return (
                    <Element
                        key={optionId}
                        selected={coffinId === optionId}
                        name={name}
                        extra={<>{numberAddComma(price)}원</>}
                        onClick={() => {
                            dispatch(
                                setFormState({
                                    type: FormTypes.FUNERAL_ESTIMATE,
                                    prop: key + 'Id',
                                    data: optionId,
                                })
                            );
                            dispatch(
                                setFormSelectItem({
                                    type: FormTypes.FUNERAL_ESTIMATE,
                                    prop: key,
                                    data: ele,
                                })
                            );
                            dispatch(closeModal());
                        }}
                    />
                );
            })}
        </DefaultModalLayout>
    );
}

export default SelectCoffin;
