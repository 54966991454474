import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Element from '../../../Components/SelectElement/Element';
import {
    clearFormState,
    setFormSelectItem,
    setFormState,
} from '../../../redux/form';
import { closeModal } from '../../../redux/modal';
import Space from '../../../Style/Space';
import DefaultModalLayout from './../../../Layouts/ModalLayouts/DefaultModalLayout';
import { useQuery } from 'react-query';
import axios from 'axios';
import { FuneralItemTypes } from './../../../redux/funeralItem';
import { FormTypes } from './../../../redux/form';

function SelectFuneralHall() {
    const dispatch = useDispatch();

    const { id } = useSelector(
        (state) => state.funeralItem[FuneralItemTypes.FUNERAL_ESTIMATE_REQUEST]
    );
    const { data: funeralHalls = [] } = useQuery(
        ['getFuneralHalls', 'estimateId:', id],
        {
            queryFn: async () =>
                (await axios.get(`estimate/find/funeral/req/${id}`)).data
                    .funeralHalls,
        }
    );

    const { funeralHallId } = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );

    return (
        <DefaultModalLayout header={{ content: '장례식장 선택' }}>
            {funeralHalls.map((ele, idx) => {
                const { id, name, address1, address2 } = ele;

                return (
                    <React.Fragment key={idx}>
                        {idx === 0 && <Space height={16} />}
                        <Element
                            name={name}
                            extra={address1 + address2}
                            selected={funeralHallId === id}
                            onClick={() => {
                                // 장례식장이 변경되면 formState초기화
                                dispatch(
                                    clearFormState({
                                        type: FuneralItemTypes.OPTIONS,
                                    })
                                );
                                dispatch(
                                    clearFormState({
                                        type: FormTypes.FUNERAL_ESTIMATE,
                                    })
                                );

                                dispatch(
                                    setFormState({
                                        type: FormTypes.FUNERAL_ESTIMATE,
                                        prop: 'funeralHallId',
                                        data: id,
                                    })
                                );
                                dispatch(
                                    setFormSelectItem({
                                        type: FormTypes.FUNERAL_ESTIMATE,
                                        prop: 'funeralHall',
                                        data: ele,
                                    })
                                );
                                dispatch(closeModal());
                            }}
                        />
                    </React.Fragment>
                );
            })}
        </DefaultModalLayout>
    );
}

export default SelectFuneralHall;
