import React from 'react';
import DefaultLayout from '../../Layouts/DefaultLayout';
import Space from '../../Style/Space';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getDateString, getTermDateString } from '../../common/util';

import EnterImage from './../../resources/image/ic_enter.svg';
import Flex from '../../Style/Flex';
import { modalTypes } from '../../common/constants';
import { openModal } from '../../redux/modal';
import { numberAddComma } from '../../common/util';

const Card = styled.div`
    padding: 25px 20px;
    background: #ffffff;
    box-shadow: 1px 4px 14px 4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    font-size: 15px;
`;

function Reserve() {
    const { me } = useSelector((state) => state.user);
    const {
        reservation: { funeral, leaveForACemetery },
    } = me;

    const dispatch = useDispatch();

    return (
        <DefaultLayout>
            <div>
                {funeral && (
                    <>
                        <Space height={30} />
                        <div>장례예약</div>
                        {funeral.map((ele, idx) => {
                            const { date, totalPrice, funeral } = ele;

                            return (
                                <React.Fragment key={idx}>
                                    <Space height={14} />
                                    <button
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    // type: modalTypes.REQUEST_RESERVATION_FUNERAL,
                                                    data: ele,
                                                })
                                            );
                                        }}
                                    >
                                        <Card>
                                            <Flex.Wrapper justifyContent="space-between">
                                                <div>
                                                    {getTermDateString(
                                                        date.start,
                                                        date.end,
                                                        'point'
                                                    )}
                                                </div>
                                                <strong>
                                                    {numberAddComma(
                                                        totalPrice
                                                    ) + '원'}
                                                </strong>
                                            </Flex.Wrapper>

                                            <Flex.Wrapper
                                                as="ul"
                                                column
                                                alignItems="stretch"
                                                style={{
                                                    fontSize: '12px',
                                                    color: '#666666',
                                                    margin: '5px 0 0 0',
                                                }}
                                            >
                                                <Flex.Wrapper
                                                    as="li"
                                                    justifyContent="stretch"
                                                >
                                                    <div
                                                        style={{
                                                            width: '3px',
                                                            height: '3px',
                                                            background: '#666',
                                                            borderRadius:
                                                                '100%',
                                                            margin: '3px 7px 0 7px',
                                                        }}
                                                    />
                                                    {funeral.name}
                                                </Flex.Wrapper>
                                            </Flex.Wrapper>
                                            <Flex.Wrapper
                                                justifyContent="flex-end"
                                                style={{
                                                    margin: '10px 0 4px 0',
                                                }}
                                            >
                                                <img
                                                    src={EnterImage}
                                                    alt="enter"
                                                />
                                            </Flex.Wrapper>
                                        </Card>
                                    </button>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
                {leaveForACemetery && (
                    <>
                        <Space height={30} />
                        <div>발인예약</div>
                        {leaveForACemetery.map((ele, idx) => {
                            const {
                                date,
                                totalPrice,
                                cemetery: { item: cemetery },
                                crematorium: { item: crematorium },
                                vehicle,
                            } = ele;
                            const vehicleString = vehicle.reduce(
                                (prev, curr) =>
                                    prev +
                                    `${prev !== '' ? ', ' : ''}` +
                                    `${curr.name} ${curr.units[0].num}${curr.units[0].name}`,
                                ''
                            );

                            return (
                                <React.Fragment key={idx}>
                                    <Space height={14} />
                                    <button
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    // type: ActionTypes.REQUEST_RESERVATION_LEAVE_FOR_A_CEMETERY,
                                                    data: ele,
                                                })
                                            );
                                        }}
                                    >
                                        <Card>
                                            <Flex.Wrapper justifyContent="space-between">
                                                <div>
                                                    {getDateString(
                                                        date,
                                                        'point'
                                                    )}
                                                </div>
                                                <strong>
                                                    {numberAddComma(
                                                        totalPrice
                                                    ) + '원'}
                                                </strong>
                                            </Flex.Wrapper>

                                            <Flex.Wrapper
                                                as="ul"
                                                column
                                                alignItems="stretch"
                                                style={{
                                                    fontSize: '12px',
                                                    color: '#666666',
                                                    margin: '5px 0 0 0',
                                                }}
                                            >
                                                {[
                                                    cemetery.name,
                                                    crematorium.name,
                                                    vehicleString,
                                                ].map((ele, idx) => (
                                                    <Flex.Wrapper
                                                        as="li"
                                                        key={idx}
                                                        justifyContent="stretch"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '3px',
                                                                height: '3px',
                                                                background:
                                                                    '#666',
                                                                borderRadius:
                                                                    '100%',
                                                                margin: '3px 7px 0 7px',
                                                            }}
                                                        />
                                                        {ele}
                                                    </Flex.Wrapper>
                                                ))}
                                            </Flex.Wrapper>
                                            <Flex.Wrapper
                                                justifyContent="flex-end"
                                                style={{
                                                    margin: '10px 0 4px 0',
                                                }}
                                            >
                                                <img
                                                    src={EnterImage}
                                                    alt="enter"
                                                />
                                            </Flex.Wrapper>
                                        </Card>
                                    </button>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
                <Space height={100} />
            </div>
        </DefaultLayout>
    );
}

export default Reserve;
