import React from 'react';
import styled from 'styled-components';
import Space from '../../Style/Space';
import Flex from './../../Style/Flex';

const DialogWrapper = styled(Flex.Wrapper)`
    background: #fff;
    border-radius: 10px;
    min-width: 295px;

    @media screen and (max-width: 375px) {
        min-width: 0;
        width: calc(100vw - 80px);
    }
`;
const Content = styled.div`
    width: 100%;
    padding: 30px 16px;
    font-size: 12px;
    color: #666;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    text-align: center;
`;

const ActionButtonWrapper = styled(Flex.Wrapper)`
    & > div {
        button {
            text-align: center;
            width: 100%;
            padding: 15px 0;

            font-weight: 400;
            font-size: 14px;
            color: #666;
            border-right: 1px solid #eeeeee;
        }
        &:last-child button {
            border: unset;
            color: #485ba1;
        }
    }
`;

function DialogLayout({ body, actions }) {
    return (
        <DialogWrapper column>
            <Content {...body.props}>{body.content}</Content>
            <ActionButtonWrapper style={{ width: '100%' }}>
                {actions.map((action, idx) => (
                    <Flex.Item flex={1} key={idx}>
                        <button {...action.props}>{action.content}</button>
                    </Flex.Item>
                ))}
            </ActionButtonWrapper>
        </DialogWrapper>
    );
}

export default DialogLayout;
