import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogLayout from '../../Layouts/ModalLayouts/DialogLayout';
import { clearFormState, FormTypes } from './../../redux/form';
import { addReservation, removeEstimate } from '../../redux/user';
import { clearModal, closeModal } from '../../redux/modal';

function AskRequestCoffinEstimate() {
    const dispatch = useDispatch();
    const formData = useSelector(
        (state) => state.form[FormTypes.COFFIN_ESTIMATE]
    );

    return (
        <DialogLayout
            body={{ content: '견적을 발송하겠습니까?', props: {} }}
            actions={[
                {
                    content: '취소',
                    props: {
                        onClick: () => {
                            dispatch(closeModal());
                        },
                    },
                },
                {
                    content: '발송',
                    props: {
                        style: { color: '#485BA1' },
                        onClick: () => {
                            // api 요청 코드 넣는곳

                            // ------------------
                            dispatch(
                                addReservation({
                                    type: 'coffin',
                                    data: formData,
                                })
                            );

                            // formState 초기화
                            dispatch(clearFormState());

                            // 현재 열려져 있는 Dialog 닫기
                            dispatch(clearModal());

                            // 요청된 견적 삭제
                            dispatch(
                                removeEstimate({
                                    type: 'coffin',
                                    idx: formData.idx,
                                })
                            );
                        },
                    },
                },
            ]}
        />
    );
}

export default AskRequestCoffinEstimate;
