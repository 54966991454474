import React, { useState } from 'react';
import Input from './Input';

import IcEyeImage from '../../resources/image/form/ic-eye.svg';
import IcEyeOffImage from '../../resources/image/form/ic-eye-off.svg';
import Flex from './../../Style/Flex';

const InputPassword = React.forwardRef(
    ({ style = { width: '100%' }, ...rest }, ref) => {
        // 비밀번호 show 여부
        const [isShowPassword, setIsShowPassword] = useState(false);
        return (
            <Flex.Wrapper
                style={{ borderBottom: '1px solid #dddddd', ...style }}
                inlineFlex={true}
                justifyContent="space-between"
            >
                <Input
                    ref={ref}
                    {...rest}
                    type={isShowPassword ? 'text' : 'password'}
                    underline={'initial'}
                />
                <button
                    type="button"
                    onClick={() => setIsShowPassword((prev) => !prev)}
                >
                    <img
                        src={isShowPassword ? IcEyeImage : IcEyeOffImage}
                        alt="show password"
                    />
                </button>
            </Flex.Wrapper>
        );
    }
);
InputPassword.displayName = 'InputPassword';

export default InputPassword;
