import { createSlice } from '@reduxjs/toolkit';

export const FuneralItemTypes = {
    FUNERAL_ESTIMATE_REQUEST: 'FUNERAL_ESTIMATE_REQUEST',
    FUNERAL_ESTIMATE: 'FUNERAL_ESTIMATE',
    OPTIONS: 'OPTION',
};

const initialState = {
    [FuneralItemTypes.FUNERAL_ESTIMATE_REQUEST]: null,
    [FuneralItemTypes.FUNERAL_ESTIMATE]: null,
    [FuneralItemTypes.OPTIONS]: null,
};

export const funeralItemSlice = createSlice({
    name: 'funeralItem',
    initialState,
    reducers: {
        setFuneralItem: (state, action) => {
            const { type, data } = action.payload;
            if (type) {
                state[type] = data;
            } else {
                return data;
            }
        },
    },
});

export const { setFuneralItem } = funeralItemSlice.actions;

export default funeralItemSlice.reducer;
