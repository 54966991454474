import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FuneralItemInputLayout from '../../Components/Form/FuneralItemInputLayout';
import Input from '../../Components/Form/Input';
import Space from '../../Style/Space';
import Button from '../../Style/Button';
import { checkNotEmptyValue, getTermDateString } from '../../common/util';
import InputSelect from '../../Components/Form/InputSelect';
import { useSelector } from 'react-redux';
import { numberAddComma } from '../../common/util';
import Flex from '../../Style/Flex';
import { openModal } from '../../redux/modal';
import { setFormSelectItem, setFormState } from '../../redux/form';
import { modalTypes } from './../../common/constants';
import { FuneralItemTypes, setFuneralItem } from './../../redux/funeralItem';
import DefaultModalLayout from './../../Layouts/ModalLayouts/DefaultModalLayout';
import { FormTypes } from './../../redux/form';
import { useQuery } from 'react-query';
import axios from 'axios';
import useModal from './../../hooks/useModal';
import styled from 'styled-components';

const commonInputProps = {
    underline: 'none',
    disabled: true,
    as: 'div',
    style: { minHeight: '44px' },
};

const FuneralEstimateForm = styled.form`
    height: calc(100vh - 44px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 16;
`;

function getDisplayValues(items, estimateInfo, estimateEtc) {
    const {
        funeralHall,
        shroud,
        cleanOption,
        manClothes,
        womanClothes,
        visitorFood,
        sepulchre,
        room,
        shrineFlower,
        portraitPhoto,
        moveOption,
        washOption,
        shroudClothes,
        coffin,
        manpowerDirector,
        manpowerSubDirector,
        manpowerHelper,
        count,
    } = items;

    const {
        id,
        deceased,
        startDate,
        end_date: endDate,
        range,
        rangeCd,
        visitor,
        visitorCd,
        area,
        status,
        memo,
        bereaved,
    } = estimateInfo;

    const rangeCount = rangeCd === '00' ? 3 : rangeCd === '01' ? 2 : 0;
    const visitorCount =
        visitorCd === '00'
            ? 49
            : visitorCd === '01'
            ? 99
            : visitorCd === '02'
            ? 100
            : 0;

    return {
        funeralHall: checkNotEmptyValue(funeralHall) && {
            value: funeralHall.name,
            extra: `${funeralHall.address1} ${funeralHall.address2}`,
        },
        room: checkNotEmptyValue(room) && {
            value: `${room.name} x ${rangeCount}일`,
            extra: `${numberAddComma(room.price * rangeCount)}원`,
        },
        shrineFlower: checkNotEmptyValue(shrineFlower) && {
            value: shrineFlower.name,
            extra: `${numberAddComma(shrineFlower.price)}원`,
        },
        portraitPhoto: checkNotEmptyValue(portraitPhoto) && {
            value: `영정사진 ${portraitPhoto.extra}`,
            extra: numberAddComma(portraitPhoto.price) + '원',
        },
        manClothes: {
            value: `남성 ${manClothes ? manClothes.cnt : 0}벌`,
            extra: `${numberAddComma(
                manClothes ? manClothes.cnt * manClothes.price : 0
            )}원`,
        },
        womanClothes: {
            value: `여성 ${womanClothes ? womanClothes.cnt : 0}벌`,
            extra: `${numberAddComma(
                womanClothes ? womanClothes.cnt * womanClothes.price : 0
            )}원`,
        },
        visitorFood: checkNotEmptyValue(visitorFood) && {
            value: `${visitor} (접객 음식)`,
            extra: `${numberAddComma(visitorFood.price * visitorCount)}원`,
        },
        moveOption: checkNotEmptyValue(moveOption) && {
            value: `${moveOption.name} 이동`,
            extra: `${numberAddComma(moveOption.price)}원`,
        },
        washOption: checkNotEmptyValue(washOption) && {
            value: washOption.name,
            extra: `${numberAddComma(washOption.price)}원`,
        },
        sepulchre: checkNotEmptyValue(sepulchre) && {
            value: `${rangeCount - 1}일`,
            extra: `${numberAddComma(sepulchre.price * (rangeCount - 1))}원`,
        },
        shroud: checkNotEmptyValue(shroud) && {
            value: shroud.name,
            extra: `${numberAddComma(shroud.price)}원`,
        },
        shroudClothes: checkNotEmptyValue(shroudClothes) && {
            value: shroudClothes.name,
            extra: `${numberAddComma(shroudClothes.price)}원`,
        },
        coffin: checkNotEmptyValue(coffin) && {
            value: coffin.name,
            extra: `${numberAddComma(coffin.price)}원`,
        },
        manpowerDirector: {
            value: `장례지도사 ${
                (manpowerDirector && manpowerDirector.cnt) || 0
            }인 x ${(manpowerDirector && manpowerDirector.days) || 0}일`,
            extra: `${numberAddComma(
                (manpowerDirector && manpowerDirector.price) || 0
            )}원`,
        },
        manpowerSubDirector: {
            value: `입관보조 장례지도사 ${
                (manpowerSubDirector && manpowerSubDirector.cnt) || 0
            }인 x ${(manpowerSubDirector && manpowerSubDirector.days) || 0}일`,
            extra: `${numberAddComma(
                (manpowerSubDirector && manpowerSubDirector.price) || 0
            )}원`,
        },
        manpowerHelper: {
            value: `접객도우미 ${
                (manpowerHelper && manpowerHelper.cnt) || 0
            }인 x ${(manpowerHelper && manpowerHelper.hours) || 0}시간`,
            extra: `${numberAddComma(
                (manpowerHelper && manpowerHelper.price) || 0
            )}원`,
        },
        cleanOption: checkNotEmptyValue(cleanOption) && {
            value: cleanOption.name,
            extra: `${numberAddComma(cleanOption.price)}원`,
        },

        totalPrice: (() => {
            let sum = 0;
            if (checkNotEmptyValue(room)) {
                sum += room.price * rangeCount;
            }
            if (checkNotEmptyValue(shrineFlower)) {
                sum += shrineFlower.price;
            }
            if (checkNotEmptyValue(portraitPhoto)) {
                sum += portraitPhoto.price;
            }
            if (checkNotEmptyValue(manClothes)) {
                sum += manClothes.price || 0;
            }
            if (checkNotEmptyValue(womanClothes)) {
                sum += womanClothes.price || 0;
            }
            if (checkNotEmptyValue(visitorFood)) {
                sum += visitorFood.price * visitorCount;
            }
            if (checkNotEmptyValue(moveOption)) {
                sum += moveOption.price;
            }
            if (checkNotEmptyValue(washOption)) {
                sum += washOption.price;
            }
            if (checkNotEmptyValue(sepulchre)) {
                sum += sepulchre.price * (rangeCount - 1);
            }
            if (checkNotEmptyValue(shroud)) {
                sum += shroud.price;
            }
            if (checkNotEmptyValue(shroudClothes)) {
                sum += shroudClothes.price;
            }
            if (checkNotEmptyValue(coffin)) {
                sum += coffin.price;
            }
            if (checkNotEmptyValue(cleanOption)) {
                sum += cleanOption.price;
            }
            if (checkNotEmptyValue(manpowerDirector)) {
                sum += manpowerDirector.price;
            }
            if (checkNotEmptyValue(manpowerSubDirector)) {
                sum += manpowerSubDirector.price;
            }
            if (checkNotEmptyValue(manpowerHelper)) {
                sum += manpowerHelper.price;
            }
            if (checkNotEmptyValue(estimateEtc)) {
                sum += estimateEtc.reduce((prev, curr) => prev + curr.price, 0);
            }

            return sum;
        })(),
    };
}

function FuneralEstimate() {
    const dispatch = useDispatch();
    const modalMethods = useModal();

    const funeralItem = useSelector((state) => state.funeralItem);
    const {
        id,
        deceased,
        startDate,
        end_date: endDate,
        range,
        rangeCd,
        visitor,
        visitorCd,
        area,
        status,
        memo,
        bereaved,
    } = funeralItem[FuneralItemTypes.FUNERAL_ESTIMATE_REQUEST];

    const { funeralHallId, items, estimateEtc } = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );

    const { data: options } = useQuery(
        ['getOption', 'funeralHallId : ' + funeralHallId],
        {
            queryFn: async () => {
                const options = (
                    await axios.get(`/funeral/option/${funeralHallId}`)
                ).data.options;

                return {
                    ...options,
                    manpowerDirector: { price: 250000, cnt: 0, days: 0 },
                    manpowerSubDirector: { price: 100000, cnt: 0, days: 0 },
                    manpowerHelper: { price: 9160, cnt: 0, hours: 0 },
                };
            },
            enabled: !!funeralHallId,
        }
    );

    useEffect(() => {
        if (!options) {
            return;
        }
        dispatch(
            setFuneralItem({ type: FuneralItemTypes.OPTIONS, data: options })
        );
        // 기본 옵션 적용
        [...Object.keys(options)].forEach((key) => {
            const option = options[key];
            // option key이름이 flower일때
            if (key === 'flower') {
                key = 'shrineFlower';
            }

            // 옵션 리스트 형태인경우
            if (Array.isArray(option)) {
                // 기본 옵션
                const defaultOption = option.find(
                    ({ default: isDefault }) => isDefault
                );
                // 기본 옵션 적용
                if (defaultOption) {
                    dispatch(
                        setFormState({
                            type: FormTypes.FUNERAL_ESTIMATE,
                            prop: key + 'Id',
                            data: defaultOption.optionId,
                        })
                    );
                    dispatch(
                        setFormSelectItem({
                            type: FormTypes.FUNERAL_ESTIMATE,
                            prop: key,
                            data: defaultOption,
                        })
                    );
                }
            } else if (
                ![
                    'manClothes',
                    'womanClothes',
                    'manpowerDirector',
                    'manpowerSubDirector',
                    'manpowerHelper',
                    'estimateEtc',
                    'saveAndSend',
                ].find((currKey) => currKey === key)
            ) {
                dispatch(
                    setFormState({
                        type: FormTypes.FUNERAL_ESTIMATE,
                        prop: key + 'Id',
                        data: option.optionId,
                    })
                );
                dispatch(
                    setFormSelectItem({
                        type: FormTypes.FUNERAL_ESTIMATE,
                        prop: key,
                        data: option,
                    })
                );
            }
        });
    }, [options]);

    const onSubmit = (event) => {
        event.preventDefault();
    };

    const displayValues = getDisplayValues(
        items,
        funeralItem[FuneralItemTypes.FUNERAL_ESTIMATE_REQUEST],
        estimateEtc
    );

    useEffect(() => {
        axios.get(`/estimate/suggest/funeral/${id}`).then(({ data }) => {
            if (data.success) {
                let est = data.estimateData;
                console.info('EST', est);
                if (est.funeralHall) {
                    dispatch(
                        setFormState({
                            type: FormTypes.FUNERAL_ESTIMATE,
                            prop: 'funeralHallId',
                            data: est.funeralHall.funeralHallId,
                        })
                    );
                    dispatch(
                        setFormSelectItem({
                            type: FormTypes.FUNERAL_ESTIMATE,
                            prop: 'funeralHall',
                            data: est.funeralHall,
                        })
                    );

                    if (est.shrineFlower) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'shrineFlowerId',
                                data: est.shrineFlower.optionId,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'shrineFlower',
                                data: est.shrineFlower,
                            })
                        );
                    }
                    if (est.room) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'roomId',
                                data: est.room.optionId,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'room',
                                data: est.room,
                            })
                        );
                    }
                    if (est.portraitPhoto) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'portraitPhotoId',
                                data: est.portraitPhoto.optionId,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'portraitPhoto',
                                data: est.portraitPhoto,
                            })
                        );
                    }
                    if (est.coffin) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'coffinId',
                                data: est.coffin.optionId,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'coffin',
                                data: est.coffin,
                            })
                        );
                    }
                    if (est.shroudClothes) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'shroudClothesId',
                                data: est.shroudClothes.optionId,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'shroudClothes',
                                data: est.shroudClothes,
                            })
                        );
                    }
                    if (est.manClothes) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manClothes',
                                data: est.manClothes,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manClothes',
                                data: est.manClothes,
                            })
                        );
                    }
                    if (est.womanClothes) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'womanClothes',
                                data: est.womanClothes,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'womanClothes',
                                data: est.womanClothes,
                            })
                        );
                    }
                    if (est.moveOption) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'moveOptionId',
                                data: est.moveOption.optionId,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'moveOption',
                                data: est.moveOption,
                            })
                        );
                    }
                    if (est.coffin) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'coffinId',
                                data: est.coffin.optionId,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'coffin',
                                data: est.coffin,
                            })
                        );
                    }

                    if (est.manpowerDirector) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerDirector',
                                data: est.manpowerDirector,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerDirector',
                                data: est.manpowerDirector,
                            })
                        );
                    }
                    if (est.manpowerSubDirector) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerSubDirector',
                                data: est.manpowerSubDirector,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerSubDirector',
                                data: est.manpowerSubDirector,
                            })
                        );
                    }
                    if (est.manpowerHelper) {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerHelper',
                                data: est.manpowerHelper,
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerHelper',
                                data: est.manpowerHelper,
                            })
                        );
                    }
                }
            }
        });
    }, []);

    return (
        <DefaultModalLayout
            header={{ content: '장례견적 작성', left: { content: '취소' } }}
        >
            <FuneralEstimateForm onSubmit={onSubmit}>
                <div>
                    <FuneralItemInputLayout label="장례기간">
                        <Input
                            value={`  
                                        ${getTermDateString(
                                            startDate,
                                            endDate
                                        )} 
                                        (${range})
                                  `}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>

                    <FuneralItemInputLayout label="장례식장">
                        <InputSelect
                            value={area}
                            modal={{
                                type: modalTypes.default
                                    .SELECT_FUNERAL_FUNERAL_HALL,
                            }}
                            {...displayValues.funeralHall}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>

                    <FuneralItemInputLayout label="빈소">
                        <InputSelect
                            placeholder={
                                funeralHallId
                                    ? '빈소를 선택해주세요.'
                                    : '장례식장을 선택해주세요.'
                            }
                            modal={
                                funeralHallId && {
                                    type: modalTypes.default
                                        .SELECT_FUNERAL_ROOM,
                                }
                            }
                            {...displayValues.room}
                            {...commonInputProps}
                        />
                        <InputSelect
                            placeholder={
                                funeralHallId
                                    ? '제단 꽃 장식을 선택해주세요.'
                                    : '장례식장을 선택해주세요.'
                            }
                            modal={
                                funeralHallId && {
                                    type: modalTypes.default
                                        .SELECT_FUNERAL_FLOWER,
                                }
                            }
                            {...displayValues.shrineFlower}
                            {...commonInputProps}
                        />
                        <InputSelect
                            placeholder={
                                funeralHallId
                                    ? '영정사진을 선택해주세요.'
                                    : '장례식장을 선택해주세요.'
                            }
                            modal={
                                funeralHallId && {
                                    type: modalTypes.default
                                        .SELECT_FUNERAL_PORTRAIT_PHOTO,
                                }
                            }
                            {...displayValues.portraitPhoto}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>

                    <FuneralItemInputLayout label="상복">
                        {funeralHallId ? (
                            <>
                                <InputSelect
                                    modal={{
                                        type: modalTypes.default
                                            .SELECT_FUNERAL_CLOTHES,
                                    }}
                                    {...displayValues.manClothes}
                                    {...commonInputProps}
                                />
                                <InputSelect
                                    modal={{
                                        type: modalTypes.default
                                            .SELECT_FUNERAL_CLOTHES,
                                    }}
                                    {...displayValues.womanClothes}
                                    {...commonInputProps}
                                />
                            </>
                        ) : (
                            <InputSelect value="대여" {...commonInputProps} />
                        )}
                    </FuneralItemInputLayout>

                    <FuneralItemInputLayout label="예상 조문객">
                        <InputSelect
                            placeholder="장례식장을 선택해주세요."
                            {...displayValues.visitorFood}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>
                    <FuneralItemInputLayout label="운구">
                        <InputSelect
                            placeholder="장례식장을 선택해주세요."
                            modal={
                                funeralHallId && {
                                    type: modalTypes.default
                                        .SELECT_FUNERAL_MOVE,
                                }
                            }
                            {...displayValues.moveOption}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>
                    <FuneralItemInputLayout label="수시">
                        <InputSelect
                            placeholder="장례식장을 선택해주세요."
                            {...displayValues.washOption}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>

                    <FuneralItemInputLayout label="안치">
                        <InputSelect
                            placeholder="장례식장을 선택해주세요."
                            {...displayValues.sepulchre}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>
                    <FuneralItemInputLayout label="염습">
                        <InputSelect
                            placeholder="장례식장을 선택해주세요."
                            {...displayValues.shroud}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>
                    <FuneralItemInputLayout label="입관">
                        <InputSelect
                            placeholder={
                                funeralHallId
                                    ? '수의를 선택해주세요.'
                                    : '장례식장을 선택해주세요.'
                            }
                            modal={
                                funeralHallId && {
                                    type: modalTypes.default
                                        .SELECT_FUNERAL_SHROUD_CLOTHES,
                                }
                            }
                            {...displayValues.shroudClothes}
                            {...commonInputProps}
                        />
                        <InputSelect
                            placeholder={
                                funeralHallId
                                    ? '관을 선택해주세요.'
                                    : '장례식장을 선택해주세요.'
                            }
                            modal={
                                funeralHallId && {
                                    type: modalTypes.default
                                        .SELECT_FUNERAL_COFFIN,
                                }
                            }
                            {...displayValues.coffin}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>
                    <FuneralItemInputLayout label="청소">
                        <InputSelect
                            placeholder="장례식장을 선택해주세요."
                            {...displayValues.cleanOption}
                            {...commonInputProps}
                        />
                    </FuneralItemInputLayout>
                    <FuneralItemInputLayout label="인력파견">
                        {funeralHallId ? (
                            <>
                                <InputSelect
                                    modal={{
                                        type: modalTypes.default
                                            .SELECT_FUNERAL_MANPOWER,
                                    }}
                                    {...displayValues.manpowerDirector}
                                    {...commonInputProps}
                                />
                                <InputSelect
                                    modal={{
                                        type: modalTypes.default
                                            .SELECT_FUNERAL_MANPOWER,
                                    }}
                                    {...displayValues.manpowerSubDirector}
                                    {...commonInputProps}
                                />
                                <InputSelect
                                    modal={{
                                        type: modalTypes.default
                                            .SELECT_FUNERAL_MANPOWER,
                                    }}
                                    {...displayValues.manpowerHelper}
                                    {...commonInputProps}
                                />
                            </>
                        ) : (
                            <InputSelect
                                placeholder="장례식장을 선택해주세요."
                                {...commonInputProps}
                            />
                        )}
                    </FuneralItemInputLayout>
                    <FuneralItemInputLayout label="기타">
                        {estimateEtc.length > 0 &&
                            estimateEtc.map(({ name, price }, idx) => (
                                <div
                                    key={idx}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <InputSelect
                                        value={name}
                                        extra={numberAddComma(price) + '원'}
                                        {...commonInputProps}
                                    />
                                    <button
                                        style={{
                                            color: 'rgb(195, 169, 129)',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                setFormState({
                                                    type: FormTypes.FUNERAL_ESTIMATE,
                                                    prop: 'estimateEtc',
                                                    data: estimateEtc.filter(
                                                        (_, etcIdx) =>
                                                            idx !== etcIdx
                                                    ),
                                                })
                                            );
                                        }}
                                    >
                                        삭제
                                    </button>
                                </div>
                            ))}

                        <div
                            style={{
                                width: '100%',
                                textAlign: 'right',
                            }}
                        >
                            <button
                                style={{
                                    margin: '10px 0',
                                    color: 'rgb(195, 169, 129)',
                                }}
                                onClick={modalMethods.openModal({
                                    type: modalTypes.dialog.ADD_FUNERAL_ETC,
                                })}
                            >
                                항목 추가
                            </button>
                        </div>
                    </FuneralItemInputLayout>

                    <div
                        style={{
                            margin: '0 -16px',
                            width: 'calc(100% + 32px)',
                            height: '8px',
                            background: '#F9F9F9',
                        }}
                    />
                    <Flex.Wrapper
                        justifyContent="space-between"
                        style={{ margin: '18px 0 58px' }}
                    >
                        <strong>견적 금액</strong>
                        <div style={{ color: '#ff5160' }}>
                            <strong>
                                {numberAddComma(displayValues.totalPrice)}
                            </strong>{' '}
                            원
                        </div>
                    </Flex.Wrapper>
                </div>
                <Button
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        margin: '0 -16px',
                        width: 'calc(100% + 32px)',
                        cursor: funeralHallId ? 'pointer' : 'default',
                    }}
                    disabled={!funeralHallId}
                    onClick={() => {
                        dispatch(
                            openModal({
                                type: modalTypes.dialog
                                    .ASK_REQUEST_FUNERAL_ESTIMATE,
                            })
                        );
                    }}
                >
                    견적 발송
                </Button>
            </FuneralEstimateForm>
        </DefaultModalLayout>
    );
}

export default FuneralEstimate;
