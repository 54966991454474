import styled from 'styled-components';
import { isEmptyObj } from './../common/util';

export const DEFAULT = 'default';
export const LIGHT = 'light';
export const REVERSE = 'reverse';

const themeObj = {
    default: {
        inActive: { bg: '#BBBBBB' },
        active: { bg: '#C3A981' },
    },
    light: {
        inActive: { bg: '#EEEEEE', fontColor: '#BBBBBB' },
        active: { bg: '#C3A981' },
    },
    reverse: {
        inActive: { bg: '#C3A981', fontColor: '#FFFFFF' },
        active: { bg: '#FFFFFF', fontColor: '#C3A981' },
    },
};

const Button = styled.button`
    width: ${({ width = '100%' }) =>
        String(width).indexOf('%') === -1 ? width + 'px' : width};
    padding: 15px 22px;
    font-weight: bold;
    color: ${({ theme, disabled }) => {
        const { inActive, active } = !isEmptyObj(theme)
            ? themeObj[theme]
            : themeObj[DEFAULT];
        if (!disabled) {
            return active.fontColor || '#FFFFFF';
        }
        return inActive.fontColor || '#FFFFFF';
    }};
    background-color: ${({ theme, disabled }) => {
        const { inActive, active } = !isEmptyObj(theme)
            ? themeObj[theme]
            : themeObj[DEFAULT];
        if (!disabled) {
            return active.bg || '#C3A981';
        }
        return inActive.bg || '#C3A981';
    }};
`;

Button.Circle = styled(Button)`
    border-radius: 100px;
`;

Button.Text = styled(Button)`
    background-color: transparent;
    color: ${({ color = '#FFFFFF' }) => color};
`;

export default Button;
