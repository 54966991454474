import React, { useMemo } from 'react';

import EstimateImage from './../../resources/image/menu/ic-estimate.svg';
import ActiveEstimateImage from './../../resources/image/menu/ic-estimate-active.svg';

import ManagementImage from './../../resources/image/menu/ic-management.svg';
import ActiveManagementImage from './../../resources/image/menu/ic-management-active.svg';

import ChattingImage from './../../resources/image/menu/ic-chatting.svg';
import ActiveChattingImage from './../../resources/image/menu/ic-chatting-active.svg';
import Flex from './../../Style/Flex';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const FooterWrapper = styled.div`
    width: 100%;
    border-top: 0.5px solid #d9d9d9;
    font-size: 10px;
    font-weight: 500;
    background: #fff;
    padding: 8px 0 3px;
    position: sticky;
    bottom: 0;
    color: #bbbbbb;
`;

function Footer() {
    const { pathname } = useLocation();

    const menus = useMemo(
        () => [
            {
                name: '견적요청',
                url: '/',
                icons: [EstimateImage, ActiveEstimateImage],
            },
            {
                name: '장례관리',
                url: '/manage',
                icons: [ManagementImage, ActiveManagementImage],
            },
            {
                name: '온라인 상담',
                url: '/consult',
                icons: [ChattingImage, ActiveChattingImage],
            },
        ],
        []
    );

    return (
        <FooterWrapper className="select-none">
            <Flex.Wrapper as="ul" style={{ height: '100%' }}>
                {menus.map((ele, idx) => (
                    <Flex.Item flex={1} as="li" key={idx}>
                        <Link to={ele.url}>
                            <Flex.Wrapper
                                gap={'5px'}
                                column
                                style={
                                    ele.url === pathname
                                        ? { color: ' #C3A981' }
                                        : {}
                                }
                            >
                                {/* 현재 url과 일치하는 메뉴라면 active img */}
                                <img
                                    src={
                                        ele.url === pathname
                                            ? ele.icons[1]
                                            : ele.icons[0]
                                    }
                                    alt={ele.name}
                                />
                                {ele.name}
                            </Flex.Wrapper>
                        </Link>
                    </Flex.Item>
                ))}
            </Flex.Wrapper>
        </FooterWrapper>
    );
}

export default Footer;
