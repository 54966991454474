import React, { useEffect } from 'react';
import DefaultLayout from '../../Layouts/DefaultLayout';
import Space from '../../Style/Space';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getDateString, getTermDateString, getTime } from '../../common/util';

import EnterImage from './../../resources/image/ic_enter.svg';
import Flex from '../../Style/Flex';
import { modalTypes } from '../../common/constants';
import { openModal } from '../../redux/modal';
import { numberAddComma } from '../../common/util';
import { clearFormState, setFormState } from '../../redux/form';
import { FormTypes } from './../../redux/form';
import { useQuery } from 'react-query';
import axios from 'axios';

const Card = styled.div`
    padding: 25px 20px;
    background: #ffffff;
    box-shadow: 1px 4px 14px 4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    font-size: 15px;
`;

function FuneralManagement() {
    const { me } = useSelector((state) => state.user);
    const { funeralManagement } = me || {};

    const dispatch = useDispatch();

    const { data: fixedList } = useQuery(['getFixedList'], {
        queryFn: async () =>
            (await axios.get('/estimate/funeral/fixedList')).data.data,
        enabled: !!me,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        console.info(fixedList);
    }, [fixedList]);

    return (
        <DefaultLayout>
            <Space height={30} />
            <div>
                {fixedList && (
                    <>
                        {fixedList.map((fixed, idx) => {
                            console.info(fixed);
                            let {
                                deceased,
                                funeralHall,
                                startDate,
                                endDate,
                                totalPrice,
                            } = fixed;
                            let rangeString = '';
                            switch (fixed.rangeCd) {
                                case '00':
                                    rangeString = '(3일)';
                                    break;
                                case '01':
                                    rangeString = '(2일)';
                                    break;
                                default:
                                    rangeString = '(3일)';
                                    break;
                            }

                            return (
                                <React.Fragment key={idx}>
                                    <Space height={30} />
                                    <button
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    type: modalTypes.default
                                                        .FUNERAL_MANAGEMENT_VIEW_TEMP,
                                                    data: {
                                                        ...fixed,
                                                        rangeString: `${getTermDateString(
                                                            startDate,
                                                            endDate,
                                                            'point'
                                                        )} ${rangeString}`,
                                                    },
                                                })
                                            );
                                        }}
                                    >
                                        <Card>
                                            <Flex.Wrapper
                                                justifyContent="space-between"
                                                style={{
                                                    paddingBottom: '18px',
                                                    marginBottom: '18px',
                                                    borderBottom:
                                                        '1px solid #C3A981',
                                                }}
                                            >
                                                <strong
                                                    style={{
                                                        fontSize: '18px',
                                                        color: '#191919',
                                                    }}
                                                >
                                                    故 {deceased}
                                                </strong>
                                                <img
                                                    src={EnterImage}
                                                    alt="enter"
                                                />
                                            </Flex.Wrapper>
                                            <div>
                                                <Flex.Wrapper
                                                    justifyContent="space-between"
                                                    alignItems="unset"
                                                    style={{ color: '#333' }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        {getTermDateString(
                                                            startDate,
                                                            endDate,
                                                            'point'
                                                        )}{' '}
                                                        {rangeString}
                                                    </div>
                                                    <strong>
                                                        {numberAddComma(
                                                            totalPrice
                                                        ) + '원'}
                                                    </strong>
                                                </Flex.Wrapper>
                                                <Flex.Wrapper
                                                    as="ul"
                                                    column
                                                    alignItems="stretch"
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#666666',
                                                        margin: '5px 0 0 0',
                                                        fontWeight: '700',
                                                    }}
                                                >
                                                    <Flex.Wrapper
                                                        as="li"
                                                        justifyContent="stretch"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '3px',
                                                                height: '3px',
                                                                background:
                                                                    '#666',
                                                                borderRadius:
                                                                    '100%',
                                                                margin: '3px 7px 0 7px',
                                                            }}
                                                        />
                                                        {funeralHall}
                                                    </Flex.Wrapper>
                                                </Flex.Wrapper>
                                            </div>
                                        </Card>
                                    </button>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
                {funeralManagement && (
                    <>
                        {funeralManagement.map((ele, idx) => {
                            const { deceased, funeral, leaveForACemetery } =
                                ele;

                            const {
                                cemetery: { item: cemetery },
                                crematorium: {
                                    reservation: { time: reservationTime },
                                },
                            } = leaveForACemetery;
                            const vehicleString =
                                leaveForACemetery.vehicle.reduce(
                                    (prev, curr) =>
                                        prev +
                                        `${prev !== '' ? ', ' : ''}` +
                                        `${curr.name} ${curr.units[0].num}${curr.units[0].name}`,
                                    ''
                                );

                            return (
                                <React.Fragment key={idx}>
                                    <Space height={30} />
                                    <button
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    // type: ModalTypes.FUNERAL_MANAGEMENT_VIEW,
                                                    data: ele,
                                                })
                                            );
                                            dispatch(clearFormState());
                                            dispatch(
                                                setFormState({
                                                    type: FormTypes.MANAGE_FUNERAL,
                                                    data: {
                                                        ...ele.funeral,
                                                        ...ele.leaveForACemetery,
                                                        ...ele,
                                                    },
                                                })
                                            );
                                        }}
                                    >
                                        <Card>
                                            <Flex.Wrapper
                                                justifyContent="space-between"
                                                style={{
                                                    paddingBottom: '18px',
                                                    marginBottom: '18px',
                                                    borderBottom:
                                                        '1px solid #C3A981',
                                                }}
                                            >
                                                <strong
                                                    style={{
                                                        fontSize: '18px',
                                                        color: '#191919',
                                                    }}
                                                >
                                                    故 {deceased.name}
                                                </strong>
                                                <img
                                                    src={EnterImage}
                                                    alt="enter"
                                                />
                                            </Flex.Wrapper>
                                            <div>
                                                <Flex.Wrapper
                                                    justifyContent="space-between"
                                                    alignItems="unset"
                                                    style={{ color: '#333' }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        {getTermDateString(
                                                            funeral.date.start,
                                                            funeral.date.end,
                                                            'point'
                                                        )}
                                                    </div>
                                                    <strong>
                                                        {numberAddComma(
                                                            funeral.totalPrice
                                                        ) + '원'}
                                                    </strong>
                                                </Flex.Wrapper>
                                                <Flex.Wrapper
                                                    as="ul"
                                                    column
                                                    alignItems="stretch"
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#666666',
                                                        margin: '5px 0 0 0',
                                                        fontWeight: '700',
                                                    }}
                                                >
                                                    <Flex.Wrapper
                                                        as="li"
                                                        justifyContent="stretch"
                                                    >
                                                        <div
                                                            style={{
                                                                width: '3px',
                                                                height: '3px',
                                                                background:
                                                                    '#666',
                                                                borderRadius:
                                                                    '100%',
                                                                margin: '3px 7px 0 7px',
                                                            }}
                                                        />
                                                        {funeral.funeral.name}
                                                    </Flex.Wrapper>
                                                </Flex.Wrapper>
                                            </div>
                                            <Space height={12} />
                                            <div>
                                                <Flex.Wrapper
                                                    justifyContent="space-between"
                                                    alignItems="unset"
                                                    style={{ color: '#333' }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        {getDateString(
                                                            leaveForACemetery.date,
                                                            'point'
                                                        )}{' '}
                                                        (
                                                        {getTime(
                                                            leaveForACemetery.date
                                                        )}
                                                        )
                                                    </div>
                                                    <strong>
                                                        {numberAddComma(
                                                            leaveForACemetery.totalPrice
                                                        ) + '원'}
                                                    </strong>
                                                </Flex.Wrapper>

                                                <Flex.Wrapper
                                                    as="ul"
                                                    column
                                                    alignItems="stretch"
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#666666',
                                                        margin: '5px 0 0 0',
                                                        fontWeight: '700',
                                                    }}
                                                >
                                                    {[
                                                        <>
                                                            {cemetery.name}
                                                            &nbsp;
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                }}
                                                            >
                                                                (
                                                                {getTime(
                                                                    reservationTime.start
                                                                )}
                                                                ~
                                                                {getTime(
                                                                    reservationTime.end
                                                                )}
                                                                )
                                                            </div>
                                                        </>,
                                                        leaveForACemetery
                                                            .crematorium.item
                                                            .name,
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    '400',
                                                            }}
                                                            key={'vehicle'}
                                                        >
                                                            {vehicleString}
                                                        </div>,
                                                    ].map((ele, idx) => (
                                                        <Flex.Wrapper
                                                            as="li"
                                                            justifyContent="stretch"
                                                            key={idx}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '3px',
                                                                    height: '3px',
                                                                    background:
                                                                        '#666',
                                                                    borderRadius:
                                                                        '100%',
                                                                    margin: '3px 7px 0 7px',
                                                                }}
                                                            />
                                                            {ele}
                                                        </Flex.Wrapper>
                                                    ))}
                                                </Flex.Wrapper>
                                            </div>
                                        </Card>
                                    </button>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}

                <Space height={100} />
            </div>
        </DefaultLayout>
    );
}

export default FuneralManagement;
