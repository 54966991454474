import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Input from '../Components/Form/Input';
import Logo from '../resources/image/Logo.svg';
import Button from '../Style/Button';
import Message from '../Style/Message';
import Space from '../Style/Space';
import InputPassword from './../Components/Form/InputPassword';
import Flex from './../Style/Flex';
import { getErrorMessage, addPhoneHyphen } from './../common/util.js';
import { useDispatch } from 'react-redux';
import { modalTypes } from '../common/constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import useGetMe from '../hooks/useGetMe';
import regexps from './../common/regexp';
import { openModal } from '../redux/modal';
import { setToken } from '../redux/user';

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useGetMe();

    const { mutate: login } = useMutation(['login'], {
        mutationFn: async ({ id, password }) =>
            (
                await axios.post('/users/partner/signIn', {
                    account: id,
                    password,
                })
            ).data,
        onSuccess: ({ success, code, accessToken }) => {
            if (success) {
                dispatch(setToken(accessToken));
                if (window.ReactNativeWebView) {
                    // RN에 FCM 토큰 요청
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            type: 'LOGIN',
                        })
                    );
                }
                navigate('/');
                return;
            }
        },
    });

    // 일회용 로그인
    const { mutate: oneTimeLogin } = useMutation(['onetimeLogin'], {
        mutationFn: async ({ phone, password }) =>
            (
                await axios.post('/users/partner/oneTimeSignIn', {
                    phone: addPhoneHyphen(phone),
                    authCode: password,
                })
            ).data,
        onSuccess: ({ success, code, accessToken }) => {
            if (success) {
                dispatch(setToken(accessToken));
                navigate('/');
                return;
            }
            if (code === 'E01') {
                setError('password', {
                    type: 'noMember',
                    message: '존재하지 않는 회원 아이디입니다',
                });
            }
            if (code === 'E02') {
                setError('password', {
                    type: 'notMatched',
                    message: '비밀번호가 틀렸습니다.',
                });
            }
        },
    });

    const onSubmit = (data) => {
        const emailRegexp = new RegExp(regexps.email);
        // 아이디가 이메일 형식이라면
        if (emailRegexp.test(data.id)) {
            login(data);
        } else {
            login(
                { ...data, id: addPhoneHyphen(data.id) },
                {
                    onSuccess: ({ success }) => {
                        // 로그인 실패시 일회용 로그인으로 재시도
                        if (!success) {
                            oneTimeLogin({
                                ...data,
                                phone: addPhoneHyphen(data.id),
                            });
                        }
                    },
                }
            );
        }
    };

    useEffect(() => {
        setValue('id', '010-0000-0000');
        setValue('password', 'p@ssW0Rd!!!');
    }, []);

    return (
        <div style={{ margin: '0 16px' }}>
            <Flex.Wrapper column>
                <Space height={70} />
                <img src={Logo} alt="Logo" />
                <Space height={30} />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: '100%' }}
                >
                    <Flex.Wrapper column>
                        <Input
                            placeholder={'이메일 또는 휴대폰 번호'}
                            {...register('id', {
                                required:
                                    '이메일 또는 휴대폰 번호를 입력해주세요.',
                                validate: {
                                    checkPattern: (v) => {
                                        let valid = false;
                                        // 정규 표현식중에 맞는 표현이 있다면 true
                                        [regexps.email, regexps.phone].forEach(
                                            (regexp) => {
                                                if (!valid) {
                                                    valid = !!regexp.test(v);
                                                }
                                            }
                                        );
                                        return (
                                            valid ||
                                            '이메일 또는 휴대폰 번호의 형식에 맞춰 입력해주세요.'
                                        );
                                    },
                                },
                            })}
                        />
                        <Space height={8} />
                        <InputPassword
                            placeholder="비밀번호"
                            {...register('password', {
                                required: '비밀번호를 입력해주세요.',
                            })}
                        />
                        <Space height={8} />
                        <Message>{getErrorMessage(errors)}</Message>
                        <Space height={24} />
                        <Button.Circle active>로그인</Button.Circle>
                        <Space height={30} />
                    </Flex.Wrapper>
                </form>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        dispatch(
                            openModal({
                                type: modalTypes.default.REQUEST_PASSWORD,
                            })
                        );
                    }}
                >
                    + 일회용 비밀번호 요청
                </span>
            </Flex.Wrapper>
        </div>
    );
}

export default Login;
