import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DefaultModalLayout from './../../Layouts/ModalLayouts/DefaultModalLayout';
import styled from 'styled-components';
import ImageIconImage from './../../resources/image/ic-img.svg';
import SendIconImage from './../../resources/image/ic-send.svg';
import { useForm } from 'react-hook-form';
import useSendBird from '../../hooks/useSendBird';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAlert } from 'react-alert';

// 날짜 문자 가져오기
function getDateString(prevDate, addComma = false) {
    const date = new Date(prevDate);

    const year = date.getFullYear();
    const month =
        date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    const separator = addComma ? '.' : '';

    return year + separator + month + separator + day;
}
// 시간 문자 가져오기
function getHourMinute(prevDate) {
    const date = new Date(prevDate);

    const hours =
        date.getHours() > 12
            ? `오후 ${date.getHours() - 12}`
            : `오전 ${date.getHours()}`;
    const minutes =
        date.getMinutes() < 9 ? '0' + date.getMinutes() : date.getMinutes();

    return hours + ':' + minutes;
}

const ChatWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ChatMessages = styled.div`
    padding-top: 30px;
    padding-right: 10px;
    margin-right: -10px;
    max-height: calc(100vh - 110.4px);
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;

    .date {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        color: #666666;
        text-align: center;
    }
    .chat-message {
        margin-top: 15px;
        display: flex;
        gap: 10px;

        .profile {
            background: rgba(187, 187, 187, 0.5);
            border-radius: 18px;
            width: 36px;
            height: 36px;

            display: flex;
            align-items: center;
            justify-content: center;

            font-weight: 700;
            font-size: 14px;
            color: #333333;
        }
        .chat-message-right {
            .content {
                background: #f1f1f1;
                border-radius: 0px 20px 20px 20px;
                padding: 12px;
                font-size: 14px;
                color: #191919;

                &.image {
                    background: transparent !important;
                    max-width: 100px;
                    img {
                        width: 100%;
                    }
                }
            }
            .time {
                margin-top: 8px;
                font-family: 'Roboto', sans-serif;
                font-size: 11px;
                color: #888888;
            }
        }
        &.me {
            justify-content: flex-end;
            text-align: end;
            .profile {
                display: none;
            }

            .chat-message-right {
                .content {
                    background: #edf0f9;
                    border-radius: 20px 0px 20px 20px;
                }
            }
        }
    }
`;

const ChatForm = styled.form`
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .message-form {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid #dddddd;
        border-radius: 100px;
        padding: 10px;
        margin-left: 15px;

        .message-input {
            flex: 1;
            border: none;
            outline: none;
            padding: 0 10px;
        }
    }
`;

// 허용 업로드 이미지 타입
const acceptFileTypes = ['image/png', 'image/jpeg', 'image/gif'];

function ChatRoom({ channelUrl }) {
    const alert = useAlert();
    const { register, handleSubmit, reset } = useForm();
    const { sendbirdID } = useSelector((state) => state.user.me) || {};
    const sb = useSendBird();
    const uploadImgRef = useRef(null);
    const chatRoomWrapper = useRef(null);
    const commentListQuery = useRef(null);
    const [channel, setChannel] = useState(null);
    const [messages, setMessages] = useState([]);
    // 채널 이름
    const channelName = (() => {
        if (!channel) {
            return '';
        }
        const { members } = channel;
        if (members.length === 1) {
            return members[0].nickname;
        }
        return members
            .filter(({ userId }) => userId !== sendbirdID)
            .map(({ nickname }) => nickname)
            .join(', ');
    })();

    // 스크롤을 최하단으로 이동
    const goEndScroll = () => {
        chatRoomWrapper.current.scrollTop = 0;
    };

    // 메시지 로드
    const loadMessages = () => {
        if (
            commentListQuery.current.hasMore &&
            !commentListQuery.current.isLoading
        ) {
            commentListQuery.current.load((loadedMessages, error) => {
                if (error) {
                    console.error(error);
                }
                if (messages.length === 0) {
                    channel.markAsRead(() => {});
                }
                setMessages((prev) => [...prev, ...loadedMessages]);
            });
        }
    };

    // 메시지 전송
    const sendMessage = (message) => {
        const params = new sb.UserMessageParams();
        params.message = message;
        channel.sendUserMessage(params, (userMessage, error) => {
            if (error) {
                return console.error(error);
            }
            setMessages((prev) => [userMessage, ...prev]);
            goEndScroll();
            reset();
        });
    };

    // 파일 전송
    const sendFile = (file) => {
        const params = new sb.FileMessageParams();
        params.file = file;

        channel.sendFileMessage(params, (fileMessage, error) => {
            if (error) {
                return console.error(error);
            }
            setMessages((prev) => [fileMessage, ...prev]);
            goEndScroll();
        });
    };

    // 체널 초기화
    useEffect(() => {
        if (sb && !channel) {
            sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
                if (error) {
                    return console.error(error);
                }
                setChannel(channel);
            });
        }
    }, [sb]);

    // 댓글 리스트 쿼리 초기화
    useEffect(() => {
        if (sb && channel && !commentListQuery.current) {
            // There should only be one single instance per channel view.
            const listQuery = channel.createPreviousMessageListQuery();
            listQuery.limit = 30;
            listQuery.reverse = true;
            commentListQuery.current = listQuery;

            // 메시지 로드
            loadMessages();
        }
    }, [channel]);

    // 채널 메시지 수신이벤트 등록
    useEffect(() => {
        if (channel) {
            const channelId = channel.url;
            const channelHandler = new sb.ChannelHandler();
            channelHandler.onMessageReceived = (channel, message) => {
                setMessages((prev) => [message, ...prev]);
                channel.markAsRead(() => {});
            };
            sb.addChannelHandler(channelId, channelHandler);
            return () => {
                sb.removeChannelHandler(channelId);
            };
        }
    }, [channel]);

    const onSubmit = ({ message }) => {
        sendMessage(message);
    };

    return (
        <DefaultModalLayout
            header={{
                content: channelName,
                right: {
                    content: (
                        <button style={{ fontSize: '14px', color: '#485BA1' }}>
                            장례내용
                        </button>
                    ),
                },
            }}
        >
            <ChatWrapper
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <ChatMessages id="scrollableDiv" ref={chatRoomWrapper}>
                    <InfiniteScroll
                        dataLength={messages.length}
                        next={loadMessages}
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                        }}
                        inverse={true}
                        hasMore={
                            commentListQuery.current
                                ? commentListQuery.current.hasMore
                                : true
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        {messages.map((message, idx, currMessages) => {
                            const {
                                createdAt,
                                _sender: sender,
                                message: content,
                                messageType,
                                url,
                            } = message;
                            const isMe = sender.userId === sendbirdID;

                            return (
                                <React.Fragment key={idx}>
                                    <div
                                        className={`chat-message ${
                                            isMe ? 'me' : ''
                                        }`}
                                        key={idx}
                                    >
                                        {!isMe && (
                                            <div className="chat-message-left profile">
                                                {sender.nickname.substr(0, 1)}
                                            </div>
                                        )}
                                        <div className="chat-message-right">
                                            <div
                                                className={`content ${
                                                    messageType === 'file'
                                                        ? 'image'
                                                        : ''
                                                }`}
                                            >
                                                {messageType !== 'file' ? (
                                                    content
                                                ) : (
                                                    <img src={url} alt="chat" />
                                                )}
                                            </div>

                                            <div className="time">
                                                {currMessages[idx - 1] ? (
                                                    (getHourMinute(
                                                        createdAt
                                                    ) !==
                                                        getHourMinute(
                                                            currMessages[
                                                                idx - 1
                                                            ].createdAt
                                                        ) ||
                                                        sender.userId !==
                                                            currMessages[
                                                                idx - 1
                                                            ]._sender
                                                                .userId) && (
                                                        <div className="time">
                                                            {getHourMinute(
                                                                createdAt
                                                            )}
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="time">
                                                        {getHourMinute(
                                                            createdAt
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        // 날짜
                                        currMessages[idx + 1] ? (
                                            getDateString(
                                                currMessages[idx + 1].createdAt
                                            ) !== getDateString(createdAt) ? (
                                                <div className="date">
                                                    {getDateString(
                                                        createdAt,
                                                        true
                                                    )}
                                                </div>
                                            ) : null
                                        ) : (
                                            <div className="date">
                                                {getDateString(createdAt, true)}
                                            </div>
                                        )
                                    }
                                </React.Fragment>
                            );
                        })}
                    </InfiniteScroll>
                </ChatMessages>

                <ChatForm onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        accept={acceptFileTypes}
                        onChange={(event) => {
                            const { files } = event.target;
                            // 파일을 input한 경우
                            if (files && files.length > 0) {
                                const [file] = files;
                                // 업로드된 파일이 acceptFileTypes와 일치하는경우
                                if (
                                    !acceptFileTypes.find(
                                        (currType) => currType === file.type
                                    )
                                ) {
                                    return alert.info(
                                        '파일 형식이 잘못되었습니다. 이미지 파일을 업로드해주세요.'
                                    );
                                }
                                sendFile(file);
                            }
                        }}
                        ref={uploadImgRef}
                    />
                    <button
                        type="button"
                        className="upload-img"
                        onClick={() => uploadImgRef.current.click()}
                    >
                        <img src={ImageIconImage} alt="upload-img" />
                    </button>
                    <div className="message-form">
                        <input
                            className="message-input"
                            type={'text'}
                            {...register('message', { required: true })}
                        />
                        <button type="submit">
                            <img src={SendIconImage} alt="send" />
                        </button>
                    </div>
                </ChatForm>
            </ChatWrapper>
        </DefaultModalLayout>
    );
}

export default ChatRoom;
