import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FuneralItemInputLayout from '../../Components/Form/FuneralItemInputLayout';
import Modal from '../../Components/Modal';
import Input from './../../Components/Form/Input';
import Space from '../../Style/Space';
import Button from '../../Style/Button';
import { getTermDateString } from '../../common/util';
import InputSelect from './../../Components/Form/InputSelect';
import { useSelector } from 'react-redux';
import { SelectElementTypes } from '../../common/constants';
import { numberAddComma, isEmptyObj } from './../../common/util';
import Flex from '../../Style/Flex';
import { openModal } from '../../redux/modal';
import { setFormState } from '../../redux/form';
import ArrowRightImage from './../../resources/image/ic-arrow-right.svg';
import { FormTypes } from './../../redux/form';

const commonInputProps = {
    underline: 'none',
    disabled: true,
    as: 'div',
    style: { minHeight: '44px' },
};

function ChangeItemView({ content, increasePrice }) {
    return (
        <Flex.Wrapper
            justifyContent="space-between"
            style={{
                background: '#F3F0EA',
                padding: '10px',
                fontSize: '15px',
                marginBottom: '11px',
            }}
        >
            <div style={{ color: '#333', fontWeight: '500' }}>{content}</div>
            {increasePrice && (
                <div
                    style={{ color: increasePrice > 0 ? '#FF5160' : '#2F80ED' }}
                >
                    {increasePrice > 0 ? '+' : ''}{' '}
                    {numberAddComma(increasePrice)} 원
                </div>
            )}
        </Flex.Wrapper>
    );
}

function FuneralManagementView() {
    const dispatch = useDispatch();

    const onSubmit = (event) => {
        event.preventDefault();
    };

    const formState = useSelector(
        (state) => state.form[FormTypes.MANAGE_FUNERAL]
    );

    const prevFuneralManagement = useSelector(
        (state) => state.modal[0] && state.modal[0].data
    );
    const [funeralManagement, setFuneralManagement] = useState(
        prevFuneralManagement
    );

    const { deceased, reside, user, funeral, leaveForACemetery } =
        funeralManagement;

    const {
        estimate,
        funeral: funeralItem,
        mortuary,
        decoration,
        portraitPhoto,
        mourning,
        expectedMourners,
        carrying,
        laySbInState,
        shroud,
        coffin,
        manpower,
        gatherUpABody,
        dressingACorpseForBurial,
        cleaning,
    } = funeral;

    const {
        cemetery: { item: cemetery, tombstone },
        crematorium: { item: crematorium, reservation },
        vehicle,
        hearseOperation,
    } = leaveForACemetery;

    const { priceTags: funeralPriceTags } = funeralItem;

    const {
        mortuary: newMortuary,
        decoration: newDecoration,
        portraitPhoto: newPortraitPhoto,
        mourning: newMourning,
        expectedMourners: newExpectedMourners,
        carrying: newCarrying,
        shroud: newShroud,
        coffin: newCoffin,
        manpower: newManpower,
        otherCosts,
        cemetery: { tombstone: newTombstone },
        crematorium: { item: newCrematorium, reservation: newReservation },
        vehicle: newVehicle,
    } = formState;

    // dummy data set
    useEffect(() => {
        setFuneralManagement({
            ...funeralManagement,
            funeral: {
                ...funeralManagement.funeral,
                gatherUpABody: { name: '수시', price: 300000 },
                dressingACorpseForBurial: { name: '염습', price: 300000 },
                cleaning: { name: '청소 및 폐기물 처리', price: 330000 },
            },
        });
    }, []);

    // 장례기간
    const diffDay =
        (funeral.date.end - funeral.date.start) / (1000 * 60 * 60 * 24);
    const initInputValues = useCallback(() => {
        const funeralPrices = {
            mortuary: mortuary.dayPrice * diffDay,
            decoration: decoration.price,
            portraitPhoto: portraitPhoto.price,
            mourning: mourning
                .map((ele) => ele.price * ele.units[0].num)
                .reduce((prev, curr) => prev + curr),
            expectedMourners:
                expectedMourners.price * expectedMourners.units[0].num,
            carrying: carrying.price,
            laySbaInState:
                laySbInState * funeralPriceTags.laySbInState.hourPrice,
            shroud: shroud.price,
            coffin: coffin.price,
            gatherUpABody: gatherUpABody ? gatherUpABody.price : 0,
            dressingACorpseForBurial: dressingACorpseForBurial
                ? dressingACorpseForBurial.price
                : 0,
            cleaning: cleaning ? cleaning.price : 0,
            manpower: manpower
                .map(
                    ({ price, units }) =>
                        units.reduce((prev, curr) => prev.num * curr.num) *
                        price
                )
                .reduce((prev, curr) => prev + curr),
        };

        const newFuneralPrices = {
            mortuary: newMortuary ? newMortuary.dayPrice * diffDay : 0,
            decoration: newDecoration ? newDecoration.price : 0,
            portraitPhoto: newPortraitPhoto ? newPortraitPhoto.price : 0,
            mourning: newMourning
                ? newMourning
                      .map((ele) => ele.price * ele.units[0].num)
                      .reduce((prev, curr) => prev + curr)
                : 0,
            expectedMourners: newExpectedMourners
                ? newExpectedMourners.price * newExpectedMourners.units[0].num
                : 0,
            carrying: newCarrying ? newCarrying.price : 0,
            shroud: newShroud ? newShroud.price : 0,
            coffin: newCoffin ? newCoffin.price : 0,
            manpower: newManpower
                ? newManpower
                      .map(
                          ({ price, units }) =>
                              units.reduce(
                                  (prev, curr) => prev.num * curr.num
                              ) * price
                      )
                      .reduce((prev, curr) => prev + curr)
                : 0,
            otherCosts: otherCosts ? otherCosts.price : 0,
        };

        const leaveForACemeteryPrices = {
            useFee: tombstone.use.price,
            managementFee: tombstone.management.price,
            crematoriumReservation: reservation.price,
            vehicle: vehicle
                .map(({ units, price }) => price * units[0].num)
                .reduce((prev, curr) => prev + curr),
        };

        const newLeaveForACemeteryPrices = {
            useFee: newTombstone.use.price,
            managementFee: newTombstone.management.price,
            vehicle: newVehicle
                .map(({ units, price }) => price * units[0].num)
                .reduce((prev, curr) => prev + curr),
        };

        const funeralDefaultAmmount = Object.keys(funeralPrices)
            .map((key) => funeralPrices[key])
            .reduce((prev, curr) => prev + curr);
        const funeralAdditionalAmmount = Object.keys(newFuneralPrices)
            .map((key) => {
                const currPrice = newFuneralPrices[key] || 0;
                const prevPrice = funeralPrices[key] || 0;
                return currPrice - prevPrice;
            })
            .reduce((curr, prev) => curr + prev);

        const leaveForACemeteryDefaultAmmount = Object.keys(
            leaveForACemeteryPrices
        )
            .map((key) => leaveForACemeteryPrices[key])
            .reduce((prev, curr) => prev + curr);
        const leaveForACemeteryAdditionalAmmount = Object.keys(
            newLeaveForACemeteryPrices
        )
            .map((key) => {
                const currPrice = newLeaveForACemeteryPrices[key] || 0;
                const prevPrice = leaveForACemeteryPrices[key] || 0;
                return currPrice - prevPrice;
            })
            .reduce((curr, prev) => curr + prev);

        return {
            deceased: { value: '故 ' + deceased.name },
            reside: { value: reside.name },
            contact: { value: reside.contact.phone },
            isRequireCarrying: { value: funeral.carrying ? '필요' : '' },
            location: { value: funeral.location },
            subscriber: { value: user.name },
            date: {
                value: getTermDateString(funeral.date.start, funeral.date.end),
            },
            funeral: {
                value: funeralItem.name,
                extra: funeralItem.address,
            },
            mortuary: {
                value: `${mortuary.name} x ${diffDay}일`,
                extra: numberAddComma(mortuary.dayPrice * diffDay) + '원',
            },
            newMortuary: newMortuary &&
                newMortuary.idx !== mortuary.idx && {
                    content: `${newMortuary.name} x ${diffDay}일`,
                    increasePrice:
                        (newMortuary.dayPrice - mortuary.dayPrice) * diffDay,
                },
            decoration: {
                value: '재단 꽃 장식 ' + decoration.name,
                extra: numberAddComma(decoration.price) + '원',
            },
            newDecoration: newDecoration &&
                newDecoration.idx !== decoration.idx && {
                    content: '재단 꽃 장식 ' + decoration.name,
                    increasePrice: newDecoration.price - decoration.price,
                },
            portraitPhoto: {
                value: '영정사진 ' + portraitPhoto.name,
                extra: numberAddComma(portraitPhoto.price) + '원',
            },
            newPortraitPhoto: newPortraitPhoto &&
                newPortraitPhoto.idx !== portraitPhoto.idx && {
                    content: '영정사진 ' + newPortraitPhoto.name,
                    increasePrice: newPortraitPhoto.price - portraitPhoto.price,
                },
            mourning: mourning.map((ele) => ({
                value: `${ele.name} ${ele.units[0].num}벌`,
                extra: numberAddComma(ele.price * ele.units[0].num) + '원',
            })),
            newMourning: newMourning.map(
                (ele, idx) =>
                    ele.units[0].num !== mourning[idx].units[0].num && {
                        content: `${ele.name} ${ele.units[0].num}벌`,
                        increasePrice:
                            (ele.units[0].num - mourning[idx].units[0].num) *
                            ele.price,
                    }
            ),
            expectedMourners: {
                value: `${expectedMourners.units[0].num}명`,
                extra:
                    numberAddComma(
                        expectedMourners.price * expectedMourners.units[0].num
                    ) + '원',
            },
            newExpectedMourners: newExpectedMourners &&
                newExpectedMourners.units[0].num !==
                    expectedMourners.units[0].num && {
                    content: `${newExpectedMourners.units[0].num}명`,
                    increasePrice:
                        (newExpectedMourners.units[0].num -
                            expectedMourners.units[0].num) *
                        newExpectedMourners.price,
                },
            carrying: {
                value:
                    carrying.type === 'jurisdiction'
                        ? '관내 이동'
                        : `관외 이동 (${carrying.km}km)`,
                extra: numberAddComma(carrying.price) + '원',
            },
            newCarrying: !(
                (newCarrying.type === carrying.type &&
                    carrying.type === 'jurisdiction') ||
                newCarrying.km === carrying.km
            ) && {
                content:
                    newCarrying.type === 'jurisdiction'
                        ? '관내 이동'
                        : `관외 이동 (${newCarrying.km}km)`,
                increasePrice: newCarrying.price - carrying.price,
            },
            laySbaInState: {
                value: `${Math.floor(laySbInState / 24)}일 ${
                    laySbInState % 24
                }시간`,
                extra:
                    funeral &&
                    numberAddComma(
                        laySbInState * funeralPriceTags.laySbInState.hourPrice
                    ) + '원',
            },
            shroud: {
                value: shroud.name,
                extra: numberAddComma(shroud.price) + '원',
            },
            newShroud: newShroud &&
                newShroud.idx !== shroud.idx && {
                    content: newShroud.name,
                    increasePrice: newShroud.price - shroud.price,
                },
            coffin: {
                value: coffin.name,
                extra: numberAddComma(coffin.price) + '원',
            },
            newCoffin: newCoffin &&
                newCoffin.idx !== coffin.idx && {
                    content: newCoffin.name,
                    increasePrice: newCoffin.price - coffin.price,
                },
            manpower: manpower.map(({ name, price, units }) => ({
                value: `${name} ${units[0].num}인${
                    units[0].num > 0 ? ` x ${units[1].num}${units[1].name}` : ''
                }`,
                extra:
                    numberAddComma(
                        units.reduce((prev, curr) => prev.num * curr.num) *
                            price
                    ) + '원',
            })),
            newManpower: newManpower.map((ele, idx) => {
                const { name, price, units } = ele;
                const { price: prevPrice, units: prevUnits } = manpower[idx];

                return (
                    (units[0].num !== prevUnits[0].num ||
                        units[1].num !== prevUnits[1].num) && {
                        content: `${name} ${units[0].num}인${
                            units[0].num > 0
                                ? ` x ${units[1].num}${units[1].name}`
                                : ''
                        }`,
                        increasePrice:
                            (units.reduce((prev, curr) => prev.num * curr.num) -
                                prevUnits.reduce(
                                    (prev, curr) => prev.num * curr.num
                                )) *
                            price,
                    }
                );
            }),
            gatherUpABody: gatherUpABody && {
                value: numberAddComma(gatherUpABody.price) + ' 원',
            },
            dressingACorpseForBurial: dressingACorpseForBurial && {
                value: numberAddComma(dressingACorpseForBurial.price) + ' 원',
            },
            cleaning: cleaning && {
                value: cleaning.name,
                extra: numberAddComma(cleaning.price) + ' 원',
            },
            otherCosts: otherCosts
                ? {
                      value: (
                          <div
                              style={{
                                  color: '#FF5160',
                              }}
                          >
                              + {numberAddComma(otherCosts.price)}원
                          </div>
                      ),
                  }
                : { value: '0원' },
            funeralDefaultAmmount,
            funeralAdditionalAmmount,

            cemetery: cemetery && {
                value: cemetery.name,
                extra: cemetery.address,
            },
            tombstone: tombstone && {
                value: (
                    <>
                        {tombstone.area.name}
                        <img
                            src={ArrowRightImage}
                            alt="arrow-right"
                            style={{ margin: '0 8px' }}
                        />
                        {tombstone.name}
                    </>
                ),
            },
            newTombstone: newTombstone &&
                (newTombstone.area.name !== tombstone.area.name ||
                    newTombstone.name !== tombstone.name) && {
                    content: (
                        <>
                            {newTombstone.area.name}
                            <img
                                src={ArrowRightImage}
                                alt="arrow-right"
                                style={{ margin: '0 8px' }}
                            />
                            {newTombstone.name}
                        </>
                    ),
                },
            useFee: tombstone &&
                tombstone.use && {
                    value: `이용료 ${tombstone.use.term}년`,
                    extra: numberAddComma(tombstone.use.price) + '원',
                },
            newUseFee: (tombstone.use.term !== newTombstone.use.term ||
                tombstone.use.price !== newTombstone.use.price) && {
                content: `이용료 ${newTombstone.use.term}년`,
                increasePrice: newTombstone.use.price - tombstone.use.price,
            },
            managementFee: tombstone &&
                tombstone.management && {
                    value: `관리비 ${tombstone.management.term}년`,
                    extra: numberAddComma(tombstone.management.price) + '원',
                },
            newManagementFee: (tombstone.management.term !==
                newTombstone.management.term ||
                tombstone.management.price !==
                    newTombstone.management.price) && {
                content: `관리비 ${newTombstone.management.term}년`,
                increasePrice:
                    newTombstone.management.price - tombstone.management.price,
            },
            crematorium: crematorium && {
                value: crematorium.name,
                extra: crematorium.address,
            },
            crematoriumReservation: reservation && {
                value: reservation.dateString,
                extra: numberAddComma(reservation.price) + '원',
            },
            distance: {
                value: '56.2km (58분)',
                extra: (
                    <div style={{ fontSize: '12px' }}>
                        <div>출발 : {funeralItem.name}</div>
                        <div>경우 : {cemetery.name}</div>
                        <div>도착 : {crematorium.name}</div>
                    </div>
                ),
            },
            hearseOperation: {
                value: hearseOperation.name,
                extra: hearseOperation.phone,
            },
            vehicle:
                vehicle &&
                vehicle.map(({ name, units, price }) => ({
                    value: (
                        <>
                            {name} {units[0].num}
                            {units[0].name}
                        </>
                    ),
                    extra: numberAddComma(price * units[0].num) + '원',
                })),
            newVehicle:
                newVehicle &&
                newVehicle.map((ele, idx) => {
                    const { units: prevUnits } = vehicle[idx];
                    const { name, units, price } = ele;
                    return (
                        units[0].num !== prevUnits[0].num && {
                            content: (
                                <>
                                    {name} {units[0].num}
                                    {units[0].name}
                                </>
                            ),
                            increasePrice:
                                (units[0].num - prevUnits[0].num) * price,
                        }
                    );
                }),
            leaveForACemeteryDefaultAmmount,
            leaveForACemeteryAdditionalAmmount,
        };
    }, [funeralManagement, formState]);

    // input display값
    const [inputValues, setInputValues] = useState(initInputValues());

    useEffect(() => {
        setInputValues(initInputValues());
    }, [initInputValues]);

    return (
        <>
            <Modal
                header={{ content: '장례관리', left: { content: '취소' } }}
                body={{
                    content: (
                        <>
                            <Space height={16} />
                            <form
                                onSubmit={onSubmit}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <FuneralItemInputLayout label="고인명">
                                        <Input
                                            {...inputValues.deceased}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="상주">
                                        <Input
                                            {...inputValues.reside}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="연락처">
                                        <Input
                                            {...inputValues.contact}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="운구요청">
                                        <Input
                                            {...inputValues.isRequireCarrying}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="고인위치">
                                        <Input
                                            {...inputValues.location}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="예약자">
                                        <Input
                                            {...inputValues.subscriber}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="장례기간">
                                        <Input
                                            {...inputValues.date}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="장례식장">
                                        <InputSelect
                                            {...inputValues.funeral}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="빈소">
                                        <>
                                            <InputSelect
                                                {...inputValues.mortuary}
                                                {...commonInputProps}
                                                modal={{
                                                    type: SelectElementTypes
                                                        .funeral.MORTUARY,
                                                }}
                                            />
                                            {inputValues.newMortuary && (
                                                <ChangeItemView
                                                    {...inputValues.newMortuary}
                                                />
                                            )}
                                        </>
                                        <>
                                            <InputSelect
                                                {...inputValues.decoration}
                                                {...commonInputProps}
                                                modal={{
                                                    type: SelectElementTypes
                                                        .funeral.DECORATION,
                                                }}
                                            />
                                            {inputValues.newDecoration && (
                                                <ChangeItemView
                                                    {...inputValues.newDecoration}
                                                />
                                            )}
                                        </>
                                        <>
                                            <InputSelect
                                                {...inputValues.portraitPhoto}
                                                {...commonInputProps}
                                                modal={{
                                                    type: SelectElementTypes
                                                        .funeral.PORTRAIT_PHOTO,
                                                }}
                                            />
                                            {inputValues.newPortraitPhoto && (
                                                <ChangeItemView
                                                    {...inputValues.newPortraitPhoto}
                                                />
                                            )}
                                        </>
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="상복">
                                        {inputValues.mourning.map(
                                            (ele, idx) => (
                                                <React.Fragment key={idx}>
                                                    <InputSelect
                                                        {...ele}
                                                        {...commonInputProps}
                                                        modal={{
                                                            type: SelectElementTypes
                                                                .funeral
                                                                .MOURNING,
                                                        }}
                                                    />
                                                    {inputValues.newMourning[
                                                        idx
                                                    ] && (
                                                        <ChangeItemView
                                                            {...inputValues
                                                                .newMourning[
                                                                idx
                                                            ]}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )
                                        )}
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="조문객">
                                        <>
                                            <InputSelect
                                                {...inputValues.expectedMourners}
                                                {...commonInputProps}
                                                modal={{
                                                    type: SelectElementTypes
                                                        .funeral
                                                        .EXPECTED_MOURNERS,
                                                }}
                                            />
                                            {inputValues.newExpectedMourners && (
                                                <ChangeItemView
                                                    {...inputValues.newExpectedMourners}
                                                />
                                            )}
                                        </>
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="운구">
                                        <>
                                            <InputSelect
                                                {...inputValues.carrying}
                                                {...commonInputProps}
                                                modal={{
                                                    type: SelectElementTypes
                                                        .funeral.CARRYING,
                                                }}
                                            />
                                            {inputValues.newCarrying && (
                                                <ChangeItemView
                                                    {...inputValues.newCarrying}
                                                />
                                            )}
                                        </>
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="안치">
                                        <InputSelect
                                            {...inputValues.laySbaInState}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="입관">
                                        <>
                                            <InputSelect
                                                {...inputValues.shroud}
                                                {...commonInputProps}
                                                modal={{
                                                    type: SelectElementTypes
                                                        .funeral.SHROUD,
                                                }}
                                            />
                                            {inputValues.newShroud && (
                                                <ChangeItemView
                                                    {...inputValues.newShroud}
                                                />
                                            )}
                                        </>
                                        <>
                                            <InputSelect
                                                {...inputValues.coffin}
                                                {...commonInputProps}
                                                modal={{
                                                    type: SelectElementTypes
                                                        .funeral.COFFIN,
                                                }}
                                            />
                                            {inputValues.newCoffin && (
                                                <ChangeItemView
                                                    {...inputValues.newCoffin}
                                                />
                                            )}
                                        </>
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="수시">
                                        <InputSelect
                                            {...inputValues.gatherUpABody}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="염습">
                                        <InputSelect
                                            {...inputValues.dressingACorpseForBurial}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="청소">
                                        <InputSelect
                                            {...inputValues.cleaning}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="인력파견">
                                        {inputValues.manpower.map(
                                            (ele, idx) => (
                                                <React.Fragment key={idx}>
                                                    <InputSelect
                                                        {...ele}
                                                        {...commonInputProps}
                                                        modal={{
                                                            type: SelectElementTypes
                                                                .funeral
                                                                .MANPOWER,
                                                        }}
                                                    />
                                                    {inputValues.newManpower[
                                                        idx
                                                    ] && (
                                                        <ChangeItemView
                                                            {...inputValues
                                                                .newManpower[
                                                                idx
                                                            ]}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )
                                        )}
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="기타 비용">
                                        <InputSelect
                                            {...inputValues.otherCosts}
                                            modal={{
                                                type: SelectElementTypes.funeral
                                                    .OTHER_COSTS,
                                            }}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>

                                    <Flex.Wrapper
                                        column
                                        alignItems={'stretch'}
                                        style={{
                                            color: '#666',
                                            padding: '15px 0 33px',
                                            borderTop: '2px double #BBB',
                                        }}
                                    >
                                        <Flex.Wrapper justifyContent="space-between">
                                            <div>기본금액</div>
                                            <div
                                                style={{
                                                    color: '#333',
                                                    fontWeight: '500',
                                                    fontFamily: 'Roboto',
                                                }}
                                            >
                                                {numberAddComma(
                                                    inputValues.funeralDefaultAmmount
                                                )}{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '400',
                                                        fontFamily:
                                                            'Noto Sans KR',
                                                    }}
                                                >
                                                    원
                                                </span>
                                            </div>
                                        </Flex.Wrapper>
                                        <Flex.Wrapper justifyContent="space-between">
                                            <div>추가금액</div>
                                            <div
                                                style={{
                                                    color:
                                                        inputValues.funeralAdditionalAmmount >
                                                        0
                                                            ? '#FF5160'
                                                            : inputValues.funeralAdditionalAmmount <
                                                                  0 &&
                                                              '#2F80ED',
                                                    fontWeight: '500',
                                                    fontFamily: 'Roboto',
                                                }}
                                            >
                                                {inputValues.funeralAdditionalAmmount >
                                                    0 && '+'}{' '}
                                                {numberAddComma(
                                                    inputValues.funeralAdditionalAmmount
                                                )}{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '400',
                                                        fontFamily:
                                                            'Noto Sans KR',
                                                    }}
                                                >
                                                    원
                                                </span>
                                            </div>
                                        </Flex.Wrapper>
                                        <div
                                            style={{
                                                margin: '16px 0 19px',
                                                borderTop: '1px solid #BBB',
                                            }}
                                        />
                                        <Flex.Wrapper
                                            justifyContent="space-between"
                                            tyle={{
                                                fontWeight: 700,
                                                color: '#333',
                                            }}
                                            style={{
                                                color: '#333',
                                                fontWeight: '700',
                                            }}
                                        >
                                            <div style={{ fontSize: '16px' }}>
                                                합계금액
                                            </div>
                                            <div style={{ fontSize: '20px' }}>
                                                {numberAddComma(
                                                    inputValues.funeralDefaultAmmount +
                                                        inputValues.funeralAdditionalAmmount
                                                )}{' '}
                                                원
                                            </div>
                                        </Flex.Wrapper>
                                    </Flex.Wrapper>
                                </div>
                                <div
                                    style={{
                                        background: '#F9F9F9',
                                        padding: '0 0 8px',
                                        margin: '0 -16px',
                                    }}
                                />
                                <div>
                                    <FuneralItemInputLayout label="장지">
                                        <InputSelect
                                            {...inputValues.cemetery}
                                            {...commonInputProps}
                                        />
                                        <Flex.Wrapper
                                            style={{ margin: '-4px 0' }}
                                        >
                                            <Flex.Item flex={1}>
                                                <div
                                                    style={{
                                                        padding: '4px 0',
                                                    }}
                                                >
                                                    <InputSelect
                                                        titleBold
                                                        {...inputValues.tombstone}
                                                        {...commonInputProps}
                                                    />
                                                    {inputValues.newTombstone && (
                                                        <ChangeItemView
                                                            {...inputValues.newTombstone}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        padding: '4px 0',
                                                        borderTop:
                                                            '1px solid #eeeeee',
                                                    }}
                                                >
                                                    <InputSelect
                                                        {...inputValues.useFee}
                                                        {...commonInputProps}
                                                    />
                                                    {inputValues.newUseFee && (
                                                        <ChangeItemView
                                                            {...inputValues.newUseFee}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        padding: '4px 0',
                                                        borderTop:
                                                            '1px solid #eeeeee',
                                                    }}
                                                >
                                                    <InputSelect
                                                        {...inputValues.managementFee}
                                                        {...commonInputProps}
                                                    />
                                                    {inputValues.newManagementFee && (
                                                        <ChangeItemView
                                                            {...inputValues.newManagementFee}
                                                        />
                                                    )}
                                                </div>
                                            </Flex.Item>
                                            <button
                                                type="button"
                                                style={{ color: '#C3A981' }}
                                                onClick={() => {
                                                    dispatch(
                                                        openModal({
                                                            type: SelectElementTypes
                                                                .leaveForACemetery
                                                                .TOMBSTONE,
                                                        })
                                                    );
                                                }}
                                            >
                                                변경
                                            </button>
                                        </Flex.Wrapper>
                                    </FuneralItemInputLayout>

                                    <FuneralItemInputLayout label="화장장">
                                        <InputSelect
                                            {...inputValues.crematorium}
                                            {...commonInputProps}
                                        />
                                        <InputSelect
                                            {...inputValues.crematoriumReservation}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="차량">
                                        <InputSelect
                                            {...inputValues.distance}
                                            {...commonInputProps}
                                        />
                                        <InputSelect
                                            {...inputValues.hearseOperation}
                                            modal={{
                                                type: SelectElementTypes
                                                    .reserveLeaveForCemetery
                                                    .HEARSE_OPERATION,
                                            }}
                                            {...commonInputProps}
                                        />
                                        {inputValues.vehicle.map((ele, idx) => (
                                            <React.Fragment key={idx}>
                                                <InputSelect
                                                    {...ele}
                                                    {...commonInputProps}
                                                    modal={{
                                                        type: SelectElementTypes
                                                            .leaveForACemetery
                                                            .VEHICLE,
                                                    }}
                                                />
                                                {inputValues.newVehicle[
                                                    idx
                                                ] && (
                                                    <ChangeItemView
                                                        {...inputValues
                                                            .newVehicle[idx]}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </FuneralItemInputLayout>
                                    <Flex.Wrapper
                                        column
                                        alignItems={'stretch'}
                                        style={{
                                            color: '#666',
                                            padding: '15px 0 33px',
                                            borderTop: '2px double #BBB',
                                        }}
                                    >
                                        <Flex.Wrapper justifyContent="space-between">
                                            <div>기본금액</div>
                                            <div
                                                style={{
                                                    color: '#333',
                                                    fontWeight: '500',
                                                    fontFamily: 'Roboto',
                                                }}
                                            >
                                                {numberAddComma(
                                                    inputValues.leaveForACemeteryDefaultAmmount
                                                )}{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '400',
                                                        fontFamily:
                                                            'Noto Sans KR',
                                                    }}
                                                >
                                                    원
                                                </span>
                                            </div>
                                        </Flex.Wrapper>
                                        <Flex.Wrapper justifyContent="space-between">
                                            <div>추가금액</div>
                                            <div
                                                style={{
                                                    color:
                                                        inputValues.leaveForACemeteryAdditionalAmmount >
                                                        0
                                                            ? '#FF5160'
                                                            : inputValues.leaveForACemeteryAdditionalAmmount <
                                                                  0 &&
                                                              '#2F80ED',
                                                    fontWeight: '500',
                                                    fontFamily: 'Roboto',
                                                }}
                                            >
                                                {inputValues.leaveForACemeteryAdditionalAmmount >
                                                    0 && '+'}{' '}
                                                {numberAddComma(
                                                    inputValues.leaveForACemeteryAdditionalAmmount
                                                )}{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '400',
                                                        fontFamily:
                                                            'Noto Sans KR',
                                                    }}
                                                >
                                                    원
                                                </span>
                                            </div>
                                        </Flex.Wrapper>
                                        <div
                                            style={{
                                                margin: '16px 0 19px',
                                                borderTop: '1px solid #BBB',
                                            }}
                                        />
                                        <Flex.Wrapper
                                            justifyContent="space-between"
                                            tyle={{
                                                fontWeight: 700,
                                                color: '#333',
                                            }}
                                            style={{
                                                color: '#333',
                                                fontWeight: '700',
                                            }}
                                        >
                                            <div style={{ fontSize: '16px' }}>
                                                합계금액
                                            </div>
                                            <div style={{ fontSize: '20px' }}>
                                                {numberAddComma(
                                                    inputValues.leaveForACemeteryDefaultAmmount +
                                                        inputValues.leaveForACemeteryAdditionalAmmount
                                                )}{' '}
                                                원
                                            </div>
                                        </Flex.Wrapper>
                                    </Flex.Wrapper>
                                </div>
                                <Flex.Wrapper
                                    style={{
                                        position: 'sticky',
                                        bottom: '0',
                                        margin: '0 -16px',
                                        width: 'calc(100% + 32px)',
                                    }}
                                >
                                    <Button
                                        theme="reverse"
                                        style={{
                                            boxShadow:
                                                'inset 0px 1px 0px 0px #EEEEEE',
                                        }}
                                        // onClick={() => {
                                        //   dispatch(
                                        //     openModal({
                                        //       type: DialogTypes.ASK_CANCEL_RESERVE_LEAVE_FOR_A_CEMETERY,
                                        //       // data: reserveInfo.idx,
                                        //     })
                                        //   );
                                        // }}
                                    >
                                        예약 실패
                                    </Button>
                                    <Button
                                    // onClick={() => {
                                    //   dispatch(
                                    //     openModal({
                                    //       type: DialogTypes.ASK_REQUEST_RESERVE_LEAVE_FOR_A_CEMETERY,
                                    //       // data: reserveInfo,
                                    //     })
                                    //   );
                                    // }}
                                    >
                                        예약됨
                                    </Button>
                                </Flex.Wrapper>
                            </form>
                        </>
                    ),
                }}
            />
        </>
    );
}

export default FuneralManagementView;
