import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FuneralItemInputLayout from '../../Components/Form/FuneralItemInputLayout';
import Modal from '../../Components/Modal';
import Input from './../../Components/Form/Input';
import Space from '../../Style/Space';
import Button from '../../Style/Button';
import InputSelect from './../../Components/Form/InputSelect';
import { SelectElementTypes } from '../../common/constants';
import { getTermDateString, numberAddComma } from './../../common/util';
import Flex from '../../Style/Flex';
import axios from 'axios';
import DefaultModalLayout from '../../Layouts/ModalLayouts/DefaultModalLayout';
import styled from 'styled-components';

const commonInputProps = {
    underline: 'none',
    disabled: true,
    as: 'div',
    style: { minHeight: '44px' },
};

const Details = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

function ChangeItemView({ content, increasePrice }) {
    return (
        <Flex.Wrapper
            justifyContent="space-between"
            style={{
                background: '#F3F0EA',
                padding: '10px',
                fontSize: '15px',
                marginBottom: '11px',
            }}
        >
            <div style={{ color: '#333', fontWeight: '500' }}>{content}</div>
            {increasePrice && (
                <div
                    style={{ color: increasePrice > 0 ? '#FF5160' : '#2F80ED' }}
                >
                    {increasePrice > 0 ? '+' : ''}{' '}
                    {numberAddComma(increasePrice)} 원
                </div>
            )}
        </Flex.Wrapper>
    );
}

function FuneralManagementViewTemp(data) {
    const dispatch = useDispatch();
    const [fixedEstimate, setFixedEstimate] = useState(undefined);

    console.info(data.rangeCd);
    const onSubmit = () => {};
    const inputValues = {
        deceased: '고인',
    };

    useEffect(() => {
        axios
            .get(`/estimate/funeral/fixed/${data.estimateReqId}`)
            .then(({ data }) => {
                setFixedEstimate(data.data);
                console.info(data.data);
            });
    }, []);

    return (
        <>
            <DefaultModalLayout
                header={{
                    content: '확정 견적 보기',
                    left: { content: '취소' },
                }}
            >
                <>
                    <Space height={16} />
                    <div>
                        <FuneralItemInputLayout label="고인명">
                            <Input
                                value={data.deceased}
                                {...commonInputProps}
                            />
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="예약자">
                            <Input
                                value={data.requesterName}
                                {...commonInputProps}
                            />
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="연락처">
                            <Input
                                value={data.requesterPhone}
                                {...commonInputProps}
                            />
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="장례기간">
                            <Input
                                value={data.rangeString}
                                {...commonInputProps}
                            />
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="장례식장">
                            {fixedEstimate && (
                                <Input
                                    value={fixedEstimate.funeralHall.name}
                                    {...commonInputProps}
                                />
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="빈소">
                            {fixedEstimate && (
                                <>
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate.room
                                                    .name
                                            }
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                data.rangeCd === '00'
                                                    ? fixedEstimate
                                                          .fixedEstimate.room
                                                          .price * 2
                                                    : fixedEstimate
                                                          .fixedEstimate.room
                                                          .price
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                    <Space height={5} />
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .shrineFlower.name
                                            }
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .shrineFlower.price
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                    <Space height={5} />
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .portrait.name
                                            }
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .portrait.price
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                </>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="상복">
                            {fixedEstimate && (
                                <>
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .manClothes.name
                                            }{' '}
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .manClothes.cnt
                                            }
                                            벌
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .manClothes.price *
                                                    fixedEstimate.fixedEstimate
                                                        .manClothes.cnt
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                    <Space height={5} />
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .womanClothes.name
                                            }{' '}
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .womanClothes.cnt
                                            }
                                            벌
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .womanClothes.price *
                                                    fixedEstimate.fixedEstimate
                                                        .womanClothes.cnt
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                </>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="접객음식">
                            {fixedEstimate && (
                                <Details>
                                    <div className={'name'}>
                                        {fixedEstimate.fixedEstimate.food.name}
                                    </div>
                                    <div className={'price'}>
                                        {numberAddComma(
                                            data.visitorCd === '00'
                                                ? fixedEstimate.fixedEstimate
                                                      .food.price * 49
                                                : data.visitorCd === '01'
                                                ? fixedEstimate.fixedEstimate
                                                      .food.price * 99
                                                : fixedEstimate.fixedEstimate
                                                      .food.price * 100
                                        )}{' '}
                                        원
                                    </div>
                                </Details>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="운구">
                            {fixedEstimate && (
                                <Details>
                                    <div className={'name'}>
                                        {fixedEstimate.fixedEstimate.move.name}
                                    </div>
                                    <div className={'price'}>
                                        {numberAddComma(
                                            fixedEstimate.fixedEstimate.move
                                                .price
                                        )}{' '}
                                        원
                                    </div>
                                </Details>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="안치">
                            {fixedEstimate && (
                                <Details>
                                    <div className={'name'}>
                                        {
                                            fixedEstimate.fixedEstimate
                                                .sepulchre.name
                                        }
                                    </div>
                                    <div className={'price'}>
                                        {numberAddComma(
                                            fixedEstimate.fixedEstimate
                                                .sepulchre.price
                                        )}{' '}
                                        원
                                    </div>
                                </Details>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="수시비">
                            {fixedEstimate && (
                                <Details>
                                    <div className={'name'}>
                                        {fixedEstimate.fixedEstimate.wash.name}
                                    </div>
                                    <div className={'price'}>
                                        {numberAddComma(
                                            fixedEstimate.fixedEstimate.wash
                                                .price
                                        )}{' '}
                                        원
                                    </div>
                                </Details>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="입관">
                            {fixedEstimate && (
                                <>
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .shroudClothes.name
                                            }
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .shroudClothes.price
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                    <Space height={5} />
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .coffin.name
                                            }
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .coffin.price
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                </>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="청소">
                            {fixedEstimate && (
                                <>
                                    <Details>
                                        <div className={'name'}>
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .clean.name
                                            }
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .clean.price
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                </>
                            )}
                        </FuneralItemInputLayout>

                        <FuneralItemInputLayout label="인력파견">
                            {fixedEstimate && (
                                <>
                                    <Details>
                                        <div className={'name'}>
                                            장례지도사{' '}
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .manpowerDirector.cnt
                                            }
                                            인{' '}
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .manpowerDirector.days
                                            }
                                            일
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .manpowerDirector.price *
                                                    fixedEstimate.fixedEstimate
                                                        .manpowerDirector.cnt *
                                                    fixedEstimate.fixedEstimate
                                                        .manpowerDirector.days
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                    <Space height={5} />
                                    <Details>
                                        <div className={'name'}>
                                            접객도우미{' '}
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .manpowerHelper.cnt
                                            }
                                            인{' '}
                                            {
                                                fixedEstimate.fixedEstimate
                                                    .manpowerHelper.hours
                                            }
                                            시간
                                        </div>
                                        <div className={'price'}>
                                            {numberAddComma(
                                                fixedEstimate.fixedEstimate
                                                    .manpowerHelper.price *
                                                    fixedEstimate.fixedEstimate
                                                        .manpowerHelper.cnt *
                                                    fixedEstimate.fixedEstimate
                                                        .manpowerHelper.hours
                                            )}{' '}
                                            원
                                        </div>
                                    </Details>
                                </>
                            )}
                        </FuneralItemInputLayout>
                        <FuneralItemInputLayout label="기타 비용">
                            {fixedEstimate &&
                                fixedEstimate.fixedEstimate.etc.map((etc) => (
                                    <>
                                        <Details>
                                            <div className={'name'}>
                                                {etc.name}
                                            </div>
                                            <div className={'price'}>
                                                {numberAddComma(etc.price)} 원
                                            </div>
                                        </Details>
                                        <Space height={5} />
                                    </>
                                ))}
                        </FuneralItemInputLayout>

                        <Flex.Wrapper
                            column
                            alignItems={'stretch'}
                            style={{
                                color: '#666',
                                padding: '15px 0 33px',
                                borderTop: '2px double #BBB',
                            }}
                        >
                            <Flex.Wrapper
                                justifyContent="space-between"
                                tyle={{
                                    fontWeight: 700,
                                    color: '#333',
                                }}
                                style={{
                                    color: '#333',
                                    fontWeight: '700',
                                }}
                            >
                                <div style={{ fontSize: '16px' }}>합계금액</div>
                                <div style={{ fontSize: '20px' }}>
                                    {numberAddComma(data.totalPrice)} 원
                                </div>
                            </Flex.Wrapper>
                        </Flex.Wrapper>
                    </div>
                </>
            </DefaultModalLayout>
        </>
    );
}

export default FuneralManagementViewTemp;
