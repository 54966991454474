import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormTypes,
    setFormSelectItem,
    setFormState,
} from '../../../redux/form';
import { closeModal } from '../../../redux/modal';
import Flex from '../../../Style/Flex';
import SelectNumber from '../../../Components/SelectNumber';
import Button from '../../../Style/Button';
import DefaultModalLayout from './../../../Layouts/ModalLayouts/DefaultModalLayout';
import { FuneralItemTypes } from './../../../redux/funeralItem';

function SelectClothes() {
    const dispatch = useDispatch();
    // 남성, 여성 상복 option
    const { manClothes, womanClothes } = useSelector(
        (state) => state.funeralItem[FuneralItemTypes.OPTIONS]
    );
    // 남성, 여성 상복 개수
    const {
        manClothes: { cnt: initialManClothesCnt = 0 },
        womanClothes: { cnt: initialWomanClothesCnt = 0 },
    } = useSelector((state) => state.form[FormTypes.FUNERAL_ESTIMATE]);

    const [manClothesCount, setManClothesCount] =
        useState(initialManClothesCnt);
    const [womanClothesCount, setWomanClothesCount] = useState(
        initialWomanClothesCnt
    );

    return (
        <DefaultModalLayout header={{ content: '상복' }}>
            <Flex.Wrapper
                column
                justifyContent="space-between"
                alignItems="stretch"
                style={{ height: '100%' }}
            >
                <div>
                    <SelectNumber
                        name="남성 상복"
                        price={manClothes.price}
                        onChange={(name, num) => {
                            setManClothesCount(num);
                        }}
                        units={[{ name: '', num: manClothesCount }]}
                    />
                    <SelectNumber
                        name="여성 상복"
                        price={womanClothes.price}
                        onChange={(name, num) => {
                            setWomanClothesCount(num);
                        }}
                        units={[{ name: '', num: womanClothesCount }]}
                    />
                </div>
                <Button
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        margin: '0 -16px',
                        width: 'calc(100% + 32px)',
                    }}
                    onClick={() => {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manClothes',
                                data: {
                                    id: manClothes.optionId,
                                    cnt: manClothesCount,
                                },
                            })
                        );
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'womanClothes',
                                data: {
                                    id: womanClothes.optionId,
                                    cnt: womanClothesCount,
                                },
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manClothes',
                                data: {
                                    id: manClothes.optionId,
                                    cnt: manClothesCount,
                                    price: manClothes.price,
                                },
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'womanClothes',
                                data: {
                                    id: womanClothes.optionId,
                                    cnt: womanClothesCount,
                                    price: womanClothes.price,
                                },
                            })
                        );
                        dispatch(closeModal());
                    }}
                >
                    적용
                </Button>
            </Flex.Wrapper>
        </DefaultModalLayout>
    );
}

export default SelectClothes;
