import React from 'react';
import Flex from './../../Style/Flex';

import LogoImage from './../../resources/image/Logo.svg';
import Space from './../../Style/Space';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/user';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
    const { me } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Flex.Wrapper
            style={{
                width: '100%',
                padding: '8px 16px',
                position: 'sticky',
                top: '0',
                background: '#fff',
            }}
            justifyContent="initial"
            as={'header'}
        >
            <Flex.Item flex={1}>
                <Flex.Wrapper justifyContent="initial">
                    <Link to="/" style={{ display: 'flex' }}>
                        <img
                            src={LogoImage}
                            style={{ width: '32px' }}
                            alt="logo"
                        />
                    </Link>
                    <Space width={12} />
                    <div>
                        {me && me.partnerType} <strong>{me && me.name}</strong>
                    </div>
                </Flex.Wrapper>
            </Flex.Item>
            <button
                style={{ color: '#666', fontSize: '14px' }}
                onClick={() => {
                    dispatch(logout());
                    navigate('/login');
                }}
            >
                로그아웃
            </button>
        </Flex.Wrapper>
    );
}

export default Header;
