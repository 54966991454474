import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Input from './../../../Components/Form/Input';
import styled from 'styled-components';
import FormMessage from '../../../Components/Form/FormMessage';
import useModal from './../../../hooks/useModal';
import { FormTypes, setFormState } from './../../../redux/form';
import { getErrorMessage } from '../../../common/util';

const FormWrapper = styled.div`
    background-color: #fff;
    padding: 0 0 15px 0;
    min-width: 230px;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;

    .info {
        font-weight: 700;
        font-size: 16px;
        background: #f6f4f1;
        text-align: left;
        width: 100%;
        padding: 20px;
        border-radius: 6px 6px 0 0;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
        gap: 10px;

        .button-wrapper {
            display: flex;
            align-self: flex-end;
            margin-top: 26px;

            button {
                padding: 0;
                flex-basis: 100px;
                background: transparent;
                color: rgb(195, 169, 129);
                padding: 10px 30px;

                &[type='submit'] {
                    background: rgb(195, 169, 129);
                    color: #fff;
                }
            }
        }
    }
`;

function AddFuneralEtc() {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        watch,
    } = useForm();
    const modalMethods = useModal();

    const { estimateEtc } = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );

    const onSubmit = (data) => {
        dispatch(
            setFormState({
                type: FormTypes.FUNERAL_ESTIMATE,
                prop: 'estimateEtc',
                data: [...estimateEtc, data],
            })
        );
        modalMethods.closeModal();
    };
    const { price } = watch();

    return (
        <FormWrapper>
            <div className="info">기타항목 추가</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    placeholder={'명칭'}
                    {...register('name', {
                        required: '명칭을 입력해주세요.',
                    })}
                />
                <Input
                    type="text"
                    placeholder={'금액'}
                    {...register('price', {
                        required: '금액을 입력해주세요.',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: '금액을 숫자형태로 입력해주세요.',
                        },
                        valueAsNumber: true,
                    })}
                />
                <FormMessage
                    isError={errors.name || errors.price}
                    error={getErrorMessage(errors)}
                />
                <div className="button-wrapper">
                    <button onClick={modalMethods.closeModal} type="button">
                        취소
                    </button>
                    <button type="submit">추가</button>
                </div>
            </form>
        </FormWrapper>
    );
}

export default AddFuneralEtc;
