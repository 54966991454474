import React from 'react';
import { useDispatch } from 'react-redux';
import ActionSheetLayout from '../../Layouts/ModalLayouts/ActionSheetLayout';
import { clearFormState, setFormState } from '../../redux/form';
import { closeModal, openModal } from '../../redux/modal';
import { removeEstimate } from '../../redux/user';
import { modalTypes } from '../../common/constants';
import { FormTypes } from './../../redux/form';

function RequestCoffinEstimate({ estimate }) {
    const dispatch = useDispatch();
    console.log(estimate);

    return (
        <ActionSheetLayout
            header={{ content: '발인견적 요청', props: {} }}
            actions={[
                {
                    content: '견적작성',
                    props: {
                        style: { color: '#0A84FF' },
                        onClick: () => {
                            // formState 초기화
                            dispatch(clearFormState());
                            // 현재 열려져 있는 ActionSheet 닫기
                            dispatch(closeModal());

                            // const { idx, date, funeral, vehicle } = data;

                            // // 견적 요청에 대한 정보 set
                            // dispatch(
                            //     setFormState({
                            //         type: FormTypes.COFFIN_ESTIMATE,
                            //         data: {
                            //             idx,
                            //             date,
                            //             funeral,
                            //             vehicle,
                            //             estimate: data,
                            //         },
                            //     })
                            // );

                            // // 견적 요청 modal open
                            // dispatch(
                            //     openModal({
                            //         type: modalTypes.default.COFFIN_ESTIMATE,
                            //     })
                            // );
                        },
                    },
                },
                {
                    content: '삭제',
                    props: {
                        style: { color: '#FF453A' },
                        onClick: () => {
                            // // 견적 삭제
                            // dispatch(
                            //     removeEstimate({
                            //         type: 'coffin',
                            //         idx: data.idx,
                            //     })
                            // );

                            // 현재 열려져 있는 ActionSheet 닫기
                            dispatch(closeModal());
                        },
                    },
                },
            ]}
        />
    );
}

export default RequestCoffinEstimate;
