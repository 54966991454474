import React, { useState } from 'react';
import styled from 'styled-components';
import Flex from './../Style/Flex';
import { numberAddComma } from './../common/util';

import PlusImage from './../resources/image/ic_plus.svg';
import MinusImage from './../resources/image/ic_minus.svg';

const Button = styled.button`
    border: 1.5px solid #dddddd;
    border-radius: 50%;
    width: 33.75px;
    height: 33.75px;
    font-size: 20px;
`;

function SelectNumber({ name, price, units, onChange }) {
    // 단위 배열
    const unitsArr = Object.keys(units).map((key) => units[key]);

    // 값과 단위를 곱하여 구한 값
    const sum =
        unitsArr.length >= 2
            ? unitsArr.reduce((prev, current) => prev.num * current.num) * price
            : unitsArr[0].num * price;

    return (
        <Flex.Wrapper
            alignItems="stretch"
            style={{
                padding: '20px 0 15px',
                borderBottom: '1px solid #EEEEEE',
            }}
        >
            <Flex.Item flex="1">
                <strong style={{ fontSize: '15px' }}>{name}</strong>
                <div style={{ fontSize: '12px', color: '#888' }}>
                    {numberAddComma(price)}원
                </div>
            </Flex.Item>
            <Flex.Item flex="1">
                <Flex.Wrapper column alignItems="flex-end">
                    {unitsArr.map((unit, idx) => {
                        const { name, num } = unit;

                        return (
                            <Flex.Wrapper
                                key={idx}
                                style={{
                                    marginBottom: '17.25px',
                                    minWidth: '160px',
                                }}
                                justifyContent="space-between"
                            >
                                <Flex.Wrapper
                                    as={Button}
                                    disabled={num <= 0}
                                    onClick={() => {
                                        onChange(name, num - 1);
                                    }}
                                >
                                    <img src={MinusImage} alt="minus" />
                                </Flex.Wrapper>
                                <div>
                                    {num}
                                    {name}
                                </div>
                                <Flex.Wrapper
                                    as={Button}
                                    onClick={() => {
                                        onChange(name, num + 1);
                                    }}
                                >
                                    <img src={PlusImage} alt="plus" />
                                </Flex.Wrapper>
                            </Flex.Wrapper>
                        );
                    })}
                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        {numberAddComma(sum)}원
                    </div>
                </Flex.Wrapper>
            </Flex.Item>
        </Flex.Wrapper>
    );
}

export default SelectNumber;
