import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { modalTypes } from './../../common/constants';
import useModal from '../../hooks/useModal';

Modal.setAppElement('#root');

function ModalComponent({ type, shapeType, isOpen, children, isFirst }) {
    const [modalProps, setModalProps] = useState();

    const modalMethods = useModal();

    useEffect(() => {
        const commonOverlayClassName = isFirst ? 'front' : '';
        // DEFAULT
        if (shapeType === modalTypes.shape.DEFAULT) {
            return setModalProps({
                overlayClassName: `slide-down-overlay ${commonOverlayClassName}`,
                className: 'slide-down',
                style: {
                    content: {
                        inset: 'unset',
                        border: 'unset',
                        borderRadius: '0',
                        width: '100%',
                        minHeight: '100%',
                        transition: 'all 0.3s',
                        position: 'relative',
                        outline: 'none',
                        left: '50%',
                    },
                },
                onAfterOpen: (props) => {
                    const { overlayEl } = props;
                    overlayEl.style.background = '#f9f9f9';
                },
            });
        } else if (shapeType === modalTypes.shape.DIALOG) {
            return setModalProps({
                overlayClassName: `dialog ${commonOverlayClassName}`,
                style: {
                    overlay: {
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100vw',
                        minHeight: '100vh',
                        maxWidth: 'unset',
                        background: 'rgba(0,0,0, .7)',
                    },
                    content: {
                        border: 'unset',
                        borderRadius: '0',
                        outline: 'none',
                        padding: '0',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        right: 'auto',
                        bottom: 'auto',
                    },
                },
            });
        } else if (shapeType === modalTypes.shape.ACTION_SHEET) {
            return setModalProps({
                overlayClassName: `dialog ${commonOverlayClassName}`,
                style: {
                    overlay: {
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100vw',
                        minHeight: '100vh',
                        maxWidth: 'unset',
                        background: 'rgba(0,0,0, .6)',
                    },
                    content: {
                        width: '100%',
                        border: 'unset',
                        borderRadius: '0',
                        outline: 'none',
                        padding: '0 8px',
                        position: 'absolute',
                        left: '50%',
                        right: 'auto',
                        top: 'auto',
                        bottom: '32px',
                    },
                },
            });
        }
    }, [shapeType, isFirst]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                closeTimeoutMS={305}
                onRequestClose={modalMethods.closeModal}
                onAfterClose={() => {
                    window.document.body.className = '';
                }}
                shouldCloseOnOverlayClick={false}
                {...modalProps}
            >
                {children}
            </Modal>
        </>
    );
}

export default ModalComponent;
