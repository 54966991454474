import React from 'react';
import styled from 'styled-components';
import Space from '../../Style/Space';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/modal';

const ActionButton = styled.button`
    font-weight: 600;
    font-size: 20px;
    padding: 18px 16px;
    width: 100%;
    border-top: 0.5px solid rgba(84, 84, 88, 0.65);
`;

function ActionSheetLayout({ header, actions }) {
    const dispatch = useDispatch();

    return (
        <>
            <div
                style={{
                    textAlign: 'center',
                    borderRadius: '13px',
                    background: 'rgb(28, 28, 30)',
                    // background: 'rgba(24, 24, 24, 0.7)',
                }}
            >
                <div
                    {...header.props}
                    style={{
                        ...header.props.style,
                        padding: '12px 16px',
                        color: 'rgba(235,235,245,0.6)',
                    }}
                >
                    {header.content}
                </div>
                {actions.map((action, idx) => (
                    <ActionButton {...action.props} key={idx}>
                        {action.content}
                    </ActionButton>
                ))}
            </div>
            <Space height={8} />
            <ActionButton
                onClick={() => dispatch(closeModal())}
                style={{
                    background: '#1c1c1e',
                    borderRadius: '13px',
                    color: '#0A84FF',
                }}
            >
                닫기
            </ActionButton>
        </>
    );
}

export default ActionSheetLayout;
