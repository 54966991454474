// 빈 오브젝트인지 check
import { modalTypes } from './constants';
export function isEmptyObj(obj) {
    if (obj.constructor === Object && Object.keys(obj).length === 0) {
        return true;
    }
    return false;
}
// react-hook-form errorMessage get
export function getErrorMessage(error) {
    if (Object.keys(error).length === 0) {
        return null;
    }
    // message가 담겨진 error객체 형태인경우
    if (
        Object.prototype.hasOwnProperty.call(error, 'type') &&
        Object.prototype.hasOwnProperty.call(error, 'ref') &&
        Object.prototype.hasOwnProperty.call(error, 'message')
    ) {
        return error.message;
    }
    // 여러개의 error객체가 담겨져 있는경우 1번째 key에 접근
    return getErrorMessage(error[[...Object.keys(error)][0]]);
}

// date의 시간을 출력
export function getTime(date) {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        date = new Date(date);
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();

    return `${hours > 9 ? hours : '0' + hours}:${
        minutes > 9 ? minutes : '0' + minutes
    }`;
}

// date를 string 형식으로 출력
export function getDateString(date, type = 'default') {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        date = new Date(date);
    }

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (type === 'default') {
        return `${year}년 ${month > 9 ? month : '0' + month}월 ${
            day > 9 ? day : '0' + day
        }일`;
    } else if (type === 'number') {
        return `${year}-${month > 9 ? month : '0' + month}-${
            day > 9 ? day : '0' + day
        }`;
    } else if (type === 'point') {
        return `${year}.${month > 9 ? month : '0' + month}.${
            day > 9 ? day : '0' + day
        }`;
    }
}

// 기간 date를 string으로 출력
export function getTermDateString(start, end, type = 'default') {
    if (Object.prototype.toString.call(start) !== '[object Date]') {
        start = new Date(start);
    }
    if (Object.prototype.toString.call(end) !== '[object Date]') {
        end = new Date(end);
    }

    // 날짜차
    const diffDays = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
    // start, end의 년 월이 같다면 end date 년, 월 스킵
    if (
        start.getFullYear() + start.getMonth() ===
        end.getFullYear() + end.getMonth()
    ) {
        return `${getDateString(start, type)} ~ ${
            end.getDate() > 9 ? end.getDate() : '0' + end.getDate()
        }일`;
    }
    return `${getDateString(start, type)} ~ ${getDateString(end, type)}`;
}

// 3자리 숫자마다 comma를 찍음
export function numberAddComma(num) {
    if (!num) {
        return 0;
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// 값이 비어있는지 체크
export function checkEmptyValue(value) {
    // 배열인 경우
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    // 객체인 경우
    if (typeof value === 'object') {
        return Object.keys(value).length === 0 && value.constructor === Object;
    }
    return !value;
}

// 값이 있는지 체크
export function checkNotEmptyValue(value) {
    return !checkEmptyValue(value);
}

export function addPhoneHyphen(phone) {
    phone = phone.replaceAll('-', '');

    return `${phone.substring(0, 3)}-${phone.substring(
        3,
        phone.length - 4
    )}-${phone.substring(phone.length - 4, phone.length)}`;
}

export function getModalType(type) {
    // Default
    if (Object.keys(modalTypes.default).find((currType) => currType === type)) {
        return modalTypes.shape.DEFAULT;
    } else if (
        Object.keys(modalTypes.dialog).find((currType) => currType === type)
    ) {
        return modalTypes.shape.DIALOG;
    } else if (
        Object.keys(modalTypes.action).find((currType) => currType === type)
    ) {
        return modalTypes.shape.ACTION_SHEET;
    }
}
export default isEmptyObj;
