import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormTypes,
    setFormSelectItem,
    setFormState,
} from '../../../redux/form';
import { closeModal } from '../../../redux/modal';
import Flex from '../../../Style/Flex';
import SelectNumber from '../../../Components/SelectNumber';
import Button from '../../../Style/Button';
import DefaultModalLayout from './../../../Layouts/ModalLayouts/DefaultModalLayout';
import { FuneralItemTypes } from './../../../redux/funeralItem';

function SelectManpower() {
    const dispatch = useDispatch();
    // 장례지도사 option, 접객 도우미 option
    const { manpowerDirector, manpowerSubDirector, manpowerHelper } =
        useSelector((state) => state.funeralItem[FuneralItemTypes.OPTIONS]);

    // 장례지도사, 접객도우미
    const {
        manpowerDirector: initialManpowerDirector,
        manpowerSubDirector: initialManpowerSubDirector,
        manpowerHelper: initialManpowerHelper,
    } = useSelector((state) => state.form[FormTypes.FUNERAL_ESTIMATE]);

    const [manpowerDirectorCount, setManpowerDirectorCount] = useState(
        initialManpowerDirector
    );
    const [manpowerSubDirectorCount, setManpowerSubDirectorCount] = useState(
        initialManpowerSubDirector
    );
    const [manpowerHelperCount, setManpowerHelperCount] = useState(
        initialManpowerHelper
    );
    return (
        <DefaultModalLayout header={{ content: '인력파견' }}>
            <Flex.Wrapper
                column
                justifyContent="space-between"
                alignItems="stretch"
                style={{ height: '100%' }}
            >
                <div>
                    <SelectNumber
                        name="장례지도사"
                        price={manpowerDirector.price}
                        onChange={(name, num) => {
                            setManpowerDirectorCount((prev) => ({
                                ...prev,
                                [name === '명'
                                    ? 'cnt'
                                    : name === '일' && 'days']: num,
                            }));
                        }}
                        units={[
                            { name: '명', num: manpowerDirectorCount.cnt },
                            { name: '일', num: manpowerDirectorCount.days },
                        ]}
                    />
                    <SelectNumber
                        name={
                            <div>
                                입관보조
                                <br />
                                장례지도사
                            </div>
                        }
                        price={manpowerSubDirector.price}
                        onChange={(name, num) => {
                            setManpowerSubDirectorCount((prev) => ({
                                ...prev,
                                [name === '명'
                                    ? 'cnt'
                                    : name === '일' && 'days']: num,
                            }));
                        }}
                        units={[
                            { name: '명', num: manpowerSubDirectorCount.cnt },
                            { name: '일', num: manpowerSubDirectorCount.days },
                        ]}
                    />

                    <SelectNumber
                        name="접객도우미"
                        price={manpowerHelper.price}
                        onChange={(name, num) => {
                            setManpowerHelperCount((prev) => ({
                                ...prev,
                                [name === '명'
                                    ? 'cnt'
                                    : name === '시간' && 'hours']: num,
                            }));
                        }}
                        units={[
                            { name: '명', num: manpowerHelperCount.cnt },
                            { name: '시간', num: manpowerHelperCount.hours },
                        ]}
                    />
                </div>
                <Button
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        margin: '0 -16px',
                        width: 'calc(100% + 32px)',
                    }}
                    onClick={() => {
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerDirector',
                                data: {
                                    price: manpowerDirector.price,
                                    cnt: manpowerDirectorCount.cnt,
                                    days: manpowerDirectorCount.days,
                                },
                            })
                        );
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerSubDirector',
                                data: {
                                    price: manpowerSubDirector.price,
                                    cnt: manpowerSubDirectorCount.cnt,
                                    days: manpowerSubDirectorCount.days,
                                },
                            })
                        );
                        dispatch(
                            setFormState({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerHelper',
                                data: {
                                    price: manpowerHelper.price,
                                    cnt: manpowerHelperCount.cnt,
                                    hours: manpowerHelperCount.hours,
                                },
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerDirector',
                                data: {
                                    price:
                                        manpowerDirector.price *
                                        manpowerDirectorCount.days *
                                        manpowerDirectorCount.cnt,
                                    cnt: manpowerDirectorCount.cnt,
                                    days: manpowerDirectorCount.days,
                                },
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerSubDirector',
                                data: {
                                    price:
                                        manpowerSubDirector.price *
                                        manpowerSubDirectorCount.days *
                                        manpowerSubDirectorCount.cnt,
                                    cnt: manpowerSubDirectorCount.cnt,
                                    days: manpowerSubDirectorCount.days,
                                },
                            })
                        );
                        dispatch(
                            setFormSelectItem({
                                type: FormTypes.FUNERAL_ESTIMATE,
                                prop: 'manpowerHelper',
                                data: {
                                    price:
                                        manpowerHelper.price *
                                        manpowerHelperCount.hours *
                                        manpowerHelperCount.cnt,
                                    cnt: manpowerHelperCount.cnt,
                                    hours: manpowerHelperCount.hours,
                                },
                            })
                        );
                        dispatch(closeModal());
                    }}
                >
                    적용
                </Button>
            </Flex.Wrapper>
        </DefaultModalLayout>
    );
}

export default SelectManpower;
