import styled from 'styled-components';

const Message = styled.div`
    width: ${({ width = '100%' }) =>
        String(width).indexOf('%') === -1 ? width + 'px' : width};
    font-size: 12px;
    min-height: 14px;
    color: ${({ success = false }) => (success ? '#52a952' : '#ff5160')};
`;

export default Message;
