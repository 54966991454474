import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Element from '../../../Components/SelectElement/Element';
import { setFormSelectItem, setFormState } from '../../../redux/form';
import { closeModal } from '../../../redux/modal';
import DefaultModalLayout from '../../../Layouts/ModalLayouts/DefaultModalLayout';
import { FuneralItemTypes } from './../../../redux/funeralItem';
import { FormTypes } from './../../../redux/form';
import { numberAddComma } from '../../../common/util';

const key = 'shrineFlower';
function SelectFlower() {
    const dispatch = useDispatch();
    const flowers = useSelector(
        (state) => state.funeralItem[FuneralItemTypes.OPTIONS].flower
    );
    const { shrineFlowerId } = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );

    return (
        <DefaultModalLayout header={{ content: '제단 꽃 장식 선택' }}>
            {flowers.map((ele, idx) => {
                const { optionId, name, price, extra } = ele;

                return (
                    <Element
                        key={optionId}
                        selected={optionId === shrineFlowerId}
                        name={name}
                        extra={
                            <>
                                {numberAddComma(price)}원 {extra}
                            </>
                        }
                        onClick={() => {
                            dispatch(
                                setFormState({
                                    type: FormTypes.FUNERAL_ESTIMATE,
                                    prop: key + 'Id',
                                    data: optionId,
                                })
                            );
                            dispatch(
                                setFormSelectItem({
                                    type: FormTypes.FUNERAL_ESTIMATE,
                                    prop: key,
                                    data: ele,
                                })
                            );
                            dispatch(closeModal());
                        }}
                    />
                );
            })}
        </DefaultModalLayout>
    );
}

export default SelectFlower;
