import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet-async';

const GlobalStyle = createGlobalStyle`
*{
    box-sizing:border-box;
}

html{
  line-height: initial;
}

body{
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: #191919;
    background-color: #f9f9f9;

    max-width: 750px;
    margin: 0 auto;
}

#root{
  background-color: #ffffff;
}

html,body, #root, .App,.App > div{
  min-height: 100vh;
}
button{
  border:none;
  background:none;
  cursor:pointer;
}

a{
  color: inherit;
  text-decoration: none;
}

ul,ol{
  list-style: none;
  padding: 0;
  margin: 0;
}


.ReactModal__Body--open{
    overflow:hidden
}

.ReactModal__Overlay:not(.front){
    overflow:hidden !important;
}

.ReactModal__Overlay{
    position: fixed;
    inset: 0px;
    background:transparent;
}

.ReactModal__Overlay--after-open{
    overflow:auto;
}

.ReactModal__Content{
    max-width:750px;
    transform:translate(-50%,0);
}
.ReactModal__Overlay .ReactModal__Content{
    background-color: white;
}


${'' /* 슬라이드 */}
.ReactModal__Overlay.slide-down-overlay{
    transition: background 0s ease 0.3s;
}
.ReactModal__Overlay--before-close.slide-down-overlay{
    transition: background 0s;
    background-color:transparent !important;
}

.ReactModal__Overlay .ReactModal__Content.slide-down  {
    bottom: -100% !important;
    overflow: hidden;
    height: 0;
    min-height: 0 !important;
}

.ReactModal__Overlay--after-open .ReactModal__Content.slide-down {
    bottom:0 !important;
    overflow:auto ;
    height:100%;
    min-height:100% !important;
}

.ReactModal__Overlay--before-close .ReactModal__Content.slide-down {
    bottom:-100% !important;
}

${'' /* 다이얼로그 */}
.ReactModal__Overlay.dialog   {
    transition: opacity 0.3s;
    opacity:0 !important;
}

.ReactModal__Overlay--after-open.dialog {
    opacity:1 !important;
}

.ReactModal__Overlay--before-close.dialog {
    opacity:0 !important;
}

.ReactModal__Overlay.dialog .ReactModal__Content{
    background-color: transparent !important;
}

button, .select-none{
    user-select:none ;
}

`;

function GlobalStyleComponent() {
    return (
        <>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <GlobalStyle />
        </>
    );
}

export default GlobalStyleComponent;
