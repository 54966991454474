import React from 'react';
import styled from 'styled-components';
import useModal from '../../hooks/useModal';
import Flex from '../../Style/Flex';

import BackImage from './../../resources/image/ic_back.svg';

const Header = styled.div`
    position: sticky;
    top: 0;
    height: 44px;
    min-height: 44px;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    padding: 0 16px;
    border-bottom: 0.5px solid #e5e5e5;

    font-size: 17px;
    font-weight: 600;
    color: #000000;
`;

function DefaultModalLayout({ header, children }) {
    const modalState = useModal();

    return (
        <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            {header && (
                <Header>
                    <Flex.Item flex={1} style={{ marginLeft: '8px' }}>
                        <Flex.Wrapper
                            inlineFlex
                            justifyContent={'stretch'}
                            style={{ cursor: 'pointer', fontWeight: '400' }}
                            onClick={modalState.closeModal}
                        >
                            <img
                                src={BackImage}
                                style={{ height: '16px' }}
                                alt="back"
                            />
                            {header.left && header.left.content && (
                                <span style={{ marginLeft: '13px' }}>
                                    {header.left.content}
                                </span>
                            )}
                        </Flex.Wrapper>
                    </Flex.Item>
                    {header.content}
                    <Flex.Item flex={1} style={{ textAlign: 'end' }}>
                        {header.right &&
                            header.right.content &&
                            header.right.content}
                    </Flex.Item>
                </Header>
            )}
            <div style={{ padding: '0 16px', flex: 1 }}>{children}</div>
        </div>
    );
}

export default DefaultModalLayout;
