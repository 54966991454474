import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SendBird from 'sendbird';

export const SendBirdContext = React.createContext(null);
function SendBirdProvider({ children }) {
    const sb = useRef(null);
    const { id, sendbirdID, sendbirdToken } =
        useSelector((state) => state.user.me) || {};

    useEffect(() => {
        // 최초에만 SendBird 초기화
        if (id && sb.current === null) {
            sb.current = new SendBird({
                appId: process.env.REACT_APP_SENDBIRD_APP_ID,
                localCacheEnabled: false,
            });

            sb.current.connect(sendbirdID, sendbirdToken, (user, error) => {
                if (error) {
                    return console.error(error);
                }
            });
        }
        if (!id && sb.current) {
            sb.current.disconnect();
            sb.current = null;
        }
    }, [id]);

    return (
        <SendBirdContext.Provider value={sb.current}>
            {children}
        </SendBirdContext.Provider>
    );
}
SendBirdContext.displayName = 'SendBirdContext';

export default SendBirdProvider;
