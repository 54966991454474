import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FuneralItemInputLayout from '../../Components/Form/FuneralItemInputLayout';
import Modal from '../../Components/Modal';
import Space from '../../Style/Space';
import Button from '../../Style/Button';
import InputSelect from '../../Components/Form/InputSelect';
import { useSelector } from 'react-redux';
import { numberAddComma } from '../../common/util';
import Flex from '../../Style/Flex';
import { openModal } from '../../redux/modal';

const commonInputProps = {
    underline: 'none',
    disabled: true,
    as: 'div',
    style: { minHeight: '44px' },
};

function FuneralReserve() {
    const reserveState = useSelector((state) => {
        const idx = state.modal[0] && state.modal[0].data;
        if (idx !== undefined) {
            return state.user.me.reservation.funeral.find(
                (ele) => ele.idx === idx
            );
        }
        return {};
    });
    const [reserveInfo, setReserveInfo] = useState(reserveState);

    const dispatch = useDispatch();

    const { displayValues = {}, estimate = {} } = reserveInfo;

    const onSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Modal
                header={{ content: '장례예약', left: { content: '취소' } }}
                body={{
                    content: (
                        <>
                            <Space height={16} />
                            <form
                                onSubmit={onSubmit}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <FuneralItemInputLayout label="고인명">
                                        <InputSelect
                                            value={
                                                estimate.deceased &&
                                                '故 ' + estimate.deceased.name
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="상주명">
                                        <InputSelect
                                            value={
                                                estimate.reside &&
                                                estimate.reside.name
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="연락처">
                                        <InputSelect
                                            value={
                                                estimate.reside &&
                                                estimate.reside.contact &&
                                                estimate.reside.contact.phone
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="운구요청">
                                        <InputSelect
                                            value={
                                                estimate.carrying ? '필요' : ''
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="고인위치">
                                        <InputSelect
                                            value={estimate.location}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="예약자">
                                        <InputSelect
                                            value={
                                                estimate.user &&
                                                estimate.user.name
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="장례기간">
                                        <InputSelect
                                            value={
                                                displayValues.date &&
                                                displayValues.date.title
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="장례식장">
                                        <InputSelect
                                            value={
                                                displayValues.funeral &&
                                                displayValues.funeral.title
                                            }
                                            extra={
                                                displayValues.funeral &&
                                                displayValues.funeral.content
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="빈소">
                                        <InputSelect
                                            value={
                                                displayValues.mortuary &&
                                                displayValues.mortuary.title
                                            }
                                            extra={
                                                displayValues.mortuary &&
                                                displayValues.mortuary.content
                                            }
                                            {...commonInputProps}
                                        />
                                        <InputSelect
                                            value={
                                                displayValues.decoration &&
                                                displayValues.decoration.title
                                            }
                                            extra={
                                                displayValues.decoration &&
                                                displayValues.decoration.content
                                            }
                                            {...commonInputProps}
                                        />
                                        <InputSelect
                                            value={
                                                displayValues.portraitPhoto &&
                                                displayValues.portraitPhoto
                                                    .title
                                            }
                                            extra={
                                                displayValues.portraitPhoto &&
                                                displayValues.portraitPhoto
                                                    .content
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="상복">
                                        {displayValues.mourning &&
                                            displayValues.mourning.map(
                                                ({ title, content }, idx) => (
                                                    <InputSelect
                                                        key={idx}
                                                        value={title}
                                                        extra={content}
                                                        {...commonInputProps}
                                                    />
                                                )
                                            )}
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="예상조문객">
                                        <InputSelect
                                            value={
                                                displayValues.expectedMourners &&
                                                displayValues.expectedMourners
                                                    .title
                                            }
                                            extra={
                                                displayValues.expectedMourners &&
                                                displayValues.expectedMourners
                                                    .content
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>

                                    <div
                                        style={{
                                            margin: '0 -16px',
                                            width: 'calc(100% + 32px)',
                                            height: '8px',
                                            background: '#F9F9F9',
                                        }}
                                    />
                                    <Flex.Wrapper
                                        justifyContent="space-between"
                                        style={{ margin: '18px 0 58px' }}
                                    >
                                        <strong>견적 금액</strong>
                                        <div style={{ color: '#ff5160' }}>
                                            <strong>
                                                {displayValues.sum &&
                                                    numberAddComma(
                                                        displayValues.sum
                                                    )}
                                            </strong>{' '}
                                            원
                                        </div>
                                    </Flex.Wrapper>
                                </div>
                                <Flex.Wrapper
                                    style={{
                                        position: 'sticky',
                                        bottom: '0',
                                        margin: '0 -16px',
                                        width: 'calc(100% + 32px)',
                                    }}
                                >
                                    <Button
                                        theme="reverse"
                                        style={{
                                            boxShadow:
                                                'inset 0px 1px 0px 0px #EEEEEE',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    // type: DialogTypes.ASK_CANCEL_RESERVE_FUNERAL,
                                                    data: reserveInfo.idx,
                                                })
                                            );
                                        }}
                                    >
                                        예약 불발
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    // type: DialogTypes.ASK_REQUEST_RESERVE_FUNERAL,
                                                    data: reserveInfo,
                                                })
                                            );
                                        }}
                                    >
                                        예약됨
                                    </Button>
                                </Flex.Wrapper>
                            </form>
                        </>
                    ),
                }}
            />
        </>
    );
}

export default FuneralReserve;
