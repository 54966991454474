const API_BASE_URL_DEV = 'http://skydev.catbellcompany.com:9000';
//const API_BASE_URL_PROD = 'https://api.good-land.co.kr';
const API_BASE_URL_PROD = 'https://api.goodland.catbellcompany.com';

export const API_BASE_URL =
    process.env.NODE_ENV === 'development'
        ? API_BASE_URL_DEV
        : API_BASE_URL_PROD;

// 요소 선택 Modal 종류
export const SelectElementTypes = {
    funeral: {
        DECORATION: 'DECORATION',
        PORTRAIT_PHOTO: 'PORTRAIT_PHOTO',
        MOURNING: 'MOURNING',
        EXPECTED_MOURNERS: 'EXPECTED_MOURNERS',
        CARRYING: 'CARRYING',
        SHROUD: 'SHROUD',
        COFFIN: 'COFFIN',
        MANPOWER: 'MANPOWER',
        OTHER_COSTS: 'OTHER_COSTS',
    },
    coffin: {
        CEMETERY: 'CEMETERY',
        TOMBSTONE: 'TOMBSTONE',
        AREA: 'AREA',
        CREMATORIUM: 'CREMATORIUM',
        RESERVATION: 'RESERVATION',
        VEHICLE: 'VEHICLE',
    },
    reserveLeaveForCemetery: {
        HEARSE_OPERATION: 'HEARSE_OPERATION',
    },
};

export const modalTypes = {
    default: {
        REQUEST_PASSWORD: 'REQUEST_PASSWORD',
        FUNERAL_ESTIMATE: 'FUNERAL_ESTIMATE',
        COFFIN_ESTIMATE: 'COFFIN_ESTIMATE',
        FUNERAL_RESERVE: 'FUNERAL_RESERVE',
        COFFIN_RESERVE: 'COFFIN_RESERVE',
        FUNERAL_MANAGEMENT_VIEW: 'FUNERAL_MANAGEMENT_VIEW',
        FUNERAL_MANAGEMENT_VIEW_TEMP: 'FUNERAL_MANAGEMENT_VIEW_TEMP', // 시연 임시
        SELECT_FUNERAL_FUNERAL_HALL: 'SELECT_FUNERAL_FUNERAL_HALL',
        SELECT_FUNERAL_ROOM: 'SELECT_FUNERAL_ROOM',
        SELECT_FUNERAL_FLOWER: 'SELECT_FUNERAL_FLOWER',
        SELECT_FUNERAL_PORTRAIT_PHOTO: 'SELECT_FUNERAL_PORTRAIT_PHOTO',
        SELECT_FUNERAL_CLOTHES: 'SELECT_FUNERAL_CLOTHES',
        SELECT_FUNERAL_MOVE: 'SELECT_FUNERAL_MOVE',
        SELECT_FUNERAL_SHROUD_CLOTHES: 'SELECT_FUNERAL_SHROUD_CLOTHES',
        SELECT_FUNERAL_COFFIN: 'SELECT_FUNERAL_COFFIN',
        SELECT_FUNERAL_MANPOWER: 'SELECT_FUNERAL_MANPOWER',
        CHAT_ROOM: 'CHAT_ROOM',
    },
    action: {
        REQUEST_FUNERAL_ESTIMATE: 'REQUEST_FUNERAL_ESTIMATE',
        REQUEST_COFFIN_ESTIMATE: 'REQUEST_COFFIN_ESTIMATE',
        REQUEST_FUNERAL_RESERVE: 'REQUEST_FUNERAL_RESERVE ',
        REQUEST_COFFIN_RESERVE: 'REQUEST_COFFIN_RESERVE',
    },
    dialog: {
        ASK_REQUEST_FUNERAL_ESTIMATE: 'ASK_REQUEST_FUNERAL_ESTIMATE',
        ASK_REQUEST_COFFIN_ESTIMATE: 'ASK_REQUEST_COFFIN_ESTIMATE',
        ASK_REQUEST_FUNERAL_RESERVE: 'ASK_REQUEST_FUNERAL_RESERVE',
        ASK_CANCEL_FUNERAL_RESERVE: 'ASK_CANCEL_FUNERAL_RESERVE',
        ASK_REQUEST_COFFIN_RESERVE: 'ASK_REQUEST_COFFIN_RESERVE',
        ASK_CANCEL_COFFIN_RESERVE: 'ASK_CANCEL_COFFIN_RESERVE',
        ADD_FUNERAL_ETC: 'ADD_FUNERAL_ETC',
    },
    shape: {
        DEFAULT: 'DEFAULT',
        SELECT: 'SELECT',
        DIALOG: 'DIALOG',
        ACTION_SHEET: 'ACTION_SHEET',
        SIDE_MENU: 'SIDE_MENU',
    },
};
