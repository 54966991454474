import { createSlice } from '@reduxjs/toolkit';

// form 종류
export const FormTypes = {
    FUNERAL_ESTIMATE: 'FUNERAL_ESTIMATE',
    COFFIN_ESTIMATE: 'COFFIN_ESTIMATE',
    COFFIN_RESERVE: 'COFFIN_RESERVE',
    MANAGE_FUNERAL: 'MANAGE_FUNERAL',
};

const initialState = {
    [FormTypes.FUNERAL_ESTIMATE]: {
        manClothes: {
            id: null,
            cnt: 0,
        },
        womanClothes: {
            id: null,
            cnt: 0,
        },
        manpowerDirector: { price: 0, cnt: 0, days: 0 },
        manpowerSubDirector: { price: 0, cnt: 0, days: 0 },
        manpowerHelper: { price: 0, cnt: 0, hours: 0 },
        estimateEtc: [],
        items: {},
        saveAndSend: true,
    },
    [FormTypes.COFFIN_ESTIMATE]: {
        idx: 0,
        funeral: null,
        date: null,
        cemetery: { item: null, tombstone: null },
        crematorium: { item: null, reservation: null },
        vehicle: [
            {
                name: '리무진 5인승',
                units: [{ name: '대', num: 0 }],
            },
            {
                name: '버스 45인승',
                units: [{ name: '대', num: 0 }],
            },
        ],
        totalPrice: 0,
    },
    [FormTypes.COFFIN_RESERVE]: {
        hearseOperation: null,
    },
    [FormTypes.MANAGE_FUNERAL]: {},
};

export const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setFormState: (state, action) => {
            const { type, prop = null, data } = action.payload;
            if (prop) {
                state[type][prop] = data;
            } else {
                state[type] = { ...state[type], ...data };
            }
        },
        setFormSelectItem: (state, action) => {
            const { type, prop, data } = action.payload;
            state[type].items[prop] = data;
        },

        clearFormState: (state, action) => {
            const { type } = action.payload || {};
            // form type이 명시되어 있지 않다면 모든 데이터 초기화
            if (!type) {
                return initialState;
            }
            state[type] = { ...initialState[type] };
        },
    },
});

export const { setFormState, setFormSelectItem, clearFormState } =
    formSlice.actions;

export default formSlice.reducer;
