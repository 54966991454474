import React from 'react';
import Flex from '../../Style/Flex';
import styled from 'styled-components';

const InputLayout = styled(Flex.Wrapper)`
    border-bottom: 1px solid #eee;
    font-size: 15px;

    .label {
        flex-basis: 110px;
        color: #666;
    }

    .input-item {
        /* width: 100%; */
        padding: 4px 0;
    }
`;

function FuneralItemInputLayout({ label, children }) {
    const isMultipleInput = Array.isArray(children);

    return (
        <InputLayout justifyContent="stretch" alignItems="baseline">
            <div className="label">{label}</div>
            <Flex.Item flex={1}>
                <Flex.Wrapper column alignItems="stretch">
                    {isMultipleInput ? (
                        children.map((ele, idx) =>
                            ele ? (
                                <div className="input-item" key={idx}>
                                    {ele}
                                </div>
                            ) : null
                        )
                    ) : (
                        <div className="input-item">{children}</div>
                    )}
                </Flex.Wrapper>
            </Flex.Item>
        </InputLayout>
    );
}

export default FuneralItemInputLayout;
