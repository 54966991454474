import React from 'react';
import Message from './../../Style/Message';

function FormMessage({ isSuccess, success, isError, error }) {
    return (
        <>
            {/* error > success순으로 중요도 */}
            <Message success={isSuccess && !isError}>
                {isError && error}
                {isSuccess && !isError && success}
            </Message>
        </>
    );
}

export default FormMessage;
