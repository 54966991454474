import React, { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../Layouts/DefaultLayout';
import styled from 'styled-components';

import timeAgo from '../../common/timeAgo';
import useModal from '../../hooks/useModal';
import { modalTypes } from '../../common/constants';
import useSendBird from './../../hooks/useSendBird';
import { useSelector } from 'react-redux';
import LogoImage from './../../resources/image/Logo.svg';

const NoChannelInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
    text-align: center;

    img {
        width: 100vw;
        max-width: 40px;
        margin: 0 20px;
    }

    p {
        font-size: 14px;
        font-weight: 700;
        color: #303742;
        margin: 0;
    }
`;

const ChatRoomList = styled.div`
    height: calc(100vh - 99.14px);
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

const ChatRoom = styled.div`
    font-weight: 400;
    font-size: 12px;
    padding: 10px 0;
    cursor: pointer;

    .body {
        display: flex;
        justify-content: space-between;

        .chat-body-right {
            flex-basis: 80px;
            text-align: end;
        }

        .chat-name {
            margin: 0;
            font-size: 15px;
            color: #191919;
        }
        .chat-message {
            margin: 4px 0 9px;
            color: #bbbbbb;
            line-height: 1.5em;
            min-height: 3em;
        }

        .chat-body-right {
            font-family: 'Roboto', sans-serif;
        }
        .chat-relative-date {
            color: #bbbbbb;
            margin-bottom: 8px;
        }
        .chat-unread-info {
            display: inline-flex;
            background: #ff5160;
            border-radius: 100px;
            min-width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;

            font-weight: 700;
            color: #ffffff;
            text-align: center;
        }
    }
    .chat-steps {
        display: flex;
        padding-top: 4.5px;
        border-top: 1px solid #bbbbbb;

        color: #333;

        .chat-step {
            &:not(:last-child) {
                color: #bbbbbb;

                &::after {
                    content: '-';
                    margin: 0 2px;
                }
            }
        }
    }
`;

function LiveDate({ date }) {
    // re-render를 위한 state
    const [toggle, setToggle] = useState(false);

    // 1초마다 toggle값을 변경해 re-render시켜
    // 1초마다 date값과 현재 시간의 차를 계산하여 render
    useEffect(() => {
        const timer = setTimeout(() => {
            setToggle((prev) => !prev);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [toggle]);

    const formattedDate = timeAgo.format(date);

    return <>{formattedDate.includes('초 전') ? '방금' : formattedDate}</>;
}

function Chat() {
    const modalMethod = useModal();
    const sb = useSendBird();
    const { sendbirdID } = useSelector((state) => state.user.me) || {};
    const channelListQuery = useRef(null);
    const [channels, setChannels] = useState([]);

    // 채널리스트 로드
    const loadChannels = () => {
        if (
            channelListQuery.current.hasNext &&
            !channelListQuery.current.isLoading
        ) {
            channelListQuery.current.next((groupChannels, error) => {
                if (error) {
                    return console.error(error);
                }

                setChannels((prev) => [...prev, ...groupChannels]);
            });
        }
    };

    // 채널리스트 쿼리 초기화
    useEffect(() => {
        if (sb && !channelListQuery.current) {
            const listQuery = sb.GroupChannel.createMyGroupChannelListQuery();
            listQuery.includeEmpty = true;
            listQuery.memberStateFilter = 'joined_only'; // 'all', 'joined_only', 'invited_only', 'invited_by_friend', and 'invited_by_non_friend'
            listQuery.order = 'latest_last_message'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
            listQuery.limit = 15;
            channelListQuery.current = listQuery;

            // 채널리스트 로드
            if (channelListQuery.current.hasNext) {
                loadChannels();
            }
        }
    }, [sb]);

    // 채널 핸들러 등록
    useEffect(() => {
        if (sb) {
            const HANDLER_ID = 'ADD_CHANNEL';
            const channelHandler = new sb.ChannelHandler();

            // 채널 상태가 변경될때 re-render
            channelHandler.onChannelChanged = (channel, message) => {
                // 변경된 채널
                const changedChannel = channels.find(
                    ({ url }) => url === channel.url
                );
                // 변경된 채널이 기존 channels에 없는 새로운 채널이며, 메시지가 있는경우에만 채널 추가
                if (!changedChannel && channel.lastMessage) {
                    return setChannels((prev) => [channel, ...prev]);
                }
                // re-render을 위한 setState
                return setChannels((prev) => [...prev]);
            };

            sb.addChannelHandler(HANDLER_ID, channelHandler);

            return () => {
                sb.removeChannelHandler(HANDLER_ID);
            };
        }
    }, [channels, sb]);

    // channel을 시간 내림차순으로 정렬
    const sortedByDateChannels = channels.sort(
        (aChannel, bChannel) =>
            // channel.lastMessage가 없다면 channel.createdAt으로 시간비교
            ((bChannel.lastMessage && bChannel.lastMessage.createdAt) ||
                bChannel.createdAt) -
            ((aChannel.lastMessage && aChannel.lastMessage.createdAt) ||
                aChannel.createdAt)
    );

    return (
        <DefaultLayout>
            <ChatRoomList
                onScroll={({ target }) => {
                    const { offsetHeight, scrollHeight, scrollTop } = target;

                    if (offsetHeight + scrollTop > scrollHeight - 300) {
                        loadChannels();
                    }
                }}
            >
                {sortedByDateChannels.length === 0 ? (
                    <NoChannelInfo>
                        <img src={LogoImage} alt="logo" />
                        <p>채팅목록이 없습니다.</p>
                    </NoChannelInfo>
                ) : (
                    sortedByDateChannels.map((channel, idx) => {
                        const { members, lastMessage, unreadMessageCount } =
                            channel;

                        // 채널 이름
                        const channelName = (() => {
                            // 멤버가 1명일때
                            if (members.length === 1) {
                                return members[0].nickname;
                            }
                            // 멤버가 여러명일때
                            return members
                                .filter(({ userId }) => userId !== sendbirdID)
                                .map(({ nickname }) => nickname)
                                .join(', ');
                        })();

                        return (
                            <ChatRoom
                                key={idx}
                                onClick={modalMethod.openModal({
                                    type: modalTypes.default.CHAT_ROOM,
                                    data: { channelUrl: channel.url },
                                })}
                            >
                                <div className="body">
                                    <div className="chat-body-left">
                                        <h3 className="chat-name">
                                            {channelName}
                                        </h3>
                                        {lastMessage && (
                                            <p className="chat-message">
                                                {lastMessage.messageType !==
                                                'file'
                                                    ? lastMessage.message
                                                    : '이미지를 보냈습니다.'}
                                            </p>
                                        )}
                                    </div>
                                    <div className="chat-body-right">
                                        {lastMessage && (
                                            <div className="chat-relative-date">
                                                <LiveDate
                                                    date={lastMessage.createdAt}
                                                />
                                            </div>
                                        )}
                                        {unreadMessageCount > 0 && (
                                            <div className="chat-unread-info">
                                                {unreadMessageCount < 100
                                                    ? unreadMessageCount
                                                    : '99+'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="chat-steps">
                                    <div className="chat-step">장례식</div>
                                    <div className="chat-step">장지</div>
                                    <div className="chat-step">
                                        화장장,차량{' '}
                                    </div>
                                </div>
                            </ChatRoom>
                        );
                    })
                )}
            </ChatRoomList>
        </DefaultLayout>
    );
}

export default Chat;
