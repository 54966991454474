import React from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import DialogLayout from '../../Layouts/ModalLayouts/DialogLayout';
import { closeModal } from '../../redux/modal';
import { FormTypes } from './../../redux/form';
import axios from 'axios';
import { FuneralItemTypes } from './../../redux/funeralItem';

function AskRequestFuneralEstimate() {
    const dispatch = useDispatch();
    const formData = useSelector(
        (state) => state.form[FormTypes.FUNERAL_ESTIMATE]
    );
    const { id: estimateReqId } = useSelector(
        (state) => state.funeralItem[FuneralItemTypes.FUNERAL_ESTIMATE_REQUEST]
    );

    const { mutate: sendEstimate } = useMutation({
        mutationFn: async (formData) => {
            //formData 필요없는 항목 제거
            const currFormData = { ...formData };
            delete currFormData.items;
            if (!currFormData.manClothes.id) {
                delete currFormData.manClothes;
            }
            if (!currFormData.womanClothes.id) {
                delete currFormData.womanClothes;
            }
            return await axios.put(
                `/estimate/suggest/funeral/${estimateReqId}`,
                currFormData
            ).data;
        },
    });

    return (
        <DialogLayout
            body={{ content: '견적을 발송하겠습니까?', props: {} }}
            actions={[
                {
                    content: '취소',
                    props: {
                        onClick: () => {
                            dispatch(closeModal());
                        },
                    },
                },
                {
                    content: '발송',
                    props: {
                        onClick: () => {
                            sendEstimate(formData);
                            // 현재 열려져 있는 Dialog 닫기
                            dispatch(closeModal());
                            dispatch(closeModal());
                        },
                    },
                },
            ]}
        />
    );
}

export default AskRequestFuneralEstimate;
