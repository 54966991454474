import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FuneralItemInputLayout from '../../Components/Form/FuneralItemInputLayout';
import Modal from '../../Components/Modal';
import Space from '../../Style/Space';
import Button from '../../Style/Button';
import InputSelect from '../../Components/Form/InputSelect';
import { useSelector } from 'react-redux';
import { getTime, getDateString, numberAddComma } from '../../common/util';
import Flex from '../../Style/Flex';
import { openModal } from '../../redux/modal';
import { SelectElementTypes } from '../../common/constants';

import ArrowRightImage from './../../resources/image/ic-arrow-right.svg';
import CheckImage from './../../resources/image/ic-check-circle.svg';
import CheckActiveImage from './../../resources/image/ic-check-circle-active.svg';
import { modalTypes } from './../../common/constants';
import { FormTypes } from './../../redux/form';

const commonInputProps = {
    underline: 'none',
    disabled: true,
    as: 'div',
    style: { minHeight: '44px' },
};

function ReservationState({ isReserved = false }) {
    return (
        <Flex.Wrapper column>
            <img
                src={isReserved ? CheckActiveImage : CheckImage}
                alt="reserve state"
            />
            <div
                style={{
                    fontSize: '13px',
                    color: isReserved ? '#C3A981' : '#BBBBBB',
                    margin: '5px 6px',
                }}
            >
                예약됨
            </div>
        </Flex.Wrapper>
    );
}

function CoffinReserve() {
    const dispatch = useDispatch();
    const reserveState = useSelector((state) => {
        const idx = state.modal[0] && state.modal[0].data;
        if (idx !== undefined) {
            return state.user.me.reservation.coffin.find(
                (ele) => ele.idx === idx
            );
        }
        return {};
    });
    const [reserveInfo, setReserveInfo] = useState(reserveState);
    const hearseOperation = useSelector(
        (state) =>
            state.form[FormTypes.RESERVE_LEAVE_FOR_A_CEMETERY].hearseOperation
    );

    const {
        date,
        funeral,
        cemetery,
        crematorium,
        displayValues = {},
        estimate = {},
    } = reserveInfo;

    const onSubmit = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setReserveInfo({ ...reserveInfo, hearseOperation });
    }, [hearseOperation]);

    return (
        <>
            <Modal
                header={{ content: '장례예약', left: { content: '취소' } }}
                body={{
                    content: (
                        <>
                            <Space height={16} />
                            <form
                                onSubmit={onSubmit}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <FuneralItemInputLayout label="고인명">
                                        <InputSelect
                                            value={
                                                estimate.deceased &&
                                                '故 ' + estimate.deceased.name
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>

                                    <FuneralItemInputLayout label="연락처">
                                        <InputSelect
                                            value={
                                                estimate.reside &&
                                                estimate.reside.contact &&
                                                estimate.reside.contact.phone
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="운구요청">
                                        <InputSelect
                                            value={
                                                estimate.carrying ? '필요' : ''
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="장례식장">
                                        <InputSelect
                                            value={
                                                displayValues.funeral &&
                                                displayValues.funeral.title
                                            }
                                            extra={
                                                displayValues.funeral &&
                                                displayValues.funeral.content
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="발인일">
                                        <InputSelect
                                            value={getDateString(date)}
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout
                                        label="장지"
                                        side={
                                            <ReservationState
                                                isReserved={
                                                    cemetery &&
                                                    cemetery.item &&
                                                    cemetery.tombstone
                                                }
                                            />
                                        }
                                    >
                                        <InputSelect
                                            value={
                                                displayValues.cemetery.item &&
                                                displayValues.cemetery.item
                                                    .title
                                            }
                                            extra={
                                                displayValues.cemetery.item &&
                                                displayValues.cemetery.item
                                                    .content
                                            }
                                            {...commonInputProps}
                                        />
                                        <InputSelect
                                            value={
                                                <>
                                                    {
                                                        cemetery.tombstone.area
                                                            .name
                                                    }
                                                    <img
                                                        src={ArrowRightImage}
                                                        alt="arrow-right"
                                                        style={{
                                                            margin: '0 8px',
                                                        }}
                                                    />
                                                    {cemetery.tombstone.name}
                                                </>
                                            }
                                            titleBold
                                            {...commonInputProps}
                                        />
                                        {displayValues.cemetery.fees &&
                                            Object.keys(
                                                displayValues.cemetery.fees
                                            ).map((key, idx) => {
                                                const { title, content } =
                                                    displayValues.cemetery.fees[
                                                        key
                                                    ];
                                                return (
                                                    <InputSelect
                                                        key={idx}
                                                        value={title}
                                                        extra={content}
                                                        {...commonInputProps}
                                                    />
                                                );
                                            })}
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout
                                        label="화장장"
                                        side={
                                            <ReservationState
                                                isReserved={
                                                    crematorium &&
                                                    crematorium.item &&
                                                    crematorium.reservation
                                                }
                                            />
                                        }
                                    >
                                        <InputSelect
                                            value={
                                                displayValues.crematorium &&
                                                displayValues.crematorium.title
                                            }
                                            extra={
                                                displayValues.crematorium &&
                                                displayValues.crematorium
                                                    .content
                                            }
                                            {...commonInputProps}
                                        />
                                        <InputSelect
                                            value={
                                                crematorium &&
                                                crematorium.reservation &&
                                                crematorium.reservation.time &&
                                                `${getTime(
                                                    crematorium.reservation.time
                                                        .start
                                                )} ~ ${getTime(
                                                    crematorium.reservation.time
                                                        .end
                                                )}`
                                            }
                                            extra={
                                                crematorium &&
                                                crematorium.reservation &&
                                                numberAddComma(
                                                    crematorium.reservation
                                                        .price
                                                ) + '원'
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout
                                        label="차량"
                                        side={
                                            <ReservationState
                                                isReserved={hearseOperation}
                                            />
                                        }
                                    >
                                        <InputSelect
                                            value={
                                                displayValues.distance &&
                                                displayValues.distance.title
                                            }
                                            extra={
                                                <div
                                                    style={{ fontSize: '12px' }}
                                                >
                                                    <div>
                                                        출발 : {funeral.name}
                                                    </div>
                                                    <div>
                                                        경우 :{' '}
                                                        {cemetery.item.name}
                                                    </div>
                                                    <div>
                                                        도착 :{' '}
                                                        {crematorium.item.name}
                                                    </div>
                                                </div>
                                            }
                                            {...commonInputProps}
                                        />

                                        <InputSelect
                                            value={
                                                <Flex.Wrapper
                                                    justifyContent="stretch"
                                                    alignItems="unset"
                                                    column
                                                >
                                                    <div
                                                        style={{
                                                            color: '#888',
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        업체리스트
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: '#333',
                                                            fontWeight: '700',
                                                        }}
                                                    >
                                                        {hearseOperation
                                                            ? hearseOperation.name
                                                            : '예약업체'}
                                                        <button
                                                            type="button"
                                                            style={{
                                                                color: '#C3A981',
                                                            }}
                                                            onClick={() => {
                                                                dispatch(
                                                                    openModal({
                                                                        type: SelectElementTypes
                                                                            .reserveLeaveForCemetery
                                                                            .HEARSE_OPERATION,
                                                                    })
                                                                );
                                                            }}
                                                        >
                                                            {hearseOperation
                                                                ? '수정'
                                                                : '선택'}
                                                        </button>
                                                    </div>
                                                    {hearseOperation && (
                                                        <div
                                                            style={{
                                                                color: '#2F80ED',
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            {
                                                                hearseOperation.phone
                                                            }
                                                        </div>
                                                    )}
                                                </Flex.Wrapper>
                                            }
                                            {...commonInputProps}
                                        />
                                        {displayValues.vehicle.map(
                                            (ele, idx) => {
                                                const { title, content } = ele;
                                                return (
                                                    ele && (
                                                        <InputSelect
                                                            key={idx}
                                                            value={title}
                                                            extra={content}
                                                            {...commonInputProps}
                                                        />
                                                    )
                                                );
                                            }
                                        )}
                                    </FuneralItemInputLayout>

                                    <div
                                        style={{
                                            margin: '0 -16px',
                                            width: 'calc(100% + 32px)',
                                            height: '8px',
                                            background: '#F9F9F9',
                                        }}
                                    />
                                    <Flex.Wrapper
                                        justifyContent="space-between"
                                        style={{ margin: '18px 0 58px' }}
                                    >
                                        <strong>견적 금액</strong>
                                        <div style={{ color: '#ff5160' }}>
                                            <strong>
                                                {displayValues.sum &&
                                                    numberAddComma(
                                                        displayValues.sum
                                                    )}
                                            </strong>{' '}
                                            원
                                        </div>
                                    </Flex.Wrapper>
                                </div>
                                <Flex.Wrapper
                                    style={{
                                        position: 'sticky',
                                        bottom: '0',
                                        margin: '0 -16px',
                                        width: 'calc(100% + 32px)',
                                    }}
                                >
                                    <Button
                                        theme="reverse"
                                        style={{
                                            boxShadow:
                                                'inset 0px 1px 0px 0px #EEEEEE',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    type: modalTypes.dialog
                                                        .ASK_CANCEL_RESERVE_LEAVE_FOR_A_CEMETERY,
                                                    data: reserveInfo.idx,
                                                })
                                            );
                                        }}
                                    >
                                        예약 실패
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    type: modalTypes.dialog
                                                        .ASK_REQUEST_RESERVE_LEAVE_FOR_A_CEMETERY,
                                                    data: reserveInfo,
                                                })
                                            );
                                        }}
                                        disabled={!hearseOperation}
                                    >
                                        예약 성공
                                    </Button>
                                </Flex.Wrapper>
                            </form>
                        </>
                    ),
                }}
            />
        </>
    );
}

export default CoffinReserve;
