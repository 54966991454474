import styled from 'styled-components';

const Flex = {
    Wrapper: styled.div`
        display: ${({ inlineFlex = false }) =>
            inlineFlex ? 'inline-flex' : 'flex'};
        flex-direction: ${({ column = false }) => (column ? 'column' : 'row')};
        justify-content: ${({ justifyContent = 'center' }) => justifyContent};
        align-items: ${({ alignItems = 'center' }) => alignItems};
        gap: ${({ gap = 'initial' }) => gap};
    `,
    Item: styled.div`
        flex: ${({ flex = 'initial' }) => flex};
    `,
};

export default Flex;
