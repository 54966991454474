import React from 'react';
import { modalTypes } from './../../../common/constants';
import RequestCoffinEstimate from '../../../Pages/Coffin/RequestCoffinEstimate';
import RequestCoffinReserve from '../../../Pages/Coffin/RequestCoffinReserve';
import RequestFuneralReserve from '../../../Pages/Funeral/RequestFuneralReserve';
import RequestFuneralEstimate from './../../../Pages/Funeral/RequestFuneralEstimate';
import AskRequestFuneralEstimate from './../../../Pages/Funeral/AskRequestFuneralEstimate';
import AskRequestCoffinEstimate from './../../../Pages/Coffin/AskRequestCoffinEstimate';
import AskRequestFuneralReserve from './../../../Pages/Funeral/AskRequestFuneralReserve';
import AskRequestCoffinReserve from './../../../Pages/Coffin/AskRequestCoffinReserve';
import AskCancelFuneralReserve from './../../../Pages/Funeral/AskCancelFuneralReserve';
import AskCancelCoffinReserve from './../../../Pages/Coffin/AskCancelCoffinReserve';
import FuneralEstimate from './../../../Pages/Funeral/FuneralEstimate';
import CoffinEstimate from './../../../Pages/Coffin/CoffinEstimate';
import RequestPassword from './../../../Pages/RequestPassword';
import FuneralReserve from './../../../Pages/Funeral/FuneralReserve';
import CoffinReserve from './../../../Pages/Coffin/CoffinReserve';
import FuneralManagementView from './../../../Pages/Funeral/FuneralManagementView';
import SelectFuneralHall from '../../../Pages/Funeral/Select/SelectFuneralHall';
import SelectRoom from './../../../Pages/Funeral/Select/SelectRoom';
import SelectFlower from './../../../Pages/Funeral/Select/SelectFlower';
import SelectPortraitPhoto from './../../../Pages/Funeral/Select/SelectPortraitPhoto';
import SelectClothes from '../../../Pages/Funeral/Select/SelectClothes';
import SelectShroudClothes from './../../../Pages/Funeral/Select/SelectShroudClothes';
import SelectCoffin from './../../../Pages/Funeral/Select/SelectCoffin';
import SelectManpower from './../../../Pages/Funeral/Select/SelectManpower';
import AddFuneralEtc from './../../../Pages/Funeral/Select/AddFuneralEtc';
import SelectMove from '../../../Pages/Funeral/Select/SelectMove';
import FuneralManagementViewTemp from '../../../Pages/Funeral/FuneralManagementViewTemp';
import ChatRoom from '../../../Pages/Chat/ChatRoom';

// modal type에 맞는 component return
function getComponent(type) {
    // 기본
    if (type === modalTypes.default.REQUEST_PASSWORD) {
        return <RequestPassword />;
    } else if (type === modalTypes.default.FUNERAL_ESTIMATE) {
        return <FuneralEstimate />;
    } else if (type === modalTypes.default.COFFIN_ESTIMATE) {
        return <CoffinEstimate />;
    } else if (type === modalTypes.default.FUNERAL_RESERVE) {
        return <FuneralReserve />;
    } else if (type === modalTypes.default.COFFIN_RESERVE) {
        return <CoffinReserve />;
    } else if (type === modalTypes.default.FUNERAL_MANAGEMENT_VIEW) {
        return <FuneralManagementView />;
    } else if (type === modalTypes.default.FUNERAL_MANAGEMENT_VIEW_TEMP) {
        return <FuneralManagementViewTemp />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_FUNERAL_HALL) {
        return <SelectFuneralHall />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_ROOM) {
        return <SelectRoom />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_FLOWER) {
        return <SelectFlower />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_PORTRAIT_PHOTO) {
        return <SelectPortraitPhoto />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_MOVE) {
        return <SelectMove />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_CLOTHES) {
        return <SelectClothes />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_SHROUD_CLOTHES) {
        return <SelectShroudClothes />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_COFFIN) {
        return <SelectCoffin />;
    } else if (type === modalTypes.default.SELECT_FUNERAL_MANPOWER) {
        return <SelectManpower />;
    } else if (type === modalTypes.default.CHAT_ROOM) {
        return <ChatRoom />;
    }
    // 다이어로그
    else if (type === modalTypes.dialog.ASK_REQUEST_FUNERAL_ESTIMATE) {
        return <AskRequestFuneralEstimate />;
    } else if (type === modalTypes.dialog.ASK_REQUEST_COFFIN_ESTIMATE) {
        return <AskRequestCoffinEstimate />;
    } else if (type === modalTypes.dialog.ASK_REQUEST_FUNERAL_RESERVE) {
        return <AskRequestFuneralReserve />;
    } else if (type === modalTypes.dialog.ASK_CANCEL_FUNERAL_RESERVE) {
        return <AskCancelFuneralReserve />;
    } else if (type === modalTypes.dialog.ASK_REQUEST_COFFIN_RESERVE) {
        return <AskRequestCoffinReserve />;
    } else if (type === modalTypes.dialog.ASK_CANCEL_COFFIN_RESERVE) {
        return <AskCancelCoffinReserve />;
    } else if (type === modalTypes.dialog.ADD_FUNERAL_ETC) {
        return <AddFuneralEtc />;
    }
    // 액션
    else if (type === modalTypes.action.REQUEST_FUNERAL_ESTIMATE) {
        return <RequestFuneralEstimate />;
    } else if (type === modalTypes.action.REQUEST_FUNERAL_RESERVE) {
        return <RequestFuneralReserve />;
    } else if (type === modalTypes.action.REQUEST_COFFIN_ESTIMATE) {
        return <RequestCoffinEstimate />;
    } else if (type === modalTypes.action.REQUEST_COFFIN_RESERVE) {
        return <RequestCoffinReserve />;
    }
}

export default getComponent;
