import React from 'react';
import { useDispatch } from 'react-redux';
import ActionSheetLayout from '../../Layouts/ModalLayouts/ActionSheetLayout';
import { clearFormState, setFormState } from '../../redux/form';
import { closeModal, openModal } from '../../redux/modal';
import { removeEstimate, removeReservation } from '../../redux/user';
import { modalTypes } from '../../common/constants';

function RequestCoffinReserve({ data }) {
    const dispatch = useDispatch();

    return (
        <ActionSheetLayout
            header={{ content: '예약요청', props: {} }}
            actions={[
                {
                    content: '예약하기',
                    props: {
                        style: { color: '#0A84FF' },
                        onClick: () => {
                            // 현재 열려져 있는 ActionSheet 닫기
                            dispatch(closeModal());
                            // 예약 요청 modal open
                            dispatch(
                                openModal({
                                    // type: modalTypes.default.RESERVE_LEAVE_FOR_A_CEMETERY,
                                    data: data.idx,
                                })
                            );
                            // formState 초기화
                            dispatch(clearFormState());
                        },
                    },
                },
                {
                    content: '삭제',
                    props: {
                        style: { color: '#FF453A' },
                        onClick: () => {
                            // 예약 삭제
                            dispatch(
                                removeReservation({
                                    type: 'coffin',
                                    idx: data.idx,
                                })
                            );
                            // 현재 열려져 있는 ActionSheet 닫기
                            dispatch(closeModal());
                        },
                    },
                },
            ]}
        />
    );
}

export default RequestCoffinReserve;
