import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    border: 0;
    border-bottom: ${({ underline = '1px solid #dddddd' }) => underline};
    width: ${({ width = '100%' }) =>
        String(width).indexOf('%') === -1 ? width + 'px' : width};
    padding: 11px 0;
    appearance: none;
    color: inherit;
    resize: none;

    &:focus {
        outline: 0;
    }
    &::placeholder {
        color: #dddddd;
    }
    &:disabled {
        background-color: #ffffff;
    }
`;

const Input = React.forwardRef(
    (
        {
            as = 'input',
            name,
            onChange,
            placeholder,
            onBlur,
            type = 'text',
            underline,
            ...rest
        },
        ref
    ) => (
        <>
            {as === 'div' ? (
                <StyledInput
                    as={as}
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    type={type}
                    placeholder={placeholder}
                    underline={underline}
                    {...rest}
                >
                    {rest.value ? (
                        rest.value
                    ) : (
                        <div style={{ color: '#BBBBBB', fontWeight: '400' }}>
                            {placeholder}
                        </div>
                    )}
                </StyledInput>
            ) : (
                <StyledInput
                    as={as}
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    type={type}
                    placeholder={placeholder}
                    underline={underline}
                    {...rest}
                />
            )}
        </>
    )
);
Input.displayName = 'Input';

export default Input;
