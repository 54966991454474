import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalTypes } from '../../../common/constants';
import Modal from '../index';
import modal, { closeModal, deleteModal } from '../../../redux/modal';
import getComponent from './getComponent';
import { createGlobalStyle } from 'styled-components';
import { getModalType } from '../../../common/util';

const BodyHidden = createGlobalStyle`
body{
    overflow:hidden ;
}
`;

function ModalManager() {
    const modalState = useSelector((state) => state.modal);
    // 렌더링할 컴포넌트들
    const [Components, setComponents] = useState([]);
    // 모달창이 띄워 졌는지 여부
    const [isHaveModal, setIsHaveModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            modalState.length >= 3 &&
            modalState.filter(({ isOpen }) => !isOpen).length ===
                modalState.length
        ) {
            modalState.forEach((_, idx) => {
                if (modalState.length - 2 < idx) {
                    dispatch(deleteModal({ idx }));
                }
            });
        }

        // isOpen이 false인 modal 제거
        for (let idx = 0; idx < modalState.length; idx++) {
            const modal = modalState[idx];
            const { type, isOpen } = modal;
            if (
                idx !== modalState.length - 1 &&
                !isOpen &&
                getModalType(type) !== modalTypes.shape.ACTION_SHEET
            ) {
                dispatch(deleteModal({ idx }));
            }
        }
    }, [modalState]);

    useEffect(() => {
        setIsHaveModal(
            [...modalState].reduce((prev, curr) => {
                if (prev) {
                    return prev;
                }
                return curr.isOpen;
            }, false)
        );

        // 제일 상단에 떠있는 모달 idx
        const isFirstIdx =
            modalState.length -
            1 -
            [...modalState].reverse().findIndex(({ isOpen }) => isOpen);

        const newComponents = [];
        [...modalState].forEach((modal, idx) => {
            const { type, isOpen, data, ...rest } = modal;

            // 모달 모양 타입
            const modalShapeType = (function () {
                // Default
                if (
                    Object.keys(modalTypes.default).find(
                        (currType) => currType === type
                    )
                ) {
                    return modalTypes.shape.DEFAULT;
                } else if (
                    Object.keys(modalTypes.dialog).find(
                        (currType) => currType === type
                    )
                ) {
                    return modalTypes.shape.DIALOG;
                } else if (
                    Object.keys(modalTypes.action).find(
                        (currType) => currType === type
                    )
                ) {
                    return modalTypes.shape.ACTION_SHEET;
                }
            })();

            newComponents.push(
                <Modal
                    isOpen={isOpen}
                    isFirst={isFirstIdx === idx}
                    shapeType={modalShapeType}
                >
                    {React.cloneElement(getComponent(type), {
                        modal: { type },
                        ...data,
                        ...rest,
                    })}
                </Modal>
            );
        });
        setComponents([...newComponents]);
    }, [modalState]);

    useEffect(() => {
        if (modalState.length > 0) {
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = () => {
                window.history.pushState(null, '', window.location.href);
                dispatch(closeModal());
            };
            return () => {
                window.onpopstate = undefined;
            };
        }
    }, [modalState]);

    return (
        <>
            {isHaveModal && <BodyHidden />}
            {Components.map((component, idx) => (
                <React.Fragment key={idx}>{component}</React.Fragment>
            ))}
        </>
    );
}

export default ModalManager;
