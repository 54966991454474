import React from 'react';

const alertStyle = {
    backgroundColor: '#454545',
    borderRadius: 6,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 8px 12px 0 rgba(0,0,0,0.3)',
    boxSizing: 'border-box',
    fontSize: '14px',
    position: 'relative',
    padding: '10px',
};

const contentWrapperStyle = {
    padding: '10px 10px 10px 10px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
};

const messageStyle = {
    flex: 3,
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
};

const AlertTemplate = ({ message, options, style, close }) => (
    <div style={{ ...alertStyle, ...style }} onClick={close}>
        <div style={contentWrapperStyle}>
            <div style={messageStyle}>{message}</div>
        </div>
    </div>
);

export default AlertTemplate;
