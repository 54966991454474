import React, { useEffect } from 'react';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import Login from './Pages/Login';
import GlobalStyle from './Style/GlobalStyle';
import Main from './Pages/Main';
import { useDispatch, useSelector } from 'react-redux';
import Reserve from './Pages/Reserve';
import FuneralManagement from './Pages/Funeral/FuneralManagement';
import ModalManager from './Components/Modal/ModalManager/index';
import useGetMe from './hooks/useGetMe';
import axios from 'axios';
import Chat from './Pages/Chat/Chat';
import { clearModal } from './redux/modal';
import SendBirdProvider from './Components/SendBirdProvider';
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './Components/AlertTemplate';
import useGetLoginStatus from './hooks/useGetLoginStatus';

function App() {
    const navigate = useNavigate();
    const loginStatus = useGetLoginStatus();
    const dispatch = useDispatch();
    useGetMe();
    useEffect(() => {
        // 로그인 상태가 아니라면 Login 페이지로 이동
        if (loginStatus.isFail) {
            navigate('/login');
            dispatch(clearModal());
        }
    }, [dispatch, loginStatus, navigate]);

    // React Native Listener
    useEffect(() => {
        const listener = (event) => {
            const { data, type } = JSON.parse(event.data);
            if (type === 'REGISTER_TOKEN') {
                axios.post('/users/registerFcmToken', { fcmToken: data });
            }
        };

        if (window.ReactNativeWebView) {
            /** android */
            document.addEventListener('message', listener);
            /** ios */
            window.addEventListener('message', listener);
        } else {
            // 모바일이 아니라면 모바일 아님을 alert로 띄웁니다.
        }
    }, []);

    return (
        <SendBirdProvider>
            <AlertProvider
                template={AlertTemplate}
                timeout={1500}
                position={positions.MIDDLE}
                containerStyle={{ zIndex: 99999 }}
            >
                <GlobalStyle />
                <ModalManager />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/reserve" element={<Reserve />} />
                    <Route path="/manage" element={<FuneralManagement />} />
                    <Route path="/consult" element={<Chat />} />
                </Routes>
            </AlertProvider>
        </SendBirdProvider>
    );
}

export default App;
