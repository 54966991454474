import React, { useState } from 'react';
import Input from './../Components/Form/Input';
import { useForm } from 'react-hook-form';
import Space from './../Style/Space';
import { addPhoneHyphen, getErrorMessage } from './../common/util';
import Button, { LIGHT } from './../Style/Button';
import DefaultModalLayout from './../Layouts/ModalLayouts/DefaultModalLayout';
import regexps from './../common/regexp';
import { useMutation } from 'react-query';
import axios from 'axios';
import FormMessage from './../Components/Form/FormMessage';

function RequestPassword() {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const data = watch();
    const disabledSubmit = !data.id;

    // 이메일 혹은 휴대폰 인증번호 발급 성공여부
    const [isSuccessRequestCert, setIsSuccessRequestCert] = useState(false);
    const { mutate: requestCertEmail } = useMutation(['requestCertEmail'], {
        mutationFn: async (email) =>
            (
                await axios.get('/users/requestCert/e', {
                    params: {
                        email,
                        authType: 'PARTNER_D_LOGIN',
                    },
                })
            ).data,
        onSuccess: ({ success }) => {
            setIsSuccessRequestCert(success);
        },
    });
    const { mutate: requestCertPhone } = useMutation(['requestCertPhone'], {
        mutationFn: async (phone) =>
            (
                await axios.get('/users/requestCert/p', {
                    params: {
                        phone: addPhoneHyphen(phone),
                        authType: 'PARTNER_D_LOGIN',
                    },
                })
            ).data,
        onSuccess: ({ success }) => {
            setIsSuccessRequestCert(success);
        },
    });

    const onSubmit = (data) => {
        const { id } = data;
        // 이메일 정규 표현식
        const emailRegexp = new RegExp(regexps.email);

        // 아이디가 이메일 형식인경우 이메일에 인증번호를 발송요청
        if (emailRegexp.test(id)) {
            // requestCertEmail(id);
            return;
        }
        // 아이디가 휴대폰 번호 형식인경우 휴대폰에 인증번호를 발송요청
        requestCertPhone(id);
    };
    return (
        <>
            <DefaultModalLayout header={{ content: '일회용 비밀번호 요청' }}>
                <>
                    <Space height={30} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            placeholder={'이메일 또는 휴대폰 번호'}
                            {...register('id', {
                                required:
                                    '이메일 또는 휴대폰 번호를 입력해주세요.',
                                validate: {
                                    checkPattern: (v) => {
                                        let valid = false;
                                        // 정규 표현식중에 맞는 표현이 있다면 true
                                        [regexps.email, regexps.phone].forEach(
                                            (regexp) => {
                                                if (!valid) {
                                                    valid = !!regexp.test(v);
                                                }
                                            }
                                        );

                                        return (
                                            valid ||
                                            '이메일 또는 휴대폰 번호의 형식에 맞춰 입력해주세요.'
                                        );
                                    },
                                },
                            })}
                            disabled={isSuccessRequestCert}
                        />
                        <Space height={8} />
                        <FormMessage
                            isError={errors.id}
                            error={getErrorMessage(errors)}
                            isSuccess={isSuccessRequestCert}
                            success={'인증번호가 성공적으로 전송되었습니다.'}
                        />

                        <Space height={24} />
                        <Button.Circle theme={LIGHT} disabled={disabledSubmit}>
                            발송
                        </Button.Circle>
                    </form>
                </>
            </DefaultModalLayout>
        </>
    );
}

export default RequestPassword;
