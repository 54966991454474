import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    token: localStorage.getItem('accessToken'),
    me: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            const token = action.payload;
            localStorage.setItem('accessToken', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            state.token = token;
        },
        login: (state, action) => {
            const data = action.payload;
            state.me = data;
        },
        logout: (state) => {
            localStorage.removeItem('accessToken');
            delete axios.defaults.headers.common['Authorization'];
            state.me = null;
            if (window.ReactNativeWebView) {
                // RN에 FCM 토큰 요청
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'LOGOUT',
                    })
                );
            }
            state.token = null;
        },
        removeEstimate: (state, action) => {
            const {
                payload: { type, idx },
            } = action;

            state.me.estimates[type] = state.me.estimates[type].filter(
                (ele) => ele.idx !== idx
            );
        },
        addReservation: (state, action) => {
            const {
                payload: { type, data },
            } = action;

            state.me.reservation[type].push(data);
        },
        removeReservation: (state, action) => {
            const {
                payload: { type, idx },
            } = action;

            state.me.reservation[type] = state.me.reservation[type].filter(
                (ele) => ele.idx !== idx
            );
        },
        addFuneralManagement: (state, action) => {
            const {
                payload: { type, data, idx },
            } = action;

            // funeralIdx에 해당하는 장례관리 아이템이 있는지 체크
            const findManagementIdx = state.me.funeralManagement.findIndex(
                (ele) => ele.funeralIdx === idx
            );

            if (findManagementIdx >= 0) {
                state.me.funeralManagement[findManagementIdx][type] = data;
            } else {
                const { funeralIdx, user, reside, deceased } = data.estimate;

                state.me.funeralManagement.push({
                    funeralIdx,
                    user,
                    reside,
                    deceased,
                    funeral: null,
                    coffin: null,
                    [type]: data,
                });
            }
        },
    },
});

export const {
    setToken,
    login,
    logout,
    removeEstimate,
    addReservation,
    removeReservation,
    addFuneralManagement,
} = userSlice.actions;

export default userSlice.reducer;
