import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, setToken } from '../redux/user';

function useGetMe() {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.user);
    const { mutate: getMe } = useMutation(['getMe'], {
        mutationFn: async () => axios.get('/users/me').then((res) => res.data),
        onSuccess: ({ success, newToken, userInfo }) => {
            if (success) {
                // 토큰이 만료되어 새로운 토큰이 발급되는경우
                if (newToken) {
                    dispatch(setToken(newToken));
                }
                dispatch(login(userInfo));
            }
        },
        onError: ({
            response: {
                data: { success },
            },
        }) => {
            // 정보가져오기 실패
            if (!success) {
                dispatch(logout());
            }
        },
    });

    useEffect(() => {
        // 토큰이 존재한다면
        if (token) {
            dispatch(setToken(token));
            getMe();
        }
    }, [dispatch, getMe, token]);
}

export default useGetMe;
