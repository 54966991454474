import React from 'react';
import DefaultLayout from '../Layouts/DefaultLayout';
import Space from '../Style/Space';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getDateString, getTermDateString } from '../common/util';

import Flex from '../Style/Flex';
import { modalTypes } from '../common/constants';
import { openModal } from '../redux/modal';

import EnterImage from './../resources/image/ic_enter.svg';
import { useQuery } from 'react-query';
import axios from 'axios';
import useGetLoginStatus from './../hooks/useGetLoginStatus';

const Card = styled.div`
    padding: 25px 20px;
    background: #ffffff;
    box-shadow: 1px 4px 14px 4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    font-size: 15px;
`;

function Main() {
    const { me } = useSelector((state) => state.user);
    const loginStatus = useGetLoginStatus();
    const dispatch = useDispatch();

    const { data: funeralList } = useQuery(['getFuneralList'], {
        queryFn: async () =>
            (
                await axios.get('/estimate/request/funeral/d/list', {
                    params: { status: 'MO' },
                })
            ).data.funeralList,
        enabled: loginStatus.isSuccess,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
    });

    const { data: cemeteryList } = useQuery(['getCemeteryList'], {
        queryFn: () =>
            axios
                .get('/estimate/request/cemetery/d/list', {
                    params: { status: 'MO' },
                })
                .then((res) => res.data.cemeteryList),
        enabled: loginStatus.isSuccess,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
    });

    return (
        <DefaultLayout>
            <div>
                {funeralList && (
                    <>
                        <Space height={30} />
                        <strong>장례견적</strong>
                        {funeralList.map((funeral, idx) => {
                            const {
                                id,
                                deceased,
                                startDate,
                                end_date: endDate,
                                range,
                                rangCd,
                                visitor,
                                visitorCd,
                                area,
                                status,
                                memo,
                                bereaved,
                            } = funeral;
                            return (
                                <React.Fragment key={idx}>
                                    <Space height={14} />
                                    <button
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    type: modalTypes.action
                                                        .REQUEST_FUNERAL_ESTIMATE,
                                                    data: { funeral },
                                                })
                                            );
                                        }}
                                    >
                                        <Card>
                                            <Flex.Wrapper>
                                                <Flex.Item flex={1}>
                                                    <div>
                                                        {getTermDateString(
                                                            startDate,
                                                            endDate
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: '#666',
                                                        }}
                                                    >
                                                        {area}
                                                    </div>
                                                </Flex.Item>
                                                <img
                                                    src={EnterImage}
                                                    alt="enter"
                                                />
                                            </Flex.Wrapper>
                                        </Card>
                                    </button>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
                {cemeteryList && (
                    <>
                        <Space height={30} />
                        <strong>발인견적</strong>
                        {cemeteryList.map((ele, idx) => {
                            const {
                                id,
                                deceased,
                                deceasedAge,
                                balinDate,
                                area,
                                budget,
                                preffer,
                                bereaved,
                            } = ele;

                            return (
                                <React.Fragment key={idx}>
                                    <Space height={14} />
                                    <button
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    type: modalTypes.action
                                                        .REQUEST_COFFIN_ESTIMATE,
                                                    data: { estimate: ele },
                                                })
                                            );
                                        }}
                                    >
                                        <Card>
                                            <Flex.Wrapper>
                                                <Flex.Item flex={1}>
                                                    <div>
                                                        {getDateString(
                                                            balinDate !==
                                                                '0000-00-00'
                                                                ? balinDate
                                                                : new Date()
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: '#666',
                                                        }}
                                                    >
                                                        {area}
                                                        {budget && ' / '}
                                                        {budget}
                                                        {preffer && ' / '}
                                                        {preffer}
                                                    </div>
                                                </Flex.Item>
                                                <img
                                                    src={EnterImage}
                                                    alt="enter"
                                                />
                                            </Flex.Wrapper>
                                        </Card>
                                    </button>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
            </div>
        </DefaultLayout>
    );
}

export default Main;
