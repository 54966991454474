import { createSlice } from '@reduxjs/toolkit';
import { modalTypes } from '../common/constants';
import { getModalType } from '../common/util';

const initialState = [];

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModalState: (state, action) => {
            const { data } = action.payload;
            return data;
        },
        openModal: (state, action) => {
            state.push({ ...action.payload, isOpen: true });
        },
        // isOpen을 false로 설정해 modal close animation이 실행되도록함
        closeModal: (state, action) => {
            // 맨앞에 있는 모달이 종료 animation을 실행하고 isOpen이 false인경우
            // 맨앞에 있는 모달 제거
            if (state.length > 0) {
                for (let i = state.length - 1; i > -1; i--) {
                    if (!state[i].isOpen) {
                        state.pop();
                    } else {
                        break;
                    }
                }

                if (state[state.length - 1]) {
                    state[state.length - 1].isOpen = false;
                }
            }
        },
        deleteModal: (state, action) => {
            const { idx } = action.payload || {};
            if (idx !== undefined) {
                state.splice(idx, 1);
            } else {
                state.pop();
            }
        },
        setModalProps: (state, action) => {
            const { type, props } = action.payload;
            // modal type에 해당하는 modal의 idx를 찾음
            const idx = state.findIndex((ele) => ele.type === type);
            state[idx] = {
                ...state[idx],
                props: { ...state[idx].props, ...props },
            };
        },

        clearModal: (state) => initialState,
    },
});

// Action creators are generated for each case reducer function
export const {
    setModalState,
    openModal,
    closeModal,
    deleteModal,
    setModalProps,
    clearModal,
} = modalSlice.actions;

export default modalSlice.reducer;
