import React from 'react';
import Flex from './../../Style/Flex';
import CheckImage from './../../resources/image/ic-check-circle.svg';
import CheckActiveImage from './../../resources/image/ic-check-circle-active.svg';

function Element({
    name,
    extra,
    onClick,
    selected,
    type = 'default',
    children,
}) {
    return (
        <>
            {children ? (
                <Flex.Wrapper
                    justifyContent="stretch"
                    alignItems="stretch"
                    column
                    style={{
                        border: `1px solid ${selected ? '#C3A981' : '#eeeeee'}`,
                        marginTop: '8px',
                        fontSize: '15px',
                    }}
                >
                    <Flex.Wrapper
                        justifyContent={
                            type === 'default' ? 'stretch' : 'center'
                        }
                        alignItems={type === 'default' ? 'stretch' : 'center'}
                        as={Flex.Item}
                        style={{
                            width: '100%',
                            minHeight: `${type === 'default' ? '84px' : '0'}`,
                            padding: `${
                                type === 'default' ? '15px' : '18px 15px'
                            }`,
                        }}
                    >
                        <Flex.Item flex={1}>
                            <Flex.Wrapper
                                column
                                justifyContent="stretch"
                                alignItems="stretch"
                            >
                                <strong style={{ width: '100%' }}>
                                    {name}
                                </strong>
                                <div
                                    style={{ fontSize: '12px', color: '#888' }}
                                >
                                    {extra}
                                </div>
                            </Flex.Wrapper>
                        </Flex.Item>
                        <button
                            type="button"
                            onClick={onClick}
                            style={{ alignSelf: 'center', cursor: 'pointer' }}
                        >
                            <img
                                src={selected ? CheckActiveImage : CheckImage}
                                alt="check"
                            />
                        </button>
                    </Flex.Wrapper>
                    <>{children}</>
                </Flex.Wrapper>
            ) : (
                <Flex.Wrapper
                    justifyContent={type === 'default' ? 'stretch' : 'center'}
                    alignItems={type === 'default' ? 'stretch' : 'center'}
                    style={{
                        border: `1px solid ${selected ? '#C3A981' : '#eeeeee'}`,
                        marginTop: '8px',
                        fontSize: '15px',
                        minHeight: `${type === 'default' ? '84px' : '0'}`,
                        padding: `${type === 'default' ? '15px' : '18px 15px'}`,
                    }}
                >
                    <Flex.Item flex={1}>
                        <Flex.Wrapper
                            column
                            justifyContent="stretch"
                            alignItems="stretch"
                        >
                            <strong style={{ width: '100%' }}>{name}</strong>
                            <div style={{ fontSize: '12px', color: '#888' }}>
                                {extra}
                            </div>
                        </Flex.Wrapper>
                    </Flex.Item>
                    <button
                        type="button"
                        onClick={onClick}
                        style={{ alignSelf: 'center', cursor: 'pointer' }}
                    >
                        <img
                            src={selected ? CheckActiveImage : CheckImage}
                            alt="check"
                        />
                    </button>
                </Flex.Wrapper>
            )}
        </>
    );
}

export default Element;
