import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FuneralItemInputLayout from '../../Components/Form/FuneralItemInputLayout';
import Modal from '../../Components/Modal';
import Space from '../../Style/Space';
import Button from '../../Style/Button';
import { getDateString } from '../../common/util';
import InputSelect from '../../Components/Form/InputSelect';
import { useSelector } from 'react-redux';
import { SelectElementTypes } from '../../common/constants';
import { numberAddComma } from '../../common/util';
import Flex from '../../Style/Flex';
import { openModal } from '../../redux/modal';
import ArrowRightImage from './../../resources/image/ic-arrow-right.svg';
import { setFormState } from '../../redux/form';
import { modalTypes } from './../../common/constants';
import { FormTypes } from './../../redux/form';

const commonInputProps = {
    underline: 'none',
    disabled: true,
    as: 'div',
    style: { minHeight: '44px' },
};

const ArrowRight = () => (
    <img src={ArrowRightImage} alt="arrow-right" style={{ margin: '0 8px' }} />
);

function CoffinEstimate() {
    const { [FormTypes.ESTIMATE_LEAVE_FOR_A_CEMETERY]: formState } =
        useSelector((state) => state.form);
    const dispatch = useDispatch();

    const {
        funeral,
        date,
        cemetery: { item, tombstone },
        crematorium,
        vehicle,
        estimate,
    } = formState;

    const initInputValues = useCallback(() => {
        // 장례견젹 form양식이 다채워졌는지 여부
        const isFilledForm = !!(
            funeral &&
            date &&
            item &&
            tombstone &&
            tombstone.area &&
            tombstone.use &&
            tombstone.management &&
            crematorium &&
            crematorium.item &&
            crematorium.reservation
        );

        return {
            funeral: funeral && {
                title: funeral.name,
                content: funeral.address,
            },
            date: {
                title: getDateString(date),
            },
            cemetery: {
                item: item
                    ? {
                          title: item.name,
                          content: item.address,
                      }
                    : estimate && {
                          title: `${estimate.cemetery.location}/${
                              estimate.cemetery.religion
                          }/${estimate.cemetery.type.join('/')}`,
                          content: '',
                      },

                tombstone: {
                    title:
                        tombstone && tombstone.area && tombstone.name ? (
                            <>
                                {tombstone.area.name}
                                <ArrowRight />
                                {tombstone.name}
                            </>
                        ) : (
                            '구역, 위치'
                        ),
                },
                fees: {
                    use:
                        tombstone && tombstone.use
                            ? {
                                  title: `이용료 ${tombstone.use.term}년`,
                                  content: `${numberAddComma(
                                      tombstone.use.price
                                  )}원`,
                              }
                            : null,
                    management:
                        tombstone && tombstone.management
                            ? {
                                  title: `관리비 ${tombstone.management.term}년`,
                                  content: `${numberAddComma(
                                      tombstone.management.price
                                  )}원`,
                              }
                            : null,
                },
            },
            crematorium:
                crematorium.item && crematorium.reservation
                    ? {
                          title: crematorium.item.name,
                          content: crematorium.item.address,
                      }
                    : estimate && {
                          title: estimate.crematorium.location
                              .split(' ')
                              .map((ele, idx) => (
                                  <React.Fragment key={ele}>
                                      {ele}
                                      {idx + 1 !==
                                          estimate.crematorium.location.split(
                                              ' '
                                          ).length && <ArrowRight />}
                                  </React.Fragment>
                              )),
                      },
            reservation:
                crematorium.item && crematorium.reservation
                    ? {
                          title: crematorium.reservation.dateString,
                          content:
                              numberAddComma(crematorium.reservation.price) +
                              '원',
                      }
                    : null,
            distance:
                funeral && item && crematorium.item && crematorium.reservation
                    ? {
                          title: '56.2km (58분)',
                          content: (
                              <div style={{ fontSize: '12px' }}>
                                  <div>출발 : {funeral.name}</div>
                                  <div>경우 : {item.name}</div>
                                  <div>도착 : {crematorium.item.name}</div>
                              </div>
                          ),
                      }
                    : null,
            vehicle: vehicle.map(
                (ele, idx) =>
                    ele.units[0].num > 0 && {
                        title: `${ele.name} ${ele.units[0].num}${ele.units[0].name}`,
                        content:
                            tombstone &&
                            tombstone.use &&
                            tombstone.management &&
                            crematorium.item &&
                            crematorium.reservation
                                ? numberAddComma(ele.units[0].num * ele.price) +
                                  '원'
                                : null,
                    }
            ),
            sum: (function () {
                let sum = 0;
                // 장지가 설정된 경우
                if (tombstone && tombstone.use && tombstone.management) {
                    sum += tombstone.use.price;
                    sum += tombstone.management.price;
                }
                // 화장장이 설정된 경우
                if (crematorium.item && crematorium.reservation) {
                    sum += crematorium.reservation.price;
                }

                // 장지와 화장장이 둘다 설정된경우
                if (
                    tombstone &&
                    tombstone.use &&
                    tombstone.management &&
                    crematorium.item &&
                    crematorium.reservation
                ) {
                    vehicle.forEach(({ units: [{ num }], price }) => {
                        sum += num * price;
                    });
                }

                return sum;
            })(),
            isFilledForm,
        };
    }, [formState]);

    const [inputValues, setInputValues] = useState(initInputValues());

    useEffect(() => {
        setInputValues(initInputValues());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    const onSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Modal
                header={{ content: '발인견적 작성', left: { content: '취소' } }}
                body={{
                    content: (
                        <>
                            <Space height={16} />
                            <form
                                onSubmit={onSubmit}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <FuneralItemInputLayout label="장례식장">
                                        <InputSelect
                                            value={
                                                inputValues &&
                                                inputValues.funeral.title
                                            }
                                            extra={
                                                inputValues &&
                                                inputValues.funeral.content
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="발인일">
                                        <InputSelect
                                            value={
                                                inputValues &&
                                                inputValues.date.title
                                            }
                                            {...commonInputProps}
                                        />
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="장지">
                                        <InputSelect
                                            value={
                                                inputValues &&
                                                inputValues.cemetery.item.title
                                            }
                                            extra={
                                                inputValues &&
                                                inputValues.cemetery.item
                                                    .content
                                            }
                                            modal={{
                                                type: SelectElementTypes.coffin
                                                    .CEMETERY,
                                            }}
                                            {...commonInputProps}
                                        />
                                        <Flex.Wrapper
                                            style={{ margin: '-4px 0' }}
                                        >
                                            <Flex.Item flex={1}>
                                                <div
                                                    style={{
                                                        padding: '4px 0',
                                                    }}
                                                >
                                                    <InputSelect
                                                        value={
                                                            inputValues &&
                                                            inputValues.cemetery
                                                                .tombstone.title
                                                        }
                                                        titleBold={
                                                            tombstone &&
                                                            tombstone.idx &&
                                                            tombstone.area
                                                        }
                                                        modal={
                                                            !(
                                                                tombstone &&
                                                                tombstone.idx &&
                                                                tombstone.area
                                                            ) &&
                                                            (item
                                                                ? {
                                                                      type: SelectElementTypes
                                                                          .coffin
                                                                          .TOMBSTONE,
                                                                  }
                                                                : {
                                                                      type: SelectElementTypes
                                                                          .coffin
                                                                          .CEMETERY,
                                                                  })
                                                        }
                                                        {...commonInputProps}
                                                    />
                                                </div>
                                                {inputValues.cemetery.fees
                                                    .use && (
                                                    <div
                                                        style={{
                                                            padding: '4px 0',
                                                            borderTop:
                                                                '1px solid #eeeeee',
                                                        }}
                                                    >
                                                        <InputSelect
                                                            value={
                                                                inputValues &&
                                                                inputValues
                                                                    .cemetery
                                                                    .fees.use
                                                                    .title
                                                            }
                                                            extra={
                                                                inputValues &&
                                                                inputValues
                                                                    .cemetery
                                                                    .fees.use
                                                                    .content
                                                            }
                                                            {...commonInputProps}
                                                        />
                                                    </div>
                                                )}
                                                {inputValues.cemetery.fees
                                                    .management && (
                                                    <div
                                                        style={{
                                                            padding: '4px 0',
                                                            borderTop:
                                                                '1px solid #eeeeee',
                                                        }}
                                                    >
                                                        <InputSelect
                                                            value={
                                                                inputValues &&
                                                                inputValues
                                                                    .cemetery
                                                                    .fees
                                                                    .management
                                                                    .title
                                                            }
                                                            extra={
                                                                inputValues &&
                                                                inputValues
                                                                    .cemetery
                                                                    .fees
                                                                    .management
                                                                    .content
                                                            }
                                                            {...commonInputProps}
                                                        />
                                                    </div>
                                                )}
                                            </Flex.Item>
                                            {inputValues.cemetery.fees.use && (
                                                <button
                                                    type="button"
                                                    style={{ color: '#C3A981' }}
                                                    onClick={() => {
                                                        dispatch(
                                                            openModal({
                                                                type: SelectElementTypes
                                                                    .coffin
                                                                    .TOMBSTONE,
                                                            })
                                                        );
                                                    }}
                                                >
                                                    변경
                                                </button>
                                            )}
                                        </Flex.Wrapper>
                                    </FuneralItemInputLayout>
                                    <FuneralItemInputLayout label="화장장">
                                        <InputSelect
                                            value={
                                                inputValues &&
                                                inputValues.crematorium.title
                                            }
                                            extra={
                                                inputValues &&
                                                inputValues.crematorium.content
                                            }
                                            modal={{
                                                type: SelectElementTypes.coffin
                                                    .CREMATORIUM,
                                            }}
                                            {...commonInputProps}
                                        />
                                        {inputValues &&
                                            inputValues.reservation && (
                                                <InputSelect
                                                    value={
                                                        inputValues &&
                                                        inputValues.reservation
                                                            .title
                                                    }
                                                    extra={
                                                        inputValues &&
                                                        inputValues.reservation
                                                            .content
                                                    }
                                                    modal={{
                                                        type: SelectElementTypes
                                                            .coffin.RESERVATION,
                                                    }}
                                                    {...commonInputProps}
                                                />
                                            )}
                                    </FuneralItemInputLayout>

                                    <FuneralItemInputLayout label="차량">
                                        {[
                                            inputValues &&
                                                inputValues.distance && (
                                                    <InputSelect
                                                        value={
                                                            inputValues &&
                                                            inputValues.distance
                                                                .title
                                                        }
                                                        extra={
                                                            inputValues &&
                                                            inputValues.distance
                                                                .content
                                                        }
                                                        {...commonInputProps}
                                                    />
                                                ),
                                            ...(inputValues &&
                                                inputValues.vehicle &&
                                                inputValues.vehicle.map(
                                                    (ele, idx) => {
                                                        const {
                                                            title,
                                                            content,
                                                        } = ele;
                                                        return (
                                                            ele && (
                                                                <React.Fragment
                                                                    key={idx}
                                                                >
                                                                    <InputSelect
                                                                        titleBold
                                                                        value={
                                                                            title
                                                                        }
                                                                        extra={
                                                                            content
                                                                        }
                                                                        {...commonInputProps}
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        );
                                                    }
                                                )),
                                        ]}
                                    </FuneralItemInputLayout>
                                    <div
                                        style={{
                                            margin: '0 -16px',
                                            width: 'calc(100% + 32px)',
                                            height: '8px',
                                            background: '#F9F9F9',
                                        }}
                                    />
                                    <Flex.Wrapper
                                        justifyContent="space-between"
                                        style={{ margin: '18px 0 58px' }}
                                    >
                                        <strong>견적 금액</strong>
                                        <div style={{ color: '#ff5160' }}>
                                            <strong>
                                                {inputValues &&
                                                    inputValues.sum &&
                                                    numberAddComma(
                                                        inputValues.sum
                                                    )}
                                            </strong>{' '}
                                            원
                                        </div>
                                    </Flex.Wrapper>
                                </div>
                                <Button
                                    style={{
                                        position: 'sticky',
                                        bottom: '0',
                                        margin: '0 -16px',
                                        width: 'calc(100% + 32px)',
                                        cursor: !(
                                            inputValues &&
                                            inputValues.isFilledForm
                                        )
                                            ? 'default'
                                            : 'pointer',
                                    }}
                                    disabled={Boolean(
                                        !(
                                            inputValues &&
                                            inputValues.isFilledForm
                                        )
                                    )}
                                    onClick={() => {
                                        dispatch(
                                            setFormState({
                                                type: FormTypes.ESTIMATE_LEAVE_FOR_A_CEMETERY,
                                                prop: 'totalPrice',
                                                data: inputValues.sum,
                                            })
                                        );

                                        dispatch(
                                            setFormState({
                                                type: FormTypes.ESTIMATE_LEAVE_FOR_A_CEMETERY,
                                                prop: 'displayValues',
                                                data: inputValues,
                                            })
                                        );

                                        dispatch(
                                            openModal({
                                                type: modalTypes.dialog
                                                    .ASK_REQUEST_ESTIMATES_LEAVE_FOR_A_CEMETERY,
                                            })
                                        );
                                    }}
                                >
                                    견적 발송
                                </Button>
                            </form>
                        </>
                    ),
                }}
            />
        </>
    );
}

export default CoffinEstimate;
