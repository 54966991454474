import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/modal';
import Flex from '../../Style/Flex';
import Input from './Input';

const InputSelect = React.forwardRef(
    ({ extra, modal, titleBold, ...rest }, ref) => {
        const dispatch = useDispatch();

        return (
            <Flex.Wrapper justifyContent="stretch">
                <Flex.Item flex={1} style={{ ...rest.style }}>
                    <Flex.Wrapper column alignItems="stretch">
                        <Input
                            ref={ref}
                            {...rest}
                            style={{
                                fontWeight:
                                    extra || titleBold ? 'bold' : 'normal',
                                padding: extra ? '11px 0 2px 0' : '11px 0',
                            }}
                        />
                        {extra && (
                            <div
                                style={{
                                    color: '#888888',
                                    padding: '0 0 11px',
                                }}
                            >
                                {extra}
                            </div>
                        )}
                    </Flex.Wrapper>
                </Flex.Item>
                {modal && (
                    <button
                        type="button"
                        style={{ color: '#C3A981' }}
                        onClick={() => {
                            dispatch(openModal(modal));
                        }}
                    >
                        변경
                    </button>
                )}
            </Flex.Wrapper>
        );
    }
);

InputSelect.displayName = 'InputSelect';

export default InputSelect;
