import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeModal,
    deleteModal,
    openModal,
    setModalProps,
    setModalState,
} from '../redux/modal';

function useModal() {
    const dispatch = useDispatch();

    const open = useCallback((data) => () => dispatch(openModal(data)), []);

    const close = useCallback(() => {
        dispatch(closeModal());
    }, []);

    const deleteModalMethod = useCallback(() => {
        dispatch(deleteModal());
    }, []);

    const setModalStateMethod = useCallback(
        (data) => dispatch(setModalState(data)),
        []
    );

    const setModalPropsMethod = useCallback((data) => {
        dispatch(setModalProps(data));
    }, []);

    const modalState = useSelector((state) => state.modal);

    return {
        closeModal: close,
        openModal: open,
        deleteModal: deleteModalMethod,
        setModalState: setModalStateMethod,
        setModalProps: setModalPropsMethod,
        modalState,
    };
}

export default useModal;
