import React from 'react';
import { useDispatch } from 'react-redux';
import ActionSheetLayout from '../../Layouts/ModalLayouts/ActionSheetLayout';
import { clearFormState } from '../../redux/form';
import { setFuneralItem } from '../../redux/funeralItem';
import {
    changeModal,
    closeModal,
    deleteModal,
    openModal,
} from '../../redux/modal';
import { modalTypes } from './../../common/constants';
import { FuneralItemTypes } from './../../redux/funeralItem';
import { FormTypes } from './../../redux/form';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

function RequestFuneralEstimate({ funeral }) {
    const dispatch = useDispatch();

    const { id: estimateReqId } = funeral;
    const queryClient = useQueryClient();
    const { mutate: rejectEstimate } = useMutation({
        mutationFn: async () =>
            await axios.put(`estimate/request/funeral/reject/${estimateReqId}`),
    });

    return (
        <ActionSheetLayout
            header={{ content: '장례견적 요청', props: {} }}
            actions={[
                {
                    content: '견적작성',
                    props: {
                        style: { color: '#0A84FF' },
                        onClick: () => {
                            // 현재 열려져 있는 ActionSheet 닫기
                            dispatch(closeModal());

                            // 견적 form 초기화
                            dispatch(
                                clearFormState({
                                    type: FormTypes.FUNERAL_ESTIMATE,
                                })
                            );

                            // 견적 정보 set
                            dispatch(
                                setFuneralItem({
                                    type: FuneralItemTypes.FUNERAL_ESTIMATE_REQUEST,
                                    data: funeral,
                                })
                            );
                            // 견적 요청 modal open
                            dispatch(
                                openModal({
                                    type: modalTypes.default.FUNERAL_ESTIMATE,
                                })
                            );
                        },
                    },
                },

                {
                    content: '삭제',
                    props: {
                        style: { color: '#FF453A' },
                        onClick: () => {
                            rejectEstimate(null, {
                                onSuccess: () =>
                                    queryClient.fetchQuery('getFuneralList'),
                            });
                            // 현재 열려져 있는 ActionSheet 닫기
                            dispatch(closeModal());
                        },
                    },
                },
            ]}
        />
    );
}

export default RequestFuneralEstimate;
