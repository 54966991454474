import React from 'react';
import Flex from '../../Style/Flex';

import Header from './Header';
import Footer from './Footer';

function DefaultLayout({ children }) {
    return (
        <Flex.Wrapper column style={{ minHeight: '100vh' }}>
            <Header />
            <Flex.Item flex={1} style={{ padding: '0 16px', width: '100%' }}>
                {children}
            </Flex.Item>
            <Footer />
        </Flex.Wrapper>
    );
}

export default DefaultLayout;
